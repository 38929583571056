import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { navigateToQuery, setUserLocalStorage, getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

window.authNetResponseHandlerWrapper = null;

const Subscribe = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);

    const [authNetApiLoginId, setAuthNetApiLoginId] = useState(() => '');
    const [authNetPublicClientKey, setAuthNetPublicClientKey] = useState(() => '');
    const [authNetAcceptJsSrc, setAuthNetAcceptJsSrc] = useState(() => '');

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const qid = searchParams.get('qid');

    const authNetResponseHandler = (response) => {
        if (
            response['messages'] &&
            response['messages']['resultCode'] &&
            response['messages']['resultCode'] === 'Error' &&
            response['messages']['message'] &&
            response['messages']['message'].length > 0 &&
            response['messages']['message'][0]['text']
        ) {
            setError(response['messages']['message'][0]['text']);
            return;
        }

        if (submitting) {
            return;
        }

        setSubmitting(true);
        fetchService('POST', '/service/subscribe', {
            productId: 102,
            nonceData: JSON.stringify(response),
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError(data['error']);
                } else {
                    setError(null);
                    setUserLocalStorage({ status: 2 });

                    if (qid) {
                        navigate('/results/' + qid, { state: { successMessage: 'Thank you for subscribing! You now have access to full query functionality, data exports, and API downloads.' } });
                    } else {
                        navigateToQuery(navigate, { state: { successMessage: 'Thank you for subscribing! You now have access to full query functionality, data exports, and API downloads.' } });
                    }
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
            });
    }

    const products = [
        {
            id: 0,
            title: 'Free',
            subheader: 'Limited query functionality',
            price: '0',
            description: [
                '⚠️ Up to 15 queries per day',
                '⚠️ Up to 150 results per query',
                '⚠️ Up to 15 fields per query',
                '❌ No data exports',
                '❌ No API access',
                '❌ No saved queries',
            ],
            buttonText: 'Keep free for now',
            buttonVariant: 'outlined',
            onClick: () => {
                if (qid) {
                    navigate('/results/' + qid);
                } else {
                    navigateToQuery(navigate);
                }
            },
            headerBgColor: '#eeeeee',
        },
        {
            id: 102,
            title: 'Paid',
            subheader: 'Full functionality',
            price: '29.95',
            description: [
                '✅ Unlimited queries',
                '✅ Unlimited results per query',
                '✅ Unlimited fields per query',
                '✅ Export data in CSV, Excel, JSON, or XML',
                '✅ Download data via API',
                '✅ Save queries for future use',
            ],
            buttonText: 'Subscribe',
            buttonVariant: 'contained',
            onClick: () => { },
            headerBgColor: '#f3f9ff',
        },
    ];

    useEffect(() => {
        if (!user.status) {
            navigate('/signup');
            return;
        }

        if (user.status >= 2) {
            navigate('/account');
            return;
        }

        // If /subscribe was already visited (without any location change/refresh), then the authorize.net AcceptUI.js script was already loaded in the document.
        // We do not want to load the script again and duplicate its effects,
        // however we need reattach the action of showing the iframe to the click event of button with class AcceptUI.
        if (window.authNetLoaded) {
            let a = (t) => {
                document.getElementById('AcceptUIContainer').className = 'show';
                document.getElementById('AcceptUIBackground').className = 'show';
                t.stopPropagation();
                t.preventDefault();
                window.scrollTo(0, 0);
                return !1;
            }
            let p = (t, e, n) => {
                t.addEventListener ? t.addEventListener(e, n, !1) : t.attachEvent && t.attachEvent("on" + e, n);
            }
            let t = document.getElementsByClassName("AcceptUI")[0];
            ("ontouchstart" in window || navigator.msMaxTouchPoints) && p(t, "touchend", a); p(t, "click", a);
        }

        document.title = 'Subscribe - EODmetrics';
        window.scrollTo(0, 0);

        window.authNetResponseHandlerWrapper = authNetResponseHandler

        fetchService('GET', '/service/authnet-info', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    setError('Error retrieving payment gateway information: ' + data['error']);
                } else {
                    setAuthNetApiLoginId(data['apiLoginId']);
                    setAuthNetPublicClientKey(data['publicClientKey']);
                    setAuthNetAcceptJsSrc(data['acceptJsSrc']);
                }
            })
            .catch((err) => {
                setError('Error retrieving payment gateway information: ' + err.toString());
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (document.getElementById('script-accept-ui')) {
            return;
        }
        if (!authNetApiLoginId || !authNetPublicClientKey || !authNetAcceptJsSrc) {
            return;
        }

        if (!window.authNetLoaded) {
            let scriptElem = document.createElement('script');
            scriptElem.setAttribute('src', authNetAcceptJsSrc);
            scriptElem.setAttribute('charset', 'utf-8');
            scriptElem.setAttribute('id', 'script-accept-ui');
            document.getElementById('cardActions-102').appendChild(scriptElem);
            window.authNetLoaded = true;
        }
    }, [authNetApiLoginId, authNetPublicClientKey, authNetAcceptJsSrc]);

    return (
        <>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <PageHeader title="Subscription Options" />
            </Box>
            <Container disableGutters maxWidth="lg" sx={{ pt: 2, pb: 6 }}>
                <Typography variant="h5" align="center" color="text.secondary" component="p">
                    Your free account gives you limited query functionality.
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ mt: 1 }}>
                    For unlimited queries and results, data exports, and API access, subscribe to a paid account.
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ mt: 1 }}>
                    If you subscribe, you can try out the full functionality of a paid account with a two week free trial!
                </Typography>
            </Container>
            <Container maxWidth="md">
                <Grid container spacing={5} alignItems="flex-end">
                    {products.map((product) => (
                        <Grid
                            item
                            key={product.title}
                            xs={12}
                            sm={product.id > 0 ? 12 : 6}
                            md={6}
                        >
                            <Card>
                                <CardHeader
                                    title={product.title}
                                    subheader={product.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            product.headerBgColor
                                        // theme.palette.mode === 'light'
                                        //     ? theme.palette.grey[200]
                                        //     : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h4" color="text.primary">
                                            ${product.price}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /mo
                                        </Typography>
                                    </Box>
                                    {product.id > 0 ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                                *Two Week Free Trial*
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ fontWeight: 'bold', textDecoration: 'underline', visibility: 'hidden' }}>
                                                Empty
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                    }} style={{ width: '400px' }}>
                                        <ul>
                                            {product.description.map((line) => (
                                                <Typography
                                                    component="li"
                                                    variant="subtitle1"
                                                    // align="center"
                                                    key={line}
                                                >
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </Box>
                                </CardContent>
                                <CardActions id={'cardActions-' + product.id}>
                                    {product.id > 0 ? (
                                        <>
                                            <Box sx={{ display: submitting ? 'flex' : 'none' }} justifyContent="center" style={{ width: '100%' }}>
                                                <CircularProgress size={37} />
                                            </Box>
                                            <Button
                                                type="button"
                                                variant={product.buttonVariant}
                                                fullWidth
                                                className="AcceptUI"
                                                data-billingaddressoptions='{"show": true, "required": true}'
                                                data-apiloginid={authNetApiLoginId}
                                                data-clientkey={authNetPublicClientKey}
                                                data-acceptuiformbtntxt="Activate Subscription"
                                                data-acceptuiformheadertxt="Payment Information"
                                                data-paymentoptions='{"showCreditCard": true, "showBankAccount": false}'
                                                data-responsehandler="authNetResponseHandlerWrapper"
                                                sx={{ display: submitting ? 'none' : 'inline-flex' }}
                                            >
                                                {product.buttonText}
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            type="button"
                                            onClick={product.onClick}
                                            variant={product.buttonVariant}
                                            fullWidth
                                        >
                                            {product.buttonText}
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                {error &&
                    <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                }
                <Typography variant="body1" mt={4} mb={2} align="center">
                    Easily cancel at any time, no questions asked.
                </Typography>
                <Typography variant="body1" mb={2} align="center">
                    By subscribing, you agree to our <Link component={RouterLink} to="/terms">Terms of Use</Link> and <Link component={RouterLink} to="/privacy">Privacy Policy</Link>.
                </Typography>
            </Container>
        </>
    )
}

export default Subscribe;