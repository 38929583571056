import React, { useState, useEffect } from 'react';

import { useParams, Link as RouterLink } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FieldsList from './FieldsList';
import { getUserLocalStorage, fetchService, dataUpdateStatusNone, dataUpdateStatusInProgress, dataUpdateStatusDelayed } from './Util';
import PageHeader from './PageHeader';
import SubHeader from './SubHeader';
import { valueFormatter } from './ValueFormatter';

const Ticker = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [dataUpdateDate, setDataUpdateDate] = useState(() => null);
    const [dataUpdateStatus, setDataUpdateStatus] = useState(() => dataUpdateStatusNone);
    const [error, setError] = useState(() => null);
    const [values, setValues] = useState(() => []);

    const params = useParams();
    const ticker = params.ticker.toUpperCase();

    useEffect(() => {
        setLoading(true);
        document.title = ticker + ' - EODmetrics';
        fetchService('GET', '/service/ticker-data', {
            ticker: ticker,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    setLoading(false);
                    setError('Error fetching ticker data: ' + data['error']);
                } else {
                    let newValues = [];
                    for (let i = 0; i < data['values'].length; i++) {
                        newValues.push(valueFormatter(data['columnTypes'][i])({ value: data['values'][i] }));
                    }
                    setValues(newValues);
                    setDataUpdateDate(data['dataUpdateDate']);
                    setDataUpdateStatus(data['dataUpdateStatus']);
                    setLoading(false);
                    setError(null);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error fetching ticker data: ' + err.toString());
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    let loadingContent = (
        <Box style={{ display: 'flex', minHeight: '500px', marginBottom: '500px' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={100} />
        </Box>
    );

    let fieldsList;
    if (!user.status) {
        fieldsList = FieldsList.slice(0, 105);
    } else {
        fieldsList = FieldsList
    }

    return (
        <Container>
            <Box display="flex" justifyContent="center" alignItems="center">
                <PageHeader style={{ visibility: (loading || !dataUpdateDate) ? 'hidden' : 'visible' }} title={values[1] + ' (' + ticker + ')'} />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                <SubHeader style={{ visibility: (loading || !dataUpdateDate) ? 'hidden' : 'visible' }} title={'Data Updated: ' + dataUpdateDate} />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                {dataUpdateStatus === dataUpdateStatusInProgress &&
                    <Alert severity="info" sx={{ mb: 2 }}>Data update in progress, next update expected by 7:00 p.m. Eastern Time</Alert>
                }
                {dataUpdateStatus === dataUpdateStatusDelayed &&
                    <Alert severity="warning" sx={{ mb: 2 }}>Data update in progress, next update expected soon</Alert>
                }
            </Box>
            {
                error ? (
                    <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                        <Typography variant="body1" mb={2}>
                            {error}
                        </Typography>
                    </Box>
                ) :
                    (loading ? loadingContent : (
                        <>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <TableContainer component={Paper} sx={{ maxWidth: 600 }} >
                                    <Table size="small">
                                        <TableBody>
                                            {fieldsList.map((field, i) => (
                                                <TableRow key={field.id} className={'ticker-data-row-' + (i % 2 === 1 ? 'odd' : 'even')}>
                                                    <TableCell align="left" sx={{ fontWeight: 'fontWeightBold', maxWidth: 400 }}>{field.name}</TableCell>
                                                    <TableCell align="right">{values[i]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            {!user.status &&
                                <>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Typography variant="body1" mt={4} mb={1}>
                                            <Link component={RouterLink} to="/signup">Sign up for a completely free account</Link> to view hundreds of additional data fields:
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <ul>
                                            <li>
                                                <Typography variant="body1" mb={1}>
                                                    Moving averages and technical indicators
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body1" mb={1}>
                                                    Comprehensive financial metrics and fundamental ratios
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body1" mb={1}>
                                                    Historical volatility, implied volatility, put-call ratios, and other option statistics
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body1" mb={1}>
                                                    52-Week High, Low, Rank, and Percentile for all volatility/option metrics and time frames
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Box>
                                </>
                            }
                        </>
                    ))
            }
        </Container >
    );
}

export default Ticker;
