import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { fetchService } from './Util';
import PageHeader from './PageHeader';

const theme = createTheme();

const ResetPassword = (props) => {
    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);
    const [errorDetails, setErrorDetails] = useState(() => null);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        document.title = 'Reset Password - EODmetrics';
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/reset-password', {
            password: document.getElementById('password').value,
            confirmPassword: document.getElementById('confirmPassword').value,
            token: params.token || '',
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    setError(data['error']);
                    if (data['errorDetails']) {
                        setErrorDetails(data['errorDetails']);
                    } else {
                        setErrorDetails(null);
                    }
                } else {
                    setError(null);
                    setErrorDetails(null);

                    if (data['login']) {
                        navigate('/login', { state: { successMessage: 'You have successfully reset your password, and can now log in with your new password.' } });
                    } else {
                        navigate('/account', { state: { successMessage: 'You have successfully reset your password.' } });
                    }
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
                setErrorDetails(null);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PageHeader title="Reset Password" />
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {error && (!errorDetails || errorDetails['login'] || errorDetails.field === 'token') &&
                        <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>
                            {error}
                            {errorDetails?.field === 'token' &&
                                <>
                                    &nbsp; Please <Link component={RouterLink} to="/forgot-password">issue a new request.</Link>
                                </>
                            }
                        </Alert>
                    }
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    required
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type="password"
                                    autoComplete="new-password"
                                    error={errorDetails && errorDetails?.field === 'password'}
                                    helperText={errorDetails && errorDetails?.field === 'password' ? error : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="confirmPassword"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    autoComplete="new-password"
                                    error={errorDetails && errorDetails?.field === 'confirmPassword'}
                                    helperText={errorDetails && errorDetails?.field === 'confirmPassword' ? error : ''}
                                />
                            </Grid>
                        </Grid>
                        <Box style={{ minHeight: '40px' }} sx={{ mt: 3, mb: 2 }}
                            justifyContent="center"
                        >
                            {submitting ? (
                                <Box style={{ minHeight: '82px' }} sx={{ display: 'flex', mt: 3, mb: 2 }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CircularProgress size={40} />
                                </Box>
                            ) : (
                                <Box style={{ minHeight: '82px' }} sx={{ mt: 3, mb: 2 }}
                                    justifyContent="center"
                                >
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                        Reset Password
                                    </Button>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                        onClick={() => navigate('/account')}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default ResetPassword;