import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

const theme = createTheme();

const ForgotPassword = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);
    const [errorDetails, setErrorDetails] = useState(() => null);
    const [userEmail, setUserEmail] = useState(() => '');

    const navigate = useNavigate();
    const location = useLocation();

    const [successMessage, setSuccessMessage] = useState(() => location?.state?.successMessage || null);

    useEffect(() => {
        document.title = 'Forgot Password - EODmetrics';

        if (!user.status) {
            return;
        }

        fetchService('GET', '/service/account', {})
            .then((response) => response.json())
            .then((data) => {
                if (!data['error'] && data['email']) {
                    setUserEmail(data['email']);
                }
            })
            .catch((err) => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/forgot-password', {
            email: document.getElementById('email').value,
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    setError(data['error']);
                    if (data['errorDetails']) {
                        setErrorDetails(data['errorDetails']);
                    } else {
                        setErrorDetails(null);
                    }
                } else {
                    setError(null);
                    setErrorDetails(null);
                    setSuccessMessage('A password reset email has been sent.')
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
                setErrorDetails(null);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PageHeader title="Forgot Password" />
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography variant="body1" mb={1} mt={1}>
                        Enter your login email, and we will send you a link to reset your password.
                    </Typography>
                    {error && (!errorDetails || errorDetails['login']) &&
                        <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                    }
                    {successMessage &&
                        <Alert id="success-container" onClose={() => { setSuccessMessage(null) }} severity="success" sx={{ m: 1 }}>{successMessage}</Alert>
                    }
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    required
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    error={errorDetails && errorDetails?.field === 'email'}
                                    helperText={errorDetails && errorDetails?.field === 'email' ? error : ''}
                                />
                            </Grid>
                            { /* invisible unused field, hack to enforce proper width */}
                            <Grid item xs={12} style={{ visibility: 'hidden', height: 0, marginBottom: 0, paddingTop: 0 }}>
                                <TextField
                                    id="noinput"
                                    required
                                    fullWidth
                                    label="noinput"
                                    name="noinput"
                                />
                            </Grid>
                        </Grid>
                        {submitting ? (
                            <Box style={{ minHeight: '82px' }} sx={{ display: 'flex', mt: 3, mb: 2 }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress size={40} />
                            </Box>
                        ) : (
                            <Box style={{ minHeight: '82px' }} sx={{ mt: 3, mb: 2 }}
                                justifyContent="center"
                            >
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    Send Password Reset Email
                                </Button>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                    onClick={() => navigate(-1)}
                                >
                                    Go Back
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default ForgotPassword;