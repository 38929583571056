import { React } from 'react';

import Typography from '@mui/material/Typography';

const PageHeader = (props) => {
    return (
        <Typography variant="h4" component="h1" mb={2} mt={2} style={props.style}>
            {props.title}
        </Typography>
    )
}

export default PageHeader;
