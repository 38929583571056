import React, { useState, useEffect } from 'react';

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  useOutletContext,
  Link as RouterLink
} from "react-router-dom";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import CodeIcon from '@mui/icons-material/Code';
import Container from '@mui/material/Container';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { tags as t } from '@lezer/highlight';

import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { EditorState } from "@codemirror/state"
import { EditorView } from "@codemirror/view"
import { indentUnit } from "@codemirror/language"
import { sql, SQLDialect } from '@codemirror/lang-sql';

import { getLocalStorageJSON, setLocalStorageJSON, getUserLocalStorage, fetchService } from './Util';
import FieldsList from './FieldsList';
import PageHeader from './PageHeader';
import SubHeader from './SubHeader';

let fieldsList = [];
let securityMap = {};

let fieldsMap = {};
for (let field of FieldsList) {
  fieldsList.push(field.id);
  fieldsMap[field.id] = field;
}

let fieldsNameMap = {}
for (let field of FieldsList) {
  fieldsNameMap[field.name] = field;
}

const directions = [
  {
    id: 'asc',
    text: 'Ascending',
  },
  {
    id: 'desc',
    text: 'Descending',
  }
];

let directionsMap = {}
for (let direction of directions) {
  directionsMap[direction.id] = direction
}

const filterOptionsDirections = createFilterOptions({
  stringify: (option) => option.text + option.id,
});

const operators = [
  {
    id: 'gt',
    text: 'is greater than',
    op: '>',
  },
  {
    id: 'lt',
    text: 'is less than',
    op: '<',
  },
  {
    id: 'eq',
    text: 'is equal to',
    op: '=',
  },
  {
    id: 'ne',
    text: 'is not equal to',
    op: '!='
  },
];

const exchanges = [
  'NASDAQ',
  'NYSE',
  'NYSEARCA',
  'NYSEMKT',
];

const sectors = [
  'Basic Materials',
  'Communication Services',
  'Consumer Cyclical',
  'Consumer Defensive',
  'Energy',
  'Financial Services',
  'Healthcare',
  'Industrials',
  'Real Estate',
  'Technology',
  'Utilities',
];

const industries = [
  'Advertising Agencies',
  'Aerospace & Defense',
  'Agricultural Inputs',
  'Airlines',
  'Airports & Air Services',
  'Aluminum',
  'Apparel Manufacturing',
  'Apparel Retail',
  'Asset Management',
  'Auto & Truck Dealerships',
  'Auto Manufacturers',
  'Auto Parts',
  'Banks - Diversified',
  'Banks - Regional',
  'Beverages - Brewers',
  'Beverages - Non - Alcoholic',
  'Beverages - Wineries & Distilleries',
  'Biotechnology',
  'Broadcasting',
  'Building Materials',
  'Building Products & Equipment',
  'Business Equipment & Supplies',
  'Capital Markets',
  'Chemicals',
  'Coking Coal',
  'Communication Equipment',
  'Computer Hardware',
  'Confectioners',
  'Conglomerates',
  'Consulting Services',
  'Consumer Electronics',
  'Copper',
  'Credit Services',
  'Department Stores',
  'Diagnostics & Research',
  'Discount Stores',
  'Diversified Industrials',
  'Drug Manufacturers - General',
  'Drug Manufacturers - Major',
  'Drug Manufacturers - Specialty & Generic',
  'Education & Training Services',
  'Electrical Equipment & Parts',
  'Electronic Components',
  'Electronic Gaming & Multimedia',
  'Electronics & Computer Distribution',
  'Engineering & Construction',
  'Entertainment',
  'Farm & Heavy Construction Machinery',
  'Farm Products',
  'Financial Conglomerates',
  'Financial Data & Stock Exchanges',
  'Food Distribution',
  'Footwear & Accessories',
  'Furnishings',
  'Furnishings Fixtures & Appliances',
  'Gambling',
  'Gold',
  'Grocery Stores',
  'Health Information Services',
  'Healthcare Plans',
  'Home Improvement Retail',
  'Household & Personal Products',
  'Industrial Distribution',
  'Industrial Metals & Minerals',
  'Information Technology Services',
  'Infrastructure Operations',
  'Insurance - Diversified',
  'Insurance - Life',
  'Insurance - Property & Casualty',
  'Insurance - Reinsurance',
  'Insurance - Specialty',
  'Insurance Brokers',
  'Integrated Freight & Logistics',
  'Internet Content & Information',
  'Internet Retail',
  'Leisure',
  'Lodging',
  'Lumber & Wood Production',
  'Luxury Goods',
  'Marine Shipping',
  'Medical Care Facilities',
  'Medical Devices',
  'Medical Distribution',
  'Medical Instruments & Supplies',
  'Metal Fabrication',
  'Mortgage Finance',
  'Oil & Gas Drilling',
  'Oil & Gas E & P',
  'Oil & Gas Equipment & Services',
  'Oil & Gas Integrated',
  'Oil & Gas Midstream',
  'Oil & Gas Refining & Marketing',
  'Other Industrial Metals & Mining',
  'Other Precious Metals & Mining',
  'Packaged Foods',
  'Packaging & Containers',
  'Paper & Paper Products',
  'Personal Services',
  'Pharmaceutical Retailers',
  'Pollution & Treatment Controls',
  'Publishing',
  'Railroads',
  'Real Estate - Development',
  'Real Estate - Diversified',
  'Real Estate Services',
  'Recreational Vehicles',
  'REIT - Diversified',
  'REIT - Healthcare Facilities',
  'REIT - Hotel & Motel',
  'REIT - Industrial',
  'REIT - Mortgage',
  'REIT - Office',
  'REIT - Residential',
  'REIT - Retail',
  'REIT - Specialty',
  'Rental & Leasing Services',
  'Residential Construction',
  'Resorts & Casinos',
  'Restaurants',
  'Savings & Cooperative Banks',
  'Scientific & Technical Instruments',
  'Security & Protection Services',
  'Semiconductor Equipment & Materials',
  'Semiconductors',
  'Shell Companies',
  'Silver',
  'Software - Application',
  'Software - Infrastructure',
  'Solar',
  'Specialty Business Services',
  'Specialty Chemicals',
  'Specialty Industrial Machinery',
  'Specialty Retail',
  'Staffing & Employment Services',
  'Steel',
  'Telecom Services',
  'Textile Manufacturing',
  'Thermal Coal',
  'Tobacco',
  'Tools & Accessories',
  'Travel Services',
  'Trucking',
  'Uranium',
  'Utilities - Diversified',
  'Utilities - Independent Power Producers',
  'Utilities - Regulated Electric',
  'Utilities - Regulated Gas',
  'Utilities - Regulated Water',
  'Utilities - Renewable',
  'Waste Management',
];

let operatorsMap = {}
for (let operator of operators) {
  operatorsMap[operator.id] = operator
}

const filterOptionsOperators = createFilterOptions({
  stringify: (option) => option.text + option.id + option.op,
});

const filterOptionsFields = createFilterOptions({
  stringify: (option) => option.name + option.id,
});

const editorTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#ffffff',
    foreground: '#212529',
    caret: '#5d00ff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    gutterBackground: '#f7f7f7',
    gutterForeground: '#999',
  },
  styles: [
    { tag: t.comment, color: '#787b8099' },
    { tag: t.variableName, color: '#212529;' },
    { tag: t.content, color: '#212529;' },
    { tag: [t.string, t.special(t.brace)], color: '#a11;' },
    { tag: t.number, color: '#164' },
    { tag: t.bool, color: '#5c6166' },
    { tag: t.null, color: '#5c6166' },
    { tag: t.keyword, color: '#1565c0' },
    { tag: t.operator, color: '#5c6166' },
    { tag: t.className, color: '#5c6166' },
    { tag: t.definition(t.typeName), color: '#5c6166' },
    { tag: t.typeName, color: '#5c6166' },
    { tag: t.angleBracket, color: '#5c6166' },
    { tag: t.tagName, color: '#5c6166' },
    { tag: t.attributeName, color: '#5c6166' },
  ],
});

const editorDialect = SQLDialect.define({
  keywords: 'abs acos adddate addtime and as asc avg between by case cast ceil ceiling concat cos cot count curdate current_date current_time current_timestamp curtime date date_add date_format date_sub datediff day dayname dayofmonth dayofweek dayofyear desc false field floor format from from_days group having hour if ifnull in interval is isnull last_day last_value lastval left length like limit ln locate log log10 log2 lower lpad ltrim max microsecond mid min minute mod month monthname not now nullif or ord order pow power quarter rand regexp regexp_instr regexp_replace regexp_substr replace reverse right rlike round rpad rtrim sec_to_time second select setval sign sin space sqrt std stddev stddev_pop stddev_samp str_to_date strcmp subdate substr substring substring_index subtime sum tan time time_format time_to_sec timediff timestamp timestampadd timestampdiff to_days to_seconds trim true ucase unix_timestamp upper utc_date utc_time utc_timestamp var_pop var_samp variance week weekday weekofyear where xor year yearweek',
});

const AlignedTextField = styled(TextField)({
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    top: '100%'
  }
});

const queryTypeBasic = 1;
const queryTypeSql = 2;

const Query = (props) => {
  const [user] = useState(() => getUserLocalStorage());

  const defaultType = queryTypeBasic;
  const fixedFields = ['ticker'];
  const defaultFields = ['ticker', 'name', 'close_price', 'volume', 'percent_change_price_1_day'];
  const defaultConditions = [
    {
      "field": "close_price",
      "operator": "gt",
      "value": "5.00"
    },
    {
      "field": "volume",
      "operator": "gt",
      "value": "100000"
    }
  ];
  const defaultTickers = [];
  const defaultOrderings = [
    {
      "field": "market_cap",
      "direction": "desc"
    }
  ];
  const defaultLimit = 500;
  const defaultQuerySql = `SELECT
    ticker, name, close_price, volume, percent_change_price_1_day
FROM
	  data
WHERE
	  close_price >= 5.00 AND volume >= 100000
ORDER BY
    market_cap DESC
LIMIT
	  500`;

  const [type, setType] = useState(() => defaultType);

  const [fields, setFields] = useState(() => getLocalStorageJSON('fields') || [...defaultFields]);
  const [prevFields, setPrevFields] = useState(() => fields.length === 0 ? [...fixedFields] : [...fields]);
  const [allFields, setAllFields] = useState(() => fields.length === 0);

  const [conditions, setConditions] = useState(() => getLocalStorageJSON('conditions') || [...defaultConditions]);

  const [tickers, setTickers] = useState(() => getLocalStorageJSON('tickers') || [...defaultTickers]);

  const [orderings, setOrderings] = useState(() => getLocalStorageJSON('orderings') || [...defaultOrderings]);

  const [limit, setLimit] = useState(() => getLocalStorageJSON('limit') || defaultLimit);

  const [querySql, setQuerySql] = useState(() => getLocalStorageJSON('querySql') || defaultQuerySql);

  const [loading, setLoading] = useState(() => true);
  const [runningQuery, setRunningQuery] = useState(() => false);
  const [exportingResults, setExportingResults] = useState(() => false);
  const [savingQuery, setSavingQuery] = useState(() => false);
  const [savingQueryDialog, setSavingQueryDialog] = useState(() => false);
  const [updatingQuery, setUpdatingQuery] = useState(() => false);
  const [convertingQuery, setConvertingQuery] = useState(() => false);

  // lastQid holds a qid returned by run-query as a result of an Export Results or Save Query click
  const [lastQid, setLastQid] = useState(() => '');

  const [savedQid, setSavedQid] = useState(() => '');

  const [exportResultsDialogOpen, setExportResultsDialogOpen] = useState(() => false);
  const [exportFormat, setExportFormat] = useState(() => getLocalStorageJSON('exportFormat') || '');
  const [exportZip, setExportZip] = useState(() => getLocalStorageJSON('exportZip') || false);
  const [exportPretty, setExportPretty] = useState(() => getLocalStorageJSON('exportPretty') || false);
  const [exportDialogError, setExportDialogError] = useState(() => false);

  const [saveQueryDialogOpen, setSaveQueryDialogOpen] = useState(() => false);
  const [saveQueryName, setSaveQueryName] = useState(() => ''); // input field
  const [savedQueryName, setSavedQueryName] = useState(() => ''); // header (name of current saved query)
  const [saveQueryDialogError, setSaveQueryDialogError] = useState(() => false);

  const [apiUrl, setApiUrl] = useState(() => '');
  const [apiUrlDialogOpen, setApiUrlDialogOpen] = useState(() => false);

  const [error, setError] = useState(() => null);
  const [errorDetails, setErrorDetails] = useState(() => null);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [copiedToolTipOpen, setCopiedToolTipOpen] = useState(() => false);

  const [successMessage, setSuccessMessage] = useState(() => location?.state?.successMessage || null);

  const [securityMapLoaded, setSecurityMapLoaded] = useState(() => false);
  const [queryLoaded, setQueryLoaded] = useState(() => false);
  const [apiKey, securityList, filterOptionsTickers, renderTickerOption] = useOutletContext();

  useEffect(() => {
    document.title = 'Query - EODmetrics';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (securityMapLoaded && (!params.qid || queryLoaded)) {
      setLoading(false);
    }
  }, [securityMapLoaded, queryLoaded]);

  useEffect(() => {
    if (securityList && securityList.length > 0) {
      for (let security of securityList) {
        securityMap[security.ticker] = security;
      }
      setSecurityMapLoaded(true);
    }
  }, [securityList]);

  useEffect(() => {
    const savedQidParam = searchParams.get('sqid');

    if (savedQidParam) {
      setSavedQid(savedQidParam);
    }

    const typeParam = searchParams.get('type');
    const typeNum = Number(typeParam);

    setError(null);
    setErrorDetails(null);

    if (params.qid) {
      fetchService('GET', '/service/get-query', {
        qid: params.qid,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data['error']) {
            setLoading(false);
            setError('Error fetching query: ' + data['error']);
          } else {
            let typeNum = Number(data['type']);
            if (typeNum === queryTypeBasic || typeNum === queryTypeSql) {
              setType(typeNum);
            }
            if (data['type'] === queryTypeBasic) {
              try {
                let query = JSON.parse(data['data']);
                setFields(query['fields']);
                if (query['fields'].length === 0) {
                  setAllFields(true);
                }
                setPrevFields(fields.length === 0 ? [...fixedFields] : [...fields]);

                setConditions(query['conditions']);
                setTickers(query['tickers']);
                setOrderings(query['order']);
                setLimit(query['limit']);

                setQueryLoaded(true);
              } catch (err) {
                setLoading(false);
                setError('Error parsing query JSON: ' + err.String());
              }
            } else {
              setQuerySql(data['data']);
              setQueryLoaded(true);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setError('Error fetching query: ' + err.toString());
        });

      // if sqid is present in addition to qid, fetch the saved query just to get the name
      if (savedQidParam) {
        fetchService('GET', '/service/get-saved-query', {
          sqid: savedQidParam,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data['error']) {
              // do nothing
            } else {
              setSavedQueryName(data['name']);
            }
          })
          .catch((err) => {
            // do nothing
          });
      }
    } else if (savedQidParam) {
      fetchService('GET', '/service/get-saved-query', {
        sqid: savedQidParam,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data['error']) {
            if (data['errorDetails']) {
              if (data['errorDetails']['login']) {
                navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                return;
              }
            }
            setLoading(false);
            setError('Error fetching saved query: ' + data['error']);
          } else {
            let typeNum = Number(data['type']);
            if (typeNum === queryTypeBasic || typeNum === queryTypeSql) {
              setType(typeNum);
            }
            if (data['type'] === queryTypeBasic) {
              try {
                let query = JSON.parse(data['data']);
                setFields(query['fields']);
                if (query['fields'].length === 0) {
                  setAllFields(true);
                }
                setPrevFields(fields.length === 0 ? [...fixedFields] : [...fields]);

                setConditions(query['conditions']);
                setTickers(query['tickers']);
                setOrderings(query['order']);
                setLimit(query['limit']);

                setSavedQueryName(data['name']);

                setQueryLoaded(true);
              } catch (err) {
                setLoading(false);
                setError('Error parsing query JSON: ' + err.String());
              }
            } else {
              setQuerySql(data['data']);
              setSavedQueryName(data['name']);
              setQueryLoaded(true);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setError('Error fetching saved query: ' + err.toString());
        });
    } else if (typeNum === queryTypeBasic || typeNum === queryTypeSql) {
      setType(typeNum);
    } else {
      setType(queryTypeBasic);
    }
  }, [params, searchParams]);

  useEffect(() => {
    if (error && document.getElementById('error-container')) {
      setSuccessMessage(null);
      document.getElementById('error-container').scrollIntoView(true);
    }
  }, [error]);

  useEffect(() => {
    if (successMessage && document.getElementById('success-container')) {
      window.scrollTo(0, 0);
    }
  }, [successMessage]);

  const handleAllFieldsChange = (event) => {
    setAllFields(event.target.checked);
    if (event.target.checked) {
      setPrevFields(fields);
      setFields([]);
    } else {
      setFields(prevFields);
    }
  };

  const handleAddConditionClick = () => {
    setConditions(conditions => conditions.concat({
      field: null,
      operator: null,
      value: null,
    }));
  };

  const handleRemoveConditionClick = (index) => {
    setConditions(conditions => conditions.filter((_, idx) => idx !== index));
  };

  const handleConditionFieldChange = (value, index) => {
    if (errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'field' && errorDetails.index === index) {
      setErrorDetails(null);
    }

    if (value === null) {
      return;
    }

    setConditions(conditions => conditions.map((condition, idx) => {
      if (idx === index) {
        if (value.id === 'exchange' || value.id === 'sector' || value.id === 'industry') {
          return { ...condition, field: value.id, value: null };
        } else if (!(value.id === 'exchange' || value.id === 'sector' || value.id === 'industry') &&
          (condition.field === 'exchange' || condition.field === 'sector' || condition.field === 'industry')) {
          // setting value to ' ' in this case because for some reason setting value to null does not clear the input value
          return { ...condition, field: value.id, value: ' ' };
        }
        return { ...condition, field: value.id };
      }
      return condition;
    }));
  };

  const handleConditionOperatorChange = (value, index) => {
    if (errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'operator' && errorDetails.index === index) {
      setErrorDetails(null);
    }

    if (value === null) {
      return;
    }

    setConditions(conditions => conditions.map((condition, idx) => {
      if (idx === index) {
        return { ...condition, operator: value.id };
      } else {
        return condition;
      }
    }));
  };

  const handleConditionValueChange = (value, index) => {
    if (errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index) {
      setErrorDetails(null);
    }

    if (value === null) {
      return;
    }

    setConditions(conditions => conditions.map((condition, idx) => {
      if (idx === index) {
        return { ...condition, value: (value.id || fieldsNameMap[value]?.id || value) };
      } else {
        return condition;
      }
    }));
  };

  const handleTickersValueChange = (newValues) => {
    if (errorDetails && errorDetails.d1 === 'tickers') {
      setErrorDetails(null);
    }

    let newTickers = [];
    let tickerSet = new Set();
    for (let value of newValues) {
      if (value.ticker && !tickerSet.has(value.ticker) && securityMap[value.ticker]) {
        newTickers.push(value.ticker);
        tickerSet.add(value.ticker);
      } else if (typeof value === 'string') {
        let tickersFromValue = tickersFromString(value);
        for (let ticker of tickersFromValue) {
          if (!tickerSet.has(ticker) && securityMap[ticker]) {
            newTickers.push(ticker);
            tickerSet.add(ticker);
          }
        }
      }
    }

    setTickers(newTickers);
  }

  const handleTickersInputChange = (newInputValue) => {
    if (errorDetails && errorDetails.d1 === 'tickers') {
      setErrorDetails(null);
    }

    let newTickers = [];
    let tickerSet = new Set();
    for (let ticker of tickers) {
      newTickers.push(ticker);
      tickerSet.add(ticker);
    }

    let tickersFromValue = tickersFromString(newInputValue);
    for (let ticker of tickersFromValue) {
      if (!tickerSet.has(ticker) && securityMap[ticker]) {
        newTickers.push(ticker);
        tickerSet.add(ticker);
      }
    }

    setTickers(newTickers);
  }

  const tickersFromString = (tickerStr) => {
    tickerStr = tickerStr.toUpperCase().replace(/[_-]/g, '.');
    return tickerStr.split(/[\s,]+/)
  }

  const handleAddOrderingClick = () => {
    setOrderings(orderings => orderings.concat({
      field: null,
      direction: null,
    }));
  };

  const handleRemoveOrderingClick = (index) => {
    setOrderings(orderings => orderings.filter((_, idx) => idx !== index));
  };

  const handleOrderingFieldChange = (value, index) => {
    if (errorDetails && errorDetails.d1 === 'order' && errorDetails.d2 === 'field' && errorDetails.index === index) {
      setErrorDetails(null);
    }

    if (value === null) {
      return;
    }

    setOrderings(orderings => orderings.map((ordering, idx) => {
      if (idx === index) {
        return { ...ordering, field: value.id };
      } else {
        return ordering;
      }
    }));
  };

  const handleOrderingDirectionChange = (value, index) => {
    if (errorDetails && errorDetails.d1 === 'order' && errorDetails.d2 === 'direction' && errorDetails.index === index) {
      setErrorDetails(null);
    }

    if (value === null) {
      return;
    }

    setOrderings(orderings => orderings.map((ordering, idx) => {
      if (idx === index) {
        return { ...ordering, direction: value.id };
      } else {
        return ordering;
      }
    }));
  };

  const handleRunQueryClick = () => {
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    setRunningQuery(true);

    let queryData;
    if (type === queryTypeBasic) {
      let query = {
        fields: fields,
        conditions: conditions,
        tickers: tickers,
        order: orderings,
        limit: limit,
      }
      queryData = JSON.stringify(query);
    } else {
      queryData = querySql.trim();
      if (queryData === '') {
        setRunningQuery(false);
        setError('No query provided');
        return
      }
      if (queryData[queryData.length - 1] === ';') {
        queryData = queryData.substring(0, queryData.length - 1);
      }
    }

    fetchService('POST', '/service/run-query', {
      action: 1,
      type: String(type),
      data: queryData,
    })
      .then((response) => response.json())
      .then((data) => {
        setRunningQuery(false);

        if (data['error']) {
          setError('Error running query: ' + data['error']);
          if (data['errorDetails']) {
            if (data['errorDetails']['login']) {
              navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
              return;
            }
            setErrorDetails(data['errorDetails']);
          }
        } else {
          setError(null);
          setErrorDetails(null);
          setQueryLocalStorage();
          navigate('/query/' + data['qid'] + (savedQid ? '?sqid=' + savedQid : ''), { replace: true });
          navigate('/results/' + data['qid'] + (savedQid ? '?sqid=' + savedQid : ''));
        }
      })
      .catch((err) => {
        setRunningQuery(false);
        setError('Error running query: ' + err.toString());
      });
  }

  const handleExportResultsDialogClose = () => {
    setExportResultsDialogOpen(false);
    setExportDialogError(false);
  }

  const handleExportResultsClick = () => {
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    if (userLocalStorage.status < 2) {
      setQueryLocalStorage();
      navigate('/subscribe');
      return;
    }

    setExportingResults(true);

    let queryData;
    if (type === queryTypeBasic) {
      let query = {
        fields: fields,
        conditions: conditions,
        tickers: tickers,
        order: orderings,
        limit: limit,
      }
      queryData = JSON.stringify(query);
    } else {
      queryData = querySql.trim();
      if (queryData === '') {
        setExportingResults(false);
        setError('No query provided');
        return
      }
      if (queryData[queryData.length - 1] === ';') {
        queryData = queryData.substring(0, queryData.length - 1);
      }
    }

    fetchService('POST', '/service/run-query', {
      action: 2,
      type: String(type),
      data: queryData,
    })
      .then((response) => response.json())
      .then((data) => {
        setExportingResults(false);
        if (data['error']) {
          setError('Error exporting results: ' + data['error']);
          if (data['errorDetails']) {
            if (data['errorDetails']['login']) {
              navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
              return;
            }
            setErrorDetails(data['errorDetails']);
          }
        } else {
          setError(null);
          setErrorDetails(null);
          setLastQid(data['qid']);
          setQueryLocalStorage();
          setExportResultsDialogOpen(true);
        }
      })
      .catch((err) => {
        setExportingResults(false);
        setError('Error exporting results: ' + err.toString());
      });
  }

  const handleExport = (event) => {
    event.preventDefault();
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    if (userLocalStorage.status < 2) {
      setQueryLocalStorage();
      navigate('/subscribe');
      return;
    }

    if (!exportFormat) {
      setExportDialogError(true);
      return
    }

    setExportDialogError(false);
    navigate('/query/' + lastQid + (savedQid ? '?sqid=' + savedQid : ''), { replace: true });
    handleExportResultsDialogClose();
    setExportLocalStorage();
    window.location.href = '/service/export-results?qid=' + lastQid +
      '&format=' + exportFormat +
      '&zip=' + (exportZip ? '1' : '0') +
      '&pretty=' + (exportPretty ? '1' : '0');
  }

  const handleSaveQueryDialogClose = () => {
    setSaveQueryDialogOpen(false);
    setSaveQueryDialogError(false);
    setSaveQueryName('');
  }

  const handleSaveQueryClick = () => {
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    if (userLocalStorage.status < 2) {
      setQueryLocalStorage();
      navigate('/subscribe');
      return;
    }

    setSavingQuery(true);

    let queryData;
    if (type === queryTypeBasic) {
      let query = {
        fields: fields,
        conditions: conditions,
        tickers: tickers,
        order: orderings,
        limit: limit,
      }
      queryData = JSON.stringify(query);
    } else {
      queryData = querySql.trim();
      if (queryData === '') {
        setSavingQuery(false);
        setError('No query provided');
        return
      }
      if (queryData[queryData.length - 1] === ';') {
        queryData = queryData.substring(0, queryData.length - 1);
      }
    }

    fetchService('POST', '/service/run-query', {
      action: 3,
      type: String(type),
      data: queryData,
    })
      .then((response) => response.json())
      .then((data) => {
        setSavingQuery(false);
        if (data['error']) {
          setError('Error saving query: ' + data['error']);
          if (data['errorDetails']) {
            if (data['errorDetails']['login']) {
              navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
              return;
            }
            setErrorDetails(data['errorDetails']);
          }
        } else {
          setError(null);
          setErrorDetails(null);
          setLastQid(data['qid']);
          setQueryLocalStorage();
          setSaveQueryDialogOpen(true);
        }
      })
      .catch((err) => {
        setSavingQuery(false);
        setError('Error saving query: ' + err.toString());
      });
  }

  const handleSaveQuery = (event) => {
    event.preventDefault();
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    if (userLocalStorage.status < 2) {
      setQueryLocalStorage();
      navigate('/subscribe');
      return;
    }

    if (!saveQueryName) {
      setSaveQueryDialogError(true);
      return
    }

    setSavingQueryDialog(true);

    fetchService('POST', '/service/save-query', {
      qid: lastQid,
      name: saveQueryName,
    })
      .then((response) => response.json())
      .then((data) => {
        handleSaveQueryDialogClose();
        setSavingQueryDialog(false);
        if (data['error']) {
          setError('Error saving query: ' + data['error']);
          if (data['errorDetails']) {
            if (data['errorDetails']['login']) {
              navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
              return;
            }
            setErrorDetails(data['errorDetails']);
          }
        } else {
          setError(null);
          setErrorDetails(null);
          setSuccessMessage('New query saved.');
          navigate('/query?sqid=' + data['sqid'], { replace: true });
        }
      })
      .catch((err) => {
        handleSaveQueryDialogClose();
        setSavingQueryDialog(false);
        setError('Error saving query: ' + err.toString());
      });
  }

  const handleUpdateQueryClick = () => {
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    if (userLocalStorage.status < 2) {
      setQueryLocalStorage();
      navigate('/subscribe');
      return;
    }

    setUpdatingQuery(true);

    let queryData;
    if (type === queryTypeBasic) {
      let query = {
        fields: fields,
        conditions: conditions,
        tickers: tickers,
        order: orderings,
        limit: limit,
      }
      queryData = JSON.stringify(query);
    } else {
      queryData = querySql.trim();
      if (queryData === '') {
        setUpdatingQuery(false);
        setError('No query provided');
        return
      }
      if (queryData[queryData.length - 1] === ';') {
        queryData = queryData.substring(0, queryData.length - 1);
      }
    }

    fetchService('POST', '/service/run-query', {
      action: 4,
      type: String(type),
      data: queryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['error']) {
          setUpdatingQuery(false);
          setError('Error updating query: ' + data['error']);
          if (data['errorDetails']) {
            if (data['errorDetails']['login']) {
              navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
              return;
            }
            setErrorDetails(data['errorDetails']);
          }
        } else {
          setLastQid(data['qid']);
          setQueryLocalStorage();

          fetchService('POST', '/service/update-query', {
            sqid: savedQid,
            qid: data['qid'],
          })
            .then((response) => response.json())
            .then((data) => {
              setUpdatingQuery(false);
              if (data['error']) {
                setError('Error updating query: ' + data['error']);
                if (data['errorDetails']) {
                  if (data['errorDetails']['login']) {
                    navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                    return;
                  }
                  setErrorDetails(data['errorDetails']);
                }
              } else {
                setError(null);
                setErrorDetails(null);
                setSuccessMessage('Query updated.');
                navigate('/query?sqid=' + savedQid, { replace: true });
              }
            })
            .catch((err) => {
              setUpdatingQuery(false);
              setError('Error updating query: ' + err.toString());
            });
        }
      })
      .catch((err) => {
        setUpdatingQuery(false);
        setError('Error updating query: ' + err.toString());
      });
  }

  const handleConvertQueryClick = () => {
    let userLocalStorage = getUserLocalStorage();
    if (!userLocalStorage.status) {
      setQueryLocalStorage();
      navigate('/signup');
      return;
    }

    setConvertingQuery(true);

    let query = {
      fields: fields,
      conditions: conditions,
      tickers: tickers,
      order: orderings,
      limit: limit,
    }
    let queryData = JSON.stringify(query);

    fetchService('POST', '/service/convert-query', {
      data: queryData,
    })
      .then((response) => response.json())
      .then((data) => {
        setConvertingQuery(false);
        if (data['error']) {
          setError('Error converting query: ' + data['error']);
          if (data['errorDetails']) {
            if (data['errorDetails']['login']) {
              navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
              return;
            }
            setErrorDetails(data['errorDetails']);
          }
        } else {
          setError(null);
          setErrorDetails(null);
          setQueryLocalStorage();
          navigate('/query/' + data['qidBasic'] + (savedQid ? '?sqid=' + savedQid : ''), { replace: true });
          setSuccessMessage(null);
          navigate('/query/' + data['qidSql'] + (savedQid ? '?sqid=' + savedQid : ''));
        }
      })
      .catch((err) => {
        setConvertingQuery(false);
        setError('Error converting query: ' + err.toString());
      });
  }

  const handleClearQueryClick = () => {
    setFields([...fixedFields]);
    setAllFields(false);
    setConditions([]);
    setTickers([]);
    setOrderings([]);
    setLimit(null);
    setQuerySql('');
  }

  const handleApiUrlDialogClose = () => {
    setApiUrlDialogOpen(false);
  }

  const handleApiUrlClick = () => {
    let queryData;
    if (type === queryTypeBasic) {
      let query = {
        fields: fields,
        conditions: conditions,
        tickers: tickers,
        order: orderings,
        limit: limit,
      }
      queryData = JSON.stringify(query);
    } else {
      queryData = querySql.trim();
      if (queryData[queryData.length - 1] === ';') {
        queryData = queryData.substring(0, queryData.length - 1);
      }
    }
    setApiUrl('https://api.eodmetrics.com/query?apiKey=' + (user.status >= 2 && apiKey !== null ? apiKey : 'YOUR_API_KEY') + '&format=csv&data=' + encodeURIComponent(queryData))
    setApiUrlDialogOpen(true);
  }

  const setQueryLocalStorage = () => {
    setLocalStorageJSON('type', type);
    if (type === queryTypeBasic) {
      setLocalStorageJSON('fields', fields);
      setLocalStorageJSON('conditions', conditions);
      setLocalStorageJSON('tickers', tickers);
      setLocalStorageJSON('orderings', orderings);
      setLocalStorageJSON('limit', limit);
    } else if (type === queryTypeSql) {
      setLocalStorageJSON('querySql', querySql);
    }
  }

  const setExportLocalStorage = () => {
    setLocalStorageJSON('exportFormat', exportFormat);
    setLocalStorageJSON('exportZip', exportZip);
    setLocalStorageJSON('exportPretty', exportPretty);
  }

  let exportResultsDialogContent = (
    <Dialog open={exportResultsDialogOpen} onClose={handleExportResultsDialogClose}>
      <Box component="form" onSubmit={handleExport}>
        <DialogTitle>Export Results</DialogTitle>
        <DialogContent style={{ width: '350px'/*, height: '150px'*/ }}>
          <FormGroup>
            <FormControl sx={{ m: 1, minWidth: 120 }}
              size="small"
              error={exportDialogError}
            >
              <InputLabel>Format</InputLabel>
              <Select
                value={exportFormat}
                label="Format"
                onChange={(event) => setExportFormat(event.target.value)}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="excel">Excel</MenuItem>
                <MenuItem value="json">JSON</MenuItem>
                <MenuItem value="xml">XML</MenuItem>
              </Select>
              {exportDialogError && (
                <FormHelperText>Select export format</FormHelperText>
              )}
            </FormControl>
            <FormControlLabel control={<Checkbox />}
              label="Zip File"
              checked={exportZip}
              onChange={(event) => setExportZip(event.target.checked)} />
            <FormControlLabel control={<Checkbox />}
              label="Pretty Print (line breaks and indentation)"
              checked={exportPretty}
              onChange={(event) => setExportPretty(event.target.checked)}
              style={{ display: exportFormat === 'json' || exportFormat === 'xml' ? 'inline-flex' : 'none' }}
            />
          </FormGroup>
          {/* <div style={{ width: '338px' }}></div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportResultsDialogClose}>Cancel</Button>
          <Button type="submit" autoFocus={exportResultsDialogOpen}>Export</Button>
        </DialogActions>
      </Box>
    </Dialog >
  );

  let saveQueryDialogContent = (
    <Dialog open={saveQueryDialogOpen} onClose={handleSaveQueryDialogClose}>
      <Box component="form" onSubmit={handleSaveQuery}>
        <DialogTitle>Save New Query</DialogTitle>
        <DialogContent style={{ width: '350px' }}>
          <FormGroup>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                autoFocus={saveQueryDialogOpen}
                size="small"
                error={saveQueryDialogError}
                helperText={saveQueryDialogError ? 'Enter query name' : ''}
                label="Query Name"
                value={saveQueryName}
                onChange={(event) => setSaveQueryName(event.target.value)}
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {savingQueryDialog ? (
            <CircularProgress size={30} sx={{ mr: 4, mb: 1 }} />
          ) : (
            <>
              <Button onClick={handleSaveQueryDialogClose}>Cancel</Button>
              <Button type="submit">Save</Button>
            </>
          )}
        </DialogActions>
      </Box>
    </Dialog >
  );

  let apiUrlDialogContent = (
    <Dialog open={apiUrlDialogOpen} onClose={handleApiUrlDialogClose}>
      <Box>
        <DialogTitle>API URL (url-encoded)</DialogTitle>
        <DialogContent style={{ width: '600px', wordWrap: 'break-word' }}>
          {apiUrl}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApiUrlDialogClose}>Close</Button>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setCopiedToolTipOpen(false)}
            open={copiedToolTipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            <Button onClick={(event) => {
              navigator.clipboard.writeText(apiUrl);
              setCopiedToolTipOpen(true);
              setTimeout(() => {
                setCopiedToolTipOpen(false);
              }, 1000);
            }}>
              Copy
            </Button>
          </Tooltip>
        </DialogActions>
      </Box>
    </Dialog>
  );

  let buttonContent = (
    <Grid item lg={3}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} style={{ position: 'sticky', top: '1rem' }}>
        <Stack direction="column">
          {runningQuery ? (
            <Box style={{ display: 'flex', minHeight: '100px' }}
              justifyContent="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box style={{ minHeight: '100px' }}>
              <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<PlayArrowIcon />}
                onClick={handleRunQueryClick}>
                Run Query
              </Button>
            </Box>
          )}

          {exportingResults ? (
            <Box style={{ display: 'flex', minHeight: '100px' }}
              justifyContent="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box style={{ minHeight: '100px' }}>
              <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<DownloadIcon />}
                onClick={handleExportResultsClick}>
                Export Results
              </Button>
            </Box>
          )}
          {savedQid && (
            updatingQuery ? (
              <Box style={{ display: 'flex', minHeight: '100px' }}
                justifyContent="center"
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Box style={{ minHeight: '100px' }}>
                <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<SaveIcon />}
                  onClick={handleUpdateQueryClick}>
                  Update Query
                </Button>
              </Box>
            )
          )}
          {savingQuery ? (
            <Box style={{ display: 'flex', minHeight: '100px' }}
              justifyContent="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box style={{ minHeight: '100px' }}>
              <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<SaveAsIcon />}
                onClick={handleSaveQueryClick}>
                Save{savedQid ? ' New' : ''} Query
              </Button>
            </Box>
          )}
          {convertingQuery ? (
            <Box style={{ display: 'flex', minHeight: '100px' }}
              justifyContent="center"
            >
              <CircularProgress size={30} />
            </Box>
          ) : type === queryTypeBasic && (
            <Box style={{ minHeight: '100px' }}>
              <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<CodeIcon />}
                onClick={handleConvertQueryClick}>
                Convert to SQL
              </Button>
            </Box>
          )}
          <Box style={{ minHeight: '100px' }}>
            <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<DataObjectIcon />}
              onClick={handleApiUrlClick}>
              API URL
            </Button>
          </Box>
          <Box style={{ minHeight: '100px' }}>
            <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<ClearIcon />}
              onClick={handleClearQueryClick}>
              Clear Query
            </Button>
          </Box>
        </Stack>
      </Box >
    </Grid >
  );

  let buttonContentBottom = (
    <Stack direction="row" spacing={5} sx={{ mt: 2, display: { xs: 'none', lg: 'flex' } }}>
      {runningQuery ? (
        <Paper style={{ width: '200px', border: 0, boxShadow: 'none', textAlign: 'center' }} >
          <CircularProgress size={30} />
        </Paper>
      ) : (
        <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<PlayArrowIcon />}
          onClick={handleRunQueryClick}>
          Run Query
        </Button>
      )}
      {exportingResults ? (
        <Paper style={{ width: '200px', border: 0, boxShadow: 'none', textAlign: 'center' }} >
          <CircularProgress size={30} />
        </Paper>
      ) : (
        <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<DownloadIcon />}
          onClick={handleExportResultsClick}>
          Export Results
        </Button>
      )}
      {savedQid && (
        updatingQuery ? (
          <Paper style={{ width: '200px', border: 0, boxShadow: 'none', textAlign: 'center' }} >
            <CircularProgress size={30} />
          </Paper>
        ) : (
          <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<SaveIcon />}
            onClick={handleUpdateQueryClick}>
            Update Query
          </Button>
        ))}
      {savingQuery ? (
        <Paper style={{ width: '200px', border: 0, boxShadow: 'none', textAlign: 'center' }} >
          <CircularProgress size={30} />
        </Paper>
      ) : (
        <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<SaveAsIcon />}
          onClick={handleSaveQueryClick}>
          Save{savedQid ? ' New' : ''} Query
        </Button>
      )}
      {convertingQuery ? (
        <Paper style={{ width: '200px', border: 0, boxShadow: 'none', textAlign: 'center' }} >
          <CircularProgress size={30} />
        </Paper>
      ) : type === queryTypeBasic && (
        <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<CodeIcon />}
          onClick={handleConvertQueryClick}>
          Convert to SQL
        </Button>
      )}
      <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<DataObjectIcon />}
        onClick={handleApiUrlClick}>
        API URL
      </Button>
    </Stack >
  );

  let mainContent = (
    <Grid item lg={9}>
      {error && (
        <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ mb: 1 }}>{error}</Alert>
      )}
      {successMessage && (
        <Alert id="success-container" onClose={() => { setSuccessMessage(null) }} severity="success" sx={{ mb: 1 }}>{successMessage}</Alert>
      )}
      {type === queryTypeSql ? (
        <div style={{ border: '1px solid rgba(0,0,0,.15)', minWidth: '825px', minHeight: '600px' }}>
          <CodeMirror
            value={querySql}
            height="600px"
            autoFocus={true}
            extensions={[
              editorTheme,
              indentUnit.of('    '),
              EditorState.tabSize.of(4),
              EditorView.lineWrapping,
              sql({
                dialect: editorDialect,
                schema: {
                  data: fieldsList,
                },
                defaultTable: 'data',
                upperCaseKeywords: true,
              })]}
            onChange={(value) => { setQuerySql(value) }}
          />
        </div>
      ) : (
        <>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ mb: 1 }}>
            <SubHeader title="Fields" />
            <Typography variant="body1" mb={2}>
              Select which fields to include in the results, or check the box to include all fields.
            </Typography>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Autocomplete
                size="small"
                disabled={allFields}
                multiple
                value={fields.map(fieldId => fieldsMap[fieldId])}
                onChange={(e, newValues) => {
                  if (errorDetails && errorDetails.d1 === 'fields') {
                    setErrorDetails(null);
                  }
                  setFields([...fixedFields, ...newValues.map(value => value.id).filter(fieldId => fixedFields.indexOf(fieldId) === -1)]);
                }}
                options={FieldsList}
                getOptionDisabled={(option) => fixedFields.indexOf(option.id) !== -1}
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      variant="filled"
                      label={option.name}
                      {...getTagProps({ index })}
                      disabled={fixedFields.indexOf(option.id) !== -1}
                    />
                  ))
                }
                filterOptions={filterOptionsFields}
                renderInput={(params) => (
                  <TextField {...params}
                    error={errorDetails && errorDetails.d1 === 'fields'}
                    helperText={errorDetails && errorDetails.d1 === 'fields' ? errorDetails.helperText : ''}
                    label={allFields ? '*' : 'Fields'}
                  />
                )}
              />
              <FormGroup>
                <FormControlLabel control={<Checkbox size="small" checked={allFields} disabled={!allFields && (user.status < 2)} onChange={handleAllFieldsChange} />} label={'All Fields' + (user.status >= 2 ? '' : ' (paid subscribers only)')} style={{ width: '300px' }} />
              </FormGroup>
            </Stack>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ mb: 1 }}>
            <SubHeader title="Conditions" />
            <Typography variant="body1" mb={2}>
              Enter the conditions to filter the results by. If no conditions are provided, all securities will be returned.
            </Typography>
            <Typography variant="body1" mb={2}>
              In the Value entry for a condition, you can enter a number or reference another field (with the exceptions of Exchange, Sector, Industry).
            </Typography>
            <Typography variant="body1" mb={2}>
              A number value can be suffixed with 't', 'm', 'b', or 'r' for thousand, million, billion, or trillion, for example 500t or 2.5b.
            </Typography>
            {conditions.map((condition, index) => (
              <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={4} sx={{ mb: 1 }} key={index}>
                <IconButton onClick={() => handleRemoveConditionClick(index)} color="primary" size="large" >
                  <RemoveCircleIcon fontSize="large" />
                </IconButton>
                <Autocomplete
                  size="small"
                  onChange={(_, value) => handleConditionFieldChange(value, index)}
                  disablePortal
                  value={fieldsMap[condition.field] || null}
                  options={FieldsList.filter((field) => field.id !== 'ticker' && field.id !== 'name')}
                  getOptionLabel={(option) => option.name}
                  filterOptions={filterOptionsFields}
                  renderInput={(params) => (
                    <AlignedTextField {...params}
                      error={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'field' && errorDetails.index === index}
                      helperText={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'field' && errorDetails.index === index ? errorDetails.helperText : ''}
                      label="Field"
                    />
                  )}
                  style={{ width: '35%' }}
                />
                <Autocomplete
                  size="small"
                  onChange={(_, value) => handleConditionOperatorChange(value, index)}
                  disablePortal
                  value={operatorsMap[condition.operator] || null}
                  options={operators.filter((operator) => {
                    if (condition.field === 'sector' || condition.field === 'industry' || condition.field === 'exchange') {
                      if (operator.id === 'lt' || operator.id === 'gt') {
                        return false;
                      }
                      return true;
                    }
                    return true;
                  })}
                  getOptionLabel={(option) => option.text}
                  filterOptions={filterOptionsOperators}
                  renderInput={(params) => (
                    <AlignedTextField {...params}
                      error={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'operator' && errorDetails.index === index}
                      helperText={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'operator' && errorDetails.index === index ? errorDetails.helperText : ''}
                      label="Operator"
                    />
                  )}
                  style={{ width: '15%' }}
                />
                {condition.field === 'exchange' ? (
                  <Autocomplete
                    size="small"
                    onChange={(_, value) => handleConditionValueChange(value, index)}
                    disablePortal
                    value={condition.value || null}
                    options={exchanges}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <AlignedTextField {...params}
                        error={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index}
                        helperText={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index ? errorDetails.helperText : ''}
                        label="Exchange"
                        placeholder="Select Exchange"
                      />
                    )}
                    style={{ width: '35%' }}
                  />
                ) : condition.field === 'sector' ? (
                  <Autocomplete
                    size="small"
                    onChange={(_, value) => handleConditionValueChange(value, index)}
                    disablePortal
                    value={condition.value || null}
                    options={sectors}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <AlignedTextField {...params}
                        error={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index}
                        helperText={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index ? errorDetails.helperText : ''}
                        label="Sector"
                        placeholder="Select Sector"
                      />
                    )}
                    style={{ width: '35%' }}
                  />
                ) : condition.field === 'industry' ? (
                  <Autocomplete
                    size="small"
                    onChange={(_, value) => handleConditionValueChange(value, index)}
                    disablePortal
                    value={condition.value || null}
                    options={industries}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <AlignedTextField {...params}
                        error={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index}
                        helperText={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index ? errorDetails.helperText : ''}
                        label="Industry"
                        placeholder="Select Industry"
                      />
                    )}
                    style={{ width: '35%' }}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    onChange={(_, value) => handleConditionValueChange(value, index)}
                    onInputChange={(_, value) => handleConditionValueChange(value, index)}
                    disablePortal
                    freeSolo
                    value={fieldsMap[condition.value] || condition.value}
                    options={FieldsList.filter((field) => field.id !== 'ticker' && field.id !== 'name' && field.id !== 'exchange' && field.id !== 'sector' && field.id !== 'industry')}
                    getOptionLabel={(option) => option.name || option}
                    filterOptions={filterOptionsFields}
                    renderInput={(params) => (
                      <AlignedTextField {...params}
                        error={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index}
                        helperText={errorDetails && errorDetails.d1 === 'conditions' && errorDetails.d2 === 'value' && errorDetails.index === index ? errorDetails.helperText : ''}
                        label="Value"
                        placeholder="Number or Field"
                      />
                    )}
                    style={{ width: '35%' }}
                  />
                )}
              </Stack>
            ))}
            <IconButton onClick={handleAddConditionClick} color="primary" size="large">
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ mb: 1 }}>
            <SubHeader title="Tickers" />
            <Typography variant="body1" mb={2}>
              To restrict the results to a specific set of securities, enter the tickers below.
            </Typography>
            <Typography variant="body1" mb={2}>
              Either enter the tickers one by one using the autocomplete feature, or simply copy-paste a list of tickers, separated by commas or whitespace.
            </Typography>
            <Autocomplete
              id="input-tickers"
              size="small"
              multiple
              freeSolo
              filterSelectedOptions
              value={tickers.map(ticker => securityMap[ticker])}
              onChange={(_, newValues) => { handleTickersValueChange(newValues) }}
              clearOnBlur={true}
              options={securityList}
              getOptionLabel={(option) => option.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    variant="filled"
                    label={option?.ticker}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={renderTickerOption}
              filterOptions={filterOptionsTickers}
              renderInput={(params) => (
                <TextField {...params}
                  error={errorDetails && errorDetails.d1 === 'tickers'}
                  helperText={errorDetails && errorDetails.d1 === 'tickers' ? errorDetails.helperText : ''}
                  label="Tickers"
                  onBlur={(event) => { handleTickersInputChange(event.target.value) }}
                />
              )}
            />
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ mb: 1 }}>
            <SubHeader title="Order" />
            <Typography variant="body1" mb={2}>
              Optionally specify which field(s) to order the results by.
            </Typography>
            {orderings.map((ordering, index) => (
              <Stack direction="row" alignItems="center" spacing={4} sx={{ mb: 1 }} key={index}>
                <IconButton onClick={() => handleRemoveOrderingClick(index)} color="primary" size="large">
                  <RemoveCircleIcon fontSize="large" />
                </IconButton>
                <Autocomplete
                  size="small"
                  onChange={(_, value) => handleOrderingFieldChange(value, index)}
                  disablePortal
                  value={fieldsMap[ordering.field] || null}
                  options={FieldsList}
                  getOptionLabel={(option) => option.name}
                  filterOptions={filterOptionsFields}
                  renderInput={(params) => (
                    <AlignedTextField {...params}
                      error={errorDetails && errorDetails.d1 === 'order' && errorDetails.d2 === 'field' && errorDetails.index === index}
                      helperText={errorDetails && errorDetails.d1 === 'order' && errorDetails.d2 === 'field' && errorDetails.index === index ? errorDetails.helperText : ''}
                      label="Field"
                    />
                  )}
                  style={{ width: '35%' }}
                />
                <Autocomplete
                  size="small"
                  onChange={(_, value) => handleOrderingDirectionChange(value, index)}
                  disablePortal
                  value={directionsMap[ordering.direction] || null}
                  options={directions}
                  getOptionLabel={(option) => option.text}
                  filterOptions={filterOptionsDirections}
                  renderInput={(params) => (
                    <AlignedTextField {...params}
                      error={errorDetails && errorDetails.d1 === 'order' && errorDetails.d2 === 'direction' && errorDetails.index === index}
                      helperText={errorDetails && errorDetails.d1 === 'order' && errorDetails.d2 === 'direction' && errorDetails.index === index ? errorDetails.helperText : ''}
                      label="Direction"
                    />
                  )}
                  style={{ width: '15%' }}
                />
              </Stack>
            ))}
            <IconButton onClick={handleAddOrderingClick} color="primary" size="large">
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ mb: 1 }}>
            <SubHeader title="Limit" />
            <Typography variant="body1" mb={2}>
              Optionally limit the results to a specified number of records.
            </Typography>
            <TextField
              error={errorDetails && errorDetails.d1 === 'limit'}
              helperText={errorDetails && errorDetails.d1 === 'limit' ? errorDetails.helperText : ''}
              size="small"
              label="Record Limit"
              variant="outlined"
              value={limit === null ? '' : limit}
              onChange={(event) => {
                if (errorDetails && errorDetails.d1 === 'limit') {
                  setErrorDetails(null);
                }
                event.target.value = event.target.value.replace(/\D/g, '');
                setLimit(event.target.value !== '' ? Number(event.target.value) : null);
              }}
              inputProps={{ maxLength: 5 }}
              style={{ width: '125px' }}
            />
          </Box>
        </>
      )
      }
    </Grid >
  );

  let loadingContent = (
    <Grid item lg={9} style={{ minHeight: '800px' }}>
      <Box style={{ display: 'flex', minHeight: '500px' }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={100} />
      </Box>
    </Grid>
  );

  let pageTitle = 'Query';
  if (savedQueryName) {
    pageTitle += ': ' + savedQueryName;
  }

  let content = (
    <Container maxWidth={false}>
      <Box sx={{ mb: 1 }}>
        <PageHeader title={pageTitle} />
        <Typography variant="body1" mb={2}>
          {type === queryTypeSql ? 'Enter an SQL SELECT' : 'Specify a'} query below. For guidance, see some <Link href="/examples">example queries</Link> and the list of <Link component={RouterLink} to="/fields">data fields</Link> available.
        </Typography>
      </Box>
      <Typography variant="body1" mb={2}>
        <Link component={RouterLink} to={'/query?type=' + (type === 1 ? '2' : '1')}>Switch to {type === 1 ? 'SQL' : 'Basic'} Mode</Link>
      </Typography >
      <Grid container spacing={2}>
        {loading ? loadingContent : mainContent}
        {buttonContent}
        {buttonContentBottom}
      </Grid>
      {exportResultsDialogContent}
      {saveQueryDialogContent}
      {apiUrlDialogContent}
    </Container >
  );

  return content;
}

export default Query;