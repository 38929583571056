const getLocalStorageJSON = (key) => {
    let value = null;
    const valueJSON = localStorage.getItem(key);
    if (valueJSON) {
        try {
            value = JSON.parse(valueJSON);
        } catch { }
    }
    return value;
}

const setLocalStorageJSON = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}
const getUserLocalStorage = () => {
    const user = getLocalStorageJSON('user');
    if (user === null) {
        return { status: 0 };
    }
    return user;
}

const setUserLocalStorage = (user) => {
    setLocalStorageJSON('user', user);
}

const navigateToQuery = (navigate, options) => {
    let url = '/query';
    if (getLocalStorageJSON('type') === 2) {
        url += '?type=2';
    }

    if (options) {
        navigate(url, options);
    } else {
        navigate(url);
    }
}

const fetchService = (method, url, params) => {
    if (method === 'POST') {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-By-EOD': '1',
            },
            body: new URLSearchParams({
                ...params,
                r: window.r || getCookie('c'),
            })
        });
    } else {
        return fetch(url + (Object.keys(params).length === 0 ? '' : '?' + new URLSearchParams({ ...params })), {
            method: 'GET',
        });
    }
}

const getCookie = (name) => document.cookie
    .split('; ')
    .find((keyVal) => keyVal.startsWith(name + '='))
    ?.split('=')[1] || '';

const dataUpdateStatusNone = 0
const dataUpdateStatusInProgress = 1
const dataUpdateStatusDelayed = 2

export {
    fetchService,
    getCookie,
    getLocalStorageJSON,
    setLocalStorageJSON,
    getUserLocalStorage,
    setUserLocalStorage,
    navigateToQuery,
    dataUpdateStatusNone,
    dataUpdateStatusInProgress,
    dataUpdateStatusDelayed,
};

