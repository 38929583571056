const addCommas = (numberString) => {
    if (numberString.length === 0) {
        return numberString;
    }

    let signPart = '';
    let numberPart = numberString;

    if (numberString[0] === '-') {
        signPart = '-'
        numberPart = numberString.slice(1);
    }

    let decimalIndex = numberPart.indexOf('.');

    if (decimalIndex === 0) {
        return signPart + numberPart;
    }

    let integerPart = numberPart;
    let decimalPart = '';

    if (decimalIndex > 0) {
        integerPart = numberPart.slice(0, decimalIndex);
        decimalPart = numberPart.slice(decimalIndex);
    }

    let modIntegerBytes = new Array(integerPart.length + Math.floor((integerPart.length - 1) / 3));
    let integerPartIndex = integerPart.length - 1;
    let modIntegerBytesIndex = modIntegerBytes.length - 1;
    let digitsPlaced = 0;

    while (true) {
        modIntegerBytes[modIntegerBytesIndex] = integerPart[integerPartIndex];

        if (integerPartIndex === 0) {
            break;
        }

        digitsPlaced++;

        if (digitsPlaced === 3) {
            modIntegerBytesIndex--;
            modIntegerBytes[modIntegerBytesIndex] = ',';
            digitsPlaced = 0;
        }

        integerPartIndex--;
        modIntegerBytesIndex--;
    }

    return signPart + modIntegerBytes.join('') + decimalPart;
}

const valueFormatter = (columnType) => (params) => {
    if (params.value === null) {
        return '';
    }

    if (
        columnType.typeName === 'VARCHAR' ||
        columnType.typeName === 'CHAR' ||
        columnType.typeName === 'TINYTEXT' ||
        columnType.typeName === 'TEXT' ||
        columnType.typeName === 'MEDIUMTEXT' ||
        columnType.typeName === 'LONGTEXT' ||
        columnType.typeName === 'DATE' ||
        columnType.typeName === 'DATETIME' ||
        columnType.typeName === 'TIMESTAMP' ||
        columnType.typeName === 'TIME' ||
        columnType.typeName === 'YEAR'
    ) {
        return String(params.value);
    }

    let s;
    if (columnType.typeName === 'DECIMAL') {
        if (columnType.decimalSize !== null) {
            s = params.value.toFixed(columnType.decimalSize);
        } else {
            s = params.value.toFixed(2);
        }
    } else {
        s = String(params.value);
    }

    s = addCommas(s);
    return s;
}

export { addCommas, valueFormatter };
