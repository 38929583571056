import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import PageHeader from './PageHeader';

import { getUserLocalStorage, setUserLocalStorage, fetchService } from './Util';

const theme = createTheme();

const Signup = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);
    const [errorDetails, setErrorDetails] = useState(() => null);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const qid = searchParams.get('qid');

    useEffect(() => {
        if (user.status >= 1) {
            navigate('/account');
            return;
        }

        document.title = 'Sign Up - EODmetrics';
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/signup', {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value,
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    setError(data['error']);
                    if (data['errorDetails']) {
                        setErrorDetails(data['errorDetails']);
                    } else {
                        setErrorDetails(null);
                    }
                } else {
                    setError(null);
                    setErrorDetails(null);
                    setUserLocalStorage({ status: 1 });
                    navigate('/subscribe' + (qid ? '?qid=' + qid : ''));
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
                setErrorDetails(null);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PageHeader title="Sign Up" />
                    <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ mt: 2, mb: 3 }}>
                        Sign up for a completely free account to start running your own queries.
                    </Typography>
                </Box>
            </Container>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {error && (!errorDetails || errorDetails['login']) &&
                        <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                    }
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="email"
                                    required
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    error={errorDetails && errorDetails?.field === 'email'}
                                    helperText={errorDetails && errorDetails?.field === 'email' ? error : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="new-password"
                                    error={errorDetails && errorDetails?.field === 'password'}
                                    helperText={errorDetails && errorDetails?.field === 'password' ? error : ''}
                                />
                            </Grid>
                        </Grid>
                        <Box style={{ minHeight: '40px' }} sx={{ display: 'flex', mt: 3, mb: 2 }}
                            justifyContent="center"
                        >
                            {submitting ? (
                                <CircularProgress size={40} />
                            ) : (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    Sign Up
                                </Button>
                            )}
                        </Box>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Typography variant="body2">
                                    Already have an account? <Link component={RouterLink} to="/login">Log in</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default Signup;