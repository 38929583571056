import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

const Reinstate = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);

    const [dateNextPayment, setDateNextPayment] = useState(() => null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!user.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (user.status === 1) {
            navigate('/account');
            return;
        }

        document.title = 'Reinstate Subscription - EODmetrics';

        fetchService('GET', '/service/account', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setLoading(false);
                    setError('Error retrieving account information: ' + data['error'])
                } else {
                    setDateNextPayment(data['dateNextPayment']);
                    setError(null);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error retrieving account information: ' + err.toString())
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let loadingContent = (
        <Box style={{ display: 'flex', minHeight: '285px' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={80} />
        </Box>
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/reinstate', {})
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError(data['error']);
                } else {
                    setError(null);
                    navigate('/account', { state: { successMessage: 'Your subscription has been reinstated.' } });
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
            });
    }

    return (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Reinstate Subscription" />
                {loading ? loadingContent : (
                    <>
                        <Typography variant="body1" mb={2}>
                            Click <strong>Confirm Reinstatement</strong> below to reinstate your subscription.
                        </Typography>
                        <Typography variant="body1" mb={2}>
                            By reinstating, you understand that your billing will continue on schedule on your next payment date of  <strong>{dateNextPayment}</strong>.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {error &&
                                <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                            }
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                {submitting ? (
                                    <Box style={{ minHeight: '82px' }} sx={{ display: 'flex', mt: 3, mb: 2 }}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress size={40} />
                                    </Box>
                                ) : (
                                    <Box style={{ minHeight: '82px' }} sx={{ mt: 3, mb: 2 }}
                                        justifyContent="center"
                                    >
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                        >
                                            Confirm Reinstatement
                                        </Button>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 1 }}
                                            onClick={() => navigate('/account')}
                                        >
                                            Go Back
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
}

export default Reinstate;