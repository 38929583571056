import React, { useEffect } from 'react';
import { Link as RouterLink } from "react-router-dom";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PageHeader from './PageHeader';
import SubHeader from './SubHeader';

const Privacy = (props) => {
    useEffect(() => {
        document.title = 'Privacy Policy - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Privacy Policy" />
                <Typography variant="body1" mb={2}>
                    This page contains the privacy policy ("Privacy Policy") that covers our practices regarding the information we collect through the EODmetrics.com website ("EODmetrics" or the "Site") from users, including you, how that information may be used, and with whom it may be shared. Capitalized terms not defined in this Privacy Policy have the meanings given in our <Link component={RouterLink} to="/terms">Terms of Use</Link>. This Privacy Policy may be modified in the future. When we post changes to this Privacy Policy, we will revise the last updated date at the bottom of this page. We recommend that you check our Site from time to time to inform yourself of any changes in this Privacy Policy. Market Computing is not responsible for the content or the privacy policies of any websites to which it may link.
                </Typography>

                <SubHeader title="User Consent" />

                <Typography variant="body1" mb={2}>
                    By visiting our Site, you agree to the terms of this Privacy Policy and you expressly consent to the collection, use and disclosure of Personal Information (as defined below) in accordance with this Privacy Policy. The term "Personal Information" is used throughout this Privacy Policy to describe information that can be associated with a specific person and can be used to identify that person. We do not consider Personal Information to include information that has been anonymized so that it does not identify a specific person.
                </Typography>

                <SubHeader title="What Information We Collect" />

                <Typography variant="body1" mb={2}>
                    In general, when you visit the Site we may collect your IP address and standard web log information, such as the pages you accessed on our Site. We may also collect certain geo-location information. If you do not agree to our collection of this information, you may not be able to use our services. When you become a subscriber to our services, we may collect the following information from you:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" mb={2}>
                            Identification information such as your name and email address.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" mb={2}>
                            Financial information such as your payment method if you transact business with us (valid credit card number, type, expiration date or other financial information).
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" mb={2}>
                            We may require additional information from you before permitting you to use our services to verify your identity or to manage risk and compliance throughout our relationship. We may also obtain information about you from third parties such as identity verification, fraud prevention and similar services.
                        </Typography>
                    </li>
                </ul>
                {/* </Typography> */}

                <SubHeader title="Cookies" />

                <Typography variant="body1" mb={2}>
                    When you access the Site, we, or companies we hire to track how our Site is used, may place small data files called "cookies" on your computer. "Cookies" are small amounts of data that a website can send to your web browser and store on your computer. Cookies do not contain Personal Information (unless you choose to provide it). Cookies can note that your browser was used to visit certain sites, pages, or advertisements on a certain date. We use cookies to enhance navigation and the functionality of our Site, to securely verify your identity, and to personalize aspects of your experience on our Site. We also use cookies to let us know how users are using our Site so that we can improve our Site and your experience on it. We may use certain third party analytics services, such as Google Analytics, to collect data on how our Site is used. These services may set cookies and collect data such as the web address of the page you're visiting and your IP address, but will not be used to track, collect or upload any Personal Information. We may permit certain third party ad servers to set cookies and use related technology (such as web beacons) through our Site to track performance of advertisements and to collect aggregate data on web page viewing. Most browsers can be set to notify you when a cookie is being placed on your computer. You can set most browsers to refuse to accept cookies, although this may affect your use of the Site. For more information on working with cookies, consult the user manual, FAQ, or Help function of your specific browser and operating system software.
                </Typography>

                <SubHeader title="How We Use the Information We Collect" />

                <Typography variant="body1" mb={2}>
                    In general, Personal Information you submit to us regarding you or your company is used to deliver our products and services, manage your account and to provide you with the services and customer support you request. We may use financial information such as your payment method to process payment, to protect against or identify possible fraudulent transactions, and as otherwise needed to manage our business. In addition, Personal Information or anonymous log file information may be used to perform statistical analyses of user behavior in order to measure interest in the various areas of our Site, for development purposes, and to inform advertisers and partners about users clicking on their advertising or other messages. In these analyses, we will disclose information to third parties only in aggregate form. If you become a subscriber, you agree that Market Computing may contact you by email regarding account status, changes to applicable terms and conditions that may apply to you, and to confirm your subscription.  Market Computing may also periodically send promotional email, which you will have the opportunity to unsubscribe from. In addition, if you provide your email address or other contact data in connection with a request for information about our services, Market Computing will use that information to contact you.
                </Typography>

                <SubHeader title="Who We Share the Information With" />

                <Typography variant="body1" mb={2}>
                    Market Computing may share your Personal Information with third party service providers as necessary to: provide you with the services that we offer you through our Site; conduct quality assurance testing; facilitate creation of accounts; process any payments; provide technical support; and/or provide other services. Additionally, if you entered the Site through a website maintained by one of our affiliates or business partners, then your Personal Information may be shared with that affiliate or business partner, provided that use of the Personal Information does not violate this Privacy Policy. This Site may contain links to other websites. While we encourage our business partners to adopt privacy policies that build users' trust and confidence in the Internet, we are not responsible for, do not review, and have no control over these outside websites, their privacy practices or their content. We may also disclose your information, including Personal Information, when we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to comply with applicable law or cooperate with law enforcement; or to enforce our Terms of Use or other agreements and policies.
                </Typography>
                <Typography variant="body1" mb={2}>
                    This Privacy Policy was last updated on March 31, 2023.
                </Typography>
            </Box>
        </Container >
    );
}

export default Privacy;