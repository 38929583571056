import { React } from 'react';

import Typography from '@mui/material/Typography';

const SubHeader = (props) => {
    return (
        <Typography variant="h6" component="h2" mb={2} style={props.style}>
            {props.title}
        </Typography>
    )
}

export default SubHeader;
