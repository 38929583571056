import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from './App';

import About from './About';
import Account from './Account';
import Cancel from './Cancel';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import Contact from './Contact';
import Docs from './Docs';
import Examples from './Examples';
import Fields from './Fields';
import ForgotPassword from './ForgotPassword';
import Home from './Home';
import Login from './Login';
import NotFound from './NotFound';
import Privacy from './Privacy';
import Query from './Query';
import Reinstate from './Reinstate';
import ResetPassword from './ResetPassword';
import Results from './Results';
import SavedQueries from './SavedQueries';
import Signup from './Signup';
import Subscribe from './Subscribe';
// import SubscribeApril23 from './SubscribeApril23';
// import SubscribeJul23 from './SubscribeJul23';
import Ticker from './Ticker';
import Terms from './Terms';
import UpdatePaymentMethod from './UpdatePaymentMethod';

// import AIQuery from './AIQuery';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="query" element={<Query />}>
          <Route path=":qid" />
        </Route>

        {/* <Route path="ai-query" element={<AIQuery />} /> */}

        <Route path="results/:qid" element={<Results />} />
        <Route path="ticker/:ticker" element={<Ticker />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="docs" element={<Docs />} />
        <Route path="fields" element={<Fields />} />
        <Route path="examples" element={<Examples />} />
        <Route path="signup" element={<Signup />} />
        <Route path="subscribe" element={<Subscribe />} />
        {/* <Route path="subscribe/april23" element={<SubscribeApril23 />} /> */}
        {/* <Route path="subscribe/jul23" element={<SubscribeJul23 />} /> */}
        <Route path="account" element={<Account />} />
        <Route path="saved-queries" element={<SavedQueries />} />
        <Route path="change-email" element={<ChangeEmail />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="login" element={<Login />} />
        <Route path="update-payment-method" element={<UpdatePaymentMethod />} />
        <Route path="cancel" element={<Cancel />} />
        <Route path="reinstate" element={<Reinstate />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
