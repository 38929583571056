import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import FieldsList from './FieldsList';
import PageHeader from './PageHeader';


const Fields = (props) => {
    useEffect(() => {
        document.title = 'Data Fields - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <PageHeader title="Data Fields" />
            <Typography variant="body1" mb={2}>
                Every day after market close, we update the following fields for all securities in our database.
            </Typography>
            <Typography variant="body1" mb={2}>
                Each field is presented below as:
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>Field Name</strong> (SQL/API Column Name)
            </Typography>
            <Typography variant="body1" mb={2}>
                Field description.
            </Typography>
            {FieldsList.map((field) => (
                <Box key={field.id}>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body1" mb={0}>
                        <strong>{field.name}</strong> ({field.id})
                    </Typography>
                    <Typography variant="body1" mb={1}>
                        {field.description}
                    </Typography>
                </Box>
            ))}
        </Container >
    );
}

export default Fields;