import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate, useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import {
    DataGrid,
    gridClasses,
    GridFooterContainer,
    gridPageCountSelector,
    gridPageSelector,
    GridToolbarQuickFilter,
    useGridApiContext,
    useGridSelector,
    GridPagination
} from "@mui/x-data-grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { addCommas, valueFormatter } from './ValueFormatter';
import {
    getUserLocalStorage,
    getLocalStorageJSON,
    setLocalStorageJSON,
    fetchService,
    dataUpdateStatusNone,
    dataUpdateStatusInProgress,
    dataUpdateStatusDelayed,
} from './Util';
import PageHeader from './PageHeader';
import SubHeader from './SubHeader';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'rgba(0, 0, 0, .05)',
    },
    '& .pointer': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#f3fff9 !important',
    },
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader:first-of-type, .MuiDataGrid-cell:first-of-type': {
        borderLeft: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
    },
    '& .MuiDataGrid-cell': {
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: '#fcf8e3',
    },
    // '& .MuiPaginationItem-root': {
    //     borderRadius: 0,
    // },
    '& .MuiTablePagination-displayedRows': {
        minWidth: '131px',
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
        outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeaders': {
        borderTop: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
        backgroundColor: '#f3f9ff',
    },
    '& .MuiDataGrid-footerContainer': {
        borderTop: 0,
        justifyContent: 'space-between',
        // width: '1200px',
    },
    '& .MuiDataGrid-main': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
    },
    // '& .MuiDataGrid-cell--withRenderer': {
    //     position: 'sticky',
    //     overflow: 'hidden',
    //     zIndex: 1,
    //     backgroundColor: '#fff',
    //     left: '0',
    //     float: 'left',
    //     boxShadow: '2px 0px 4px -2px rgba(0, 0, 0, 0.21)',
    // },
    display: 'flex',
    flexDirection: 'column-reverse',

    /* if sticky column headers are needed: */
    // '& .MuiDataGrid-columnHeaders': {
    //     position: "sticky",
    //     backgroundColor: theme.palette.background.paper,
    //     zIndex: theme.zIndex.mobileStepper - 1,
    // },
    // '& .MuiDataGrid-virtualScroller': {
    //     marginTop: "0 !important"
    // },
    // '& .MuiDataGrid-main': {
    //     overflow: "visible"
    // },
}));

let globalApiRef;

function TablePaginationActions(props) {
    const apiRef = useGridApiContext();
    globalApiRef = apiRef;
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handleFirstPageButtonClick = (event) => {
        apiRef.current.setPage(0);
    };

    const handlePrevButtonClick = (event) => {
        apiRef.current.setPage(page - 1);
    };

    const handleNextButtonClick = (event) => {
        apiRef.current.setPage(page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        apiRef.current.setPage(pageCount - 1);
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handlePrevButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= pageCount - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= pageCount - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </Box>
    );
}

const CustomFooter = (props) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);

    const params = useParams();
    const qid = params.qid;

    const navigate = useNavigate();

    // support arrow key paging
    document.onkeydown = (e) => {
        switch (e.key) {
            // case 'Left':
            // case 'ArrowLeft':
            //     apiRef.current.setPage(page - 1);
            //     break;
            case 'Up':
            case 'ArrowUp':
                apiRef.current.setPage(page - 1);
                break;
            // case 'Right':
            // case 'ArrowRight':
            //     apiRef.current.setPage(page + 1);
            //     break;
            case 'Down':
            case 'ArrowDown':
                apiRef.current.setPage(page + 1);
                break;
            default: return;
        }
        e.preventDefault();
    };

    let buttonContent = (
        <Stack direction="row" spacing={5} sx={{ mb: 1 }}>
            <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<EditIcon />}
                onClick={() => {
                    navigate('/results/' + qid + (props.savedQid ? '?sqid=' + props.savedQid : ''), { replace: true, state: { dataGridState: apiRef.current.exportState() } });
                    navigate('/query/' + props.qid + (props.savedQid ? '?sqid=' + props.savedQid : ''));
                }}>
                Edit Query
            </Button>
            <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<DownloadIcon />}
                onClick={props.handleExportResultsClick}>
                Export Results
            </Button>
        </Stack>
    );

    return (
        <GridFooterContainer>
            {buttonContent}
            <GridPagination />
            <GridToolbarQuickFilter debounceMs={500} />
        </GridFooterContainer>
    );
}

const Results = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [error, setError] = useState(() => null);
    const [columns, setColumns] = useState(() => []);
    const [rows, setRows] = useState(() => []);
    const [numRecords, setNumRecords] = useState(() => 0);
    const [dataUpdateDate, setDataUpdateDate] = useState(() => null);
    const [dataUpdateStatus, setDataUpdateStatus] = useState(() => dataUpdateStatusNone);
    const [specifiedDataGridContainerWidth, setSpecifiedDataGridContainerWidth] = useState(() => null);

    const [exportResultsDialogOpen, setExportResultsDialogOpen] = useState(() => false);
    const [exportFormat, setExportFormat] = useState(() => getLocalStorageJSON('exportFormat') || '');
    const [exportZip, setExportZip] = useState(() => getLocalStorageJSON('exportZip') || false);
    const [exportPretty, setExportPretty] = useState(() => getLocalStorageJSON('exportPretty') || false);
    const [exportDialogError, setExportDialogError] = useState(() => false);

    const [savedQid, setSavedQid] = useState(() => '');
    const [tickerFieldPresent, setTickerFieldPresent] = useState(() => false);

    const params = useParams();
    const [searchParams] = useSearchParams();
    const qid = params.qid;

    const [contextMenu, setContextMenu] = useState(() => null);
    const [contextMenuTicker, setContextMenuTicker] = useState(() => null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
                : null,
        );
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const defaultNumericColumnWidth = 125;
    const minDataGridContainerWidth = 1200;
    let securityNameColumnWidth = 250;

    const navigate = useNavigate();
    const location = useLocation();

    const [successMessage, setSuccessMessage] = useState(() => location?.state?.successMessage || null);

    useEffect(() => {
        const savedQidParam = searchParams.get('sqid');

        if (savedQidParam) {
            setSavedQid(savedQidParam);
        }

        document.title = 'Query Results - EODmetrics';
        window.scrollTo(0, 0);
        fetchService('GET', '/service/query-results', {
            qid: qid,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    setLoading(false);
                    setError('Error loading query results: ' + data['error']);
                    setNumRecords(0);
                } else {
                    let hasSecurityNameColumn = false;
                    for (let j = 0; j < data['columnNames'].length; j++) {
                        if (data['columnNames'][j] === 'name' || data['columnNames'][j] === 'Security Name') {
                            hasSecurityNameColumn = true;
                        }
                    }

                    let doFluidNumericColumnWidths = true;

                    let unalteredLength;
                    let numericColumnWidth = defaultNumericColumnWidth;
                    if (hasSecurityNameColumn) {
                        unalteredLength = (data['columnNames'].length - 1) * defaultNumericColumnWidth + securityNameColumnWidth;
                        if (unalteredLength < minDataGridContainerWidth && data['columnNames'].length - 1 > 0) {
                            numericColumnWidth = (minDataGridContainerWidth - securityNameColumnWidth) / (data['columnNames'].length - 1);
                            if (numericColumnWidth > securityNameColumnWidth) {
                                numericColumnWidth = minDataGridContainerWidth / data['columnNames'].length;
                                securityNameColumnWidth = minDataGridContainerWidth / data['columnNames'].length;
                            }
                            setSpecifiedDataGridContainerWidth(minDataGridContainerWidth);
                            doFluidNumericColumnWidths = false;
                        }
                    } else {
                        unalteredLength = data['columnNames'].length * defaultNumericColumnWidth;
                        if (unalteredLength < minDataGridContainerWidth) {
                            numericColumnWidth = minDataGridContainerWidth / data['columnNames'].length;
                            setSpecifiedDataGridContainerWidth(minDataGridContainerWidth);
                            doFluidNumericColumnWidths = false;
                        }
                    }

                    let newColumns = [];
                    let tickerColumnIdx = null;
                    for (let j = 0; j < data['columnNames'].length; j++) {
                        let headerName = data['columnNames'][j];

                        if (headerName.toLowerCase() === 'ticker') {
                            setTickerFieldPresent(true);
                            tickerColumnIdx = j;
                        }

                        let headerAlign, align;
                        if (
                            data['columnTypes'][j].typeName === 'VARCHAR' ||
                            data['columnTypes'][j].typeName === 'CHAR' ||
                            data['columnTypes'][j].typeName === 'TINYTEXT' ||
                            data['columnTypes'][j].typeName === 'TEXT' ||
                            data['columnTypes'][j].typeName === 'MEDIUMTEXT' ||
                            data['columnTypes'][j].typeName === 'LONGTEXT' ||
                            data['columnTypes'][j].typeName === 'TIME' ||
                            data['columnTypes'][j].typeName === 'YEAR'
                        ) {
                            headerAlign = 'left';
                            align = 'left';
                        } else {
                            headerAlign = 'right';
                            align = 'right';
                        }

                        let valFormatter = valueFormatter(data['columnTypes'][j]);

                        if (headerName === 'name' || headerName === 'Security Name') {
                            newColumns.push({
                                field: 'c' + String(j),
                                headerName: headerName,
                                headerAlign: headerAlign,
                                align: align,
                                width: securityNameColumnWidth,
                                valueFormatter: valFormatter,
                            });
                        } else {
                            if (doFluidNumericColumnWidths) {
                                newColumns.push({
                                    field: 'c' + String(j),
                                    headerName: headerName,
                                    headerAlign: headerAlign,
                                    align: align,
                                    flex: 1,
                                    minWidth: defaultNumericColumnWidth,
                                    valueFormatter: valFormatter,
                                });
                            } else {
                                newColumns.push({
                                    field: 'c' + String(j),
                                    headerName: headerName,
                                    headerAlign: headerAlign,
                                    align: align,
                                    width: numericColumnWidth,
                                    minWidth: defaultNumericColumnWidth,
                                    valueFormatter: valFormatter,
                                });
                            }
                        }
                    }

                    let newRows = [];
                    for (let i = 0; i < data['records'].length; i++) {
                        let row = { id: i }
                        for (let j = 0; j < data['columnNames'].length; j++) {
                            row['c' + String(j)] = data['records'][i][j];
                            if (j === tickerColumnIdx) {
                                row.ticker = data['records'][i][j];
                            }
                        }
                        newRows.push(row);
                    }

                    setColumns(newColumns);
                    setRows(newRows);
                    setNumRecords(data['numRecords']);
                    setDataUpdateDate(data['dataUpdateDate']);
                    setDataUpdateStatus(data['dataUpdateStatus']);
                    setLoading(false);
                    setError(null);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error loading query results: ' + err.toString());
                setNumRecords(0);
            });
    }, []);

    const handleOpenInNewTabClick = () => {
        handleContextMenuClose();
        window.open('/ticker/' + contextMenuTicker, '_blank');
    }

    const handleExportResultsDialogClose = () => {
        setExportResultsDialogOpen(false);
        setExportDialogError(false);
    }

    const handleExportResultsClick = () => {
        let userLocalStorage = getUserLocalStorage();
        if (!userLocalStorage.status) {
            navigate('/results/' + qid + (savedQid ? '?sqid=' + savedQid : ''), { replace: true, state: { dataGridState: globalApiRef.current.exportState() } });
            navigate('/signup?qid=' + qid);
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/results/' + qid + (savedQid ? '?sqid=' + savedQid : ''), { replace: true, state: { dataGridState: globalApiRef.current.exportState() } });
            navigate('/subscribe?qid=' + qid);
            return;
        }

        setExportResultsDialogOpen(true);
    }

    const handleExport = (event) => {
        event.preventDefault();

        let userLocalStorage = getUserLocalStorage();
        if (!userLocalStorage.status) {
            navigate('/results/' + qid + (savedQid ? '?sqid=' + savedQid : ''), { replace: true, state: { dataGridState: globalApiRef.current.exportState() } });
            navigate('/signup?qid=' + qid);
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/results/' + qid + (savedQid ? '?sqid=' + savedQid : ''), { replace: true, state: { dataGridState: globalApiRef.current.exportState() } });
            navigate('/subscribe?qid=' + qid);
            return;
        }

        if (!exportFormat) {
            setExportDialogError(true);
            return
        }

        setExportDialogError(false);
        handleExportResultsDialogClose();
        setExportLocalStorage();
        window.location.href = '/service/export-results?qid=' + qid +
            '&format=' + exportFormat +
            '&zip=' + (exportZip ? '1' : '0') +
            '&pretty=' + (exportPretty ? '1' : '0');
    }

    const setExportLocalStorage = () => {
        setLocalStorageJSON('exportFormat', exportFormat);
        setLocalStorageJSON('exportZip', exportZip);
        setLocalStorageJSON('exportPretty', exportPretty);
    }

    let dataGridContainerStyle = { display: 'flex', height: '100%', margin: '0 auto' };
    if (specifiedDataGridContainerWidth) {
        dataGridContainerStyle.width = String(specifiedDataGridContainerWidth) + 'px';
    }

    let initialState;
    if (location.state && location.state.dataGridState) {
        initialState = location.state.dataGridState;
    } else {
        initialState = {
            pagination: {
                pageSize: 15,
            },
        };
    }

    let mainContent = (
        <div style={dataGridContainerStyle}>
            <div style={{ flexGrow: 1 }}>
                {error && (
                    <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ mb: 1 }}>{error}</Alert>
                )}
                {successMessage && (
                    <Alert id="success-container" onClose={() => { setSuccessMessage(null) }} severity="success" sx={{ mb: 1 }}>{successMessage}</Alert>
                )}
                <StyledDataGrid
                    density="compact"
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableSelectionOnClick
                    autoHeight
                    rows={rows}
                    columns={columns}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd') + (tickerFieldPresent ? ' pointer' : '')}
                    rowsPerPageOptions={[]}
                    initialState={initialState}
                    components={{
                        Footer: CustomFooter,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No results found.
                            </Stack>
                        ),
                    }}
                    componentsProps={{
                        footer: { qid, savedQid, navigate, handleExportResultsClick },
                        row: {
                            onContextMenu: (event) => {
                                if (!tickerFieldPresent) {
                                    return;
                                }
                                setContextMenuTicker(rows[Number(event.currentTarget.getAttribute('data-id'))].ticker);
                                handleContextMenu(event);
                            },
                        },
                    }}
                    localeText={{
                        MuiTablePagination: {
                            ActionsComponent: TablePaginationActions,
                            labelDisplayedRows: ({ from, to, count }) => {
                                return `${from} - ${to} of ${addCommas(String(count))}`;
                            }
                        },
                    }}
                    onRowClick={(params, event) => {
                        if (!tickerFieldPresent) {
                            return;
                        }
                        if (event.ctrlKey) {
                            window.open('/ticker/' + params.row.ticker, '_blank');
                        } else {
                            navigate('/results/' + qid + (savedQid ? '?sqid=' + savedQid : ''), { replace: true, state: { dataGridState: globalApiRef.current.exportState() } });
                            navigate('/ticker/' + params.row.ticker);
                        }
                    }}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleContextMenuClose();
                            },
                        },
                    }}
                >
                    <MenuItem onClick={handleOpenInNewTabClick}>
                        <ListItemIcon>
                            <OpenInNewIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Open in new tab
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        </div >
    );

    let exportResultsDialogContent = (
        <Dialog open={exportResultsDialogOpen} onClose={handleExportResultsDialogClose}>
            <Box component="form" onSubmit={handleExport}>
                <DialogTitle>Export Results</DialogTitle>
                <DialogContent style={{ width: '350px'/*, height: '150px'*/ }}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 120 }}
                            size="small"
                            error={exportDialogError}
                        >
                            <InputLabel>Format</InputLabel>
                            <Select
                                value={exportFormat}
                                label="Format"
                                onChange={(event) => setExportFormat(event.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="csv">CSV</MenuItem>
                                <MenuItem value="excel">Excel</MenuItem>
                                <MenuItem value="json">JSON</MenuItem>
                                <MenuItem value="xml">XML</MenuItem>
                            </Select>
                            {exportDialogError && (
                                <FormHelperText>Select export format</FormHelperText>
                            )}
                        </FormControl>
                        <FormControlLabel control={<Checkbox />}
                            label="Zip File"
                            checked={exportZip}
                            onChange={(event) => setExportZip(event.target.checked)} />
                        <FormControlLabel control={<Checkbox />}
                            label="Pretty Print (line breaks and indentation)"
                            checked={exportPretty}
                            onChange={(event) => setExportPretty(event.target.checked)}
                            style={{ display: exportFormat === 'json' || exportFormat === 'xml' ? 'inline-flex' : 'none' }}
                        />
                    </FormGroup>
                    {/* <div style={{ width: '338px' }}></div> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleExportResultsDialogClose}>Cancel</Button>
                    <Button type="submit" autoFocus={exportResultsDialogOpen}>Export</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

    let loadingContent = (
        <Box style={{ display: 'flex', minHeight: '500px', marginBottom: '500px' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={100} />
        </Box>
    );

    let content = (
        <Container maxWidth={false}>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Query Results" />
                <SubHeader style={{ visibility: (loading || !dataUpdateDate) ? 'hidden' : 'visible' }} title={'Data Updated: ' + dataUpdateDate} />
                {dataUpdateStatus === dataUpdateStatusInProgress &&
                    <Alert severity="info">Data update in progress, next update expected by 7:00 p.m. Eastern Time</Alert>
                }
                {dataUpdateStatus === dataUpdateStatusDelayed &&
                    <Alert severity="warning">Data update in progress, next update expected soon</Alert>
                }
            </Box>
            {loading ? loadingContent : (
                <>
                    <div style={{ width: '1200px', margin: '0 auto' }}>
                        {!user.status && rows.length < numRecords && rows.length === 15 &&
                            <Typography variant="body1" mb={4}>
                                The table below contains only the first 15 of {numRecords} total records returned by this query. <Link component={RouterLink} to={'/signup?qid=' + qid}>Sign up</Link> for a free account to access more results.
                            </Typography>
                        }
                        {user.status === 1 && rows.length < numRecords && rows.length === 150 &&
                            <Typography variant="body1" mb={4}>
                                The table below contains only the first 150 of {numRecords} total records returned by this query. <Link component={RouterLink} to={'/subscribe?qid=' + qid}>Subscribe</Link> to a paid account to access the complete results.
                            </Typography>
                        }
                    </div>
                    {mainContent}
                    {exportResultsDialogContent}
                </>
            )
            }
        </Container >
    );

    return content;
}

export default Results;
