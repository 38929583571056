import React, { useEffect } from 'react';
import { Link as RouterLink } from "react-router-dom";

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PageHeader from './PageHeader';
import SubHeader from './SubHeader';

const exampleQueryLists = {
    basic: [
        {
            name: 'New 52-Week Highs',
            qid: 'y0STJ60G',
        },
        {
            name: 'All Nasdaq 100 Stocks',
            qid: 'oCNbjdd1',
        },
        {
            name: 'Most Active by Money Flow',
            qid: '1IngZIL8',
        },
        {
            name: '50-Day Moving Average Bullish Crossovers',
            qid: 'KwR1VvQx',
        },
        {
            name: 'IV Percentile over 90%',
            qid: 'b2b7GM2E',
        },
        {
            name: 'Strong Fundamentals and Revenue/Net Income Growth',
            qid: 'UhfKHheH',
        },
        {
            name: 'Lowest P/E Utility Stocks',
            qid: 'CYW5Otel',
        },
    ],
    sql: [
        {
            name: 'Closing Price between 20% and 40% below 200-Day Moving Average',
            qid: 'EX82Y0Zt',
        },
        {
            name: 'Closing Price Outside Bollinger Bands (Upper or Lower)',
            qid: 'M0xoVmw7',
        },
        {
            name: 'Average P/E, P/B, Return on Equity, and Debt to Equity per Sector',
            qid: 'vQ2c12e5',
        },
    ],
}


const Examples = (props) => {
    useEffect(() => {
        document.title = 'Example Queries - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <PageHeader title="Example Queries" />
            <Typography variant="body1" mb={2}>
                The following example queries demonstrate the range of insights that can be obtained from our data,
                and can be used as a starting point to create new queries.
            </Typography>

            <SubHeader title="Basic Queries" />
            {exampleQueryLists.basic.map((query) => (
                <Typography variant="body1" mb={2}>
                    <Link component={RouterLink} to={'/query/' + query.qid}>{query.name}</Link>
                </Typography>
            ))}

            <SubHeader title="SQL Queries" />
            {exampleQueryLists.sql.map((query) => (
                <Typography variant="body1" mb={2}>
                    <Link component={RouterLink} to={'/query/' + query.qid}>{query.name}</Link>
                </Typography>
            ))}

        </Container >
    );
}

export default Examples;