import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useOutletContext } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

const Account = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [error, setError] = useState(() => null);

    const [email, setEmail] = useState(() => null);
    const [productDescriptor, setproductDescriptor] = useState(() => null);
    const [cardNumber, setCardNumber] = useState(() => null);
    const [expDate, setExpDate] = useState(() => null);
    const [dateNextPayment, setDateNextPayment] = useState(() => null);
    const [cancel, setCancel] = useState(() => null);
    const [hasBilling, setHasBilling] = useState(() => null);

    const navigate = useNavigate();
    const location = useLocation();

    const [copiedToolTipOpen, setCopiedToolTipOpen] = useState(() => false);

    const [successMessage, setSuccessMessage] = useState(() => location?.state?.successMessage || null);

    const [apiKey] = useOutletContext();

    useEffect(() => {
        if (!user.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        document.title = 'Account - EODmetrics';

        fetchService('GET', '/service/account', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setLoading(false);
                    setError('Error retrieving account information: ' + data['error'])
                } else {
                    setEmail(data['email']);
                    setproductDescriptor(data['productDescriptor']);
                    setCardNumber(data['cardNumber']);
                    setExpDate(data['expDate']);
                    setDateNextPayment(data['dateNextPayment']);
                    setCancel(data['cancel']);
                    setHasBilling(data['hasBilling']);
                    setError(null);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error retrieving account information: ' + err.toString())
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let loadingContent = (
        <Box style={{ display: 'flex', minHeight: '285px' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={80} />
        </Box>
    );

    return (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Account" />
                {loading ? loadingContent : (
                    <>
                        {error && (
                            <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ mb: 1 }}>{error}</Alert>
                        )}
                        {successMessage && (
                            <Alert id="success-container" onClose={() => { setSuccessMessage(null) }} severity="success" sx={{ mb: 1 }}>{successMessage}</Alert>
                        )}
                        <Stack direction="row" spacing={3} py={2} style={{ borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6' }}>
                            <Typography variant="body1" style={{ width: '140px' }}>
                                Email:
                            </Typography>
                            <Typography variant="body1" style={{ width: '300px' }}>
                                {email}
                            </Typography>
                            <Typography variant="body1">
                                <Link component={RouterLink} to="/change-email">Change Email</Link>
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={3} py={2} style={{ borderBottom: '1px solid #dee2e6' }}>
                            <Typography variant="body1" style={{ width: '140px' }}>
                                Password:
                            </Typography>
                            <Typography variant="body1" style={{ width: '300px' }}>
                                ********
                            </Typography>
                            <Typography variant="body1">
                                <Link component={RouterLink} to="/change-password">Change Password</Link>
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing={3} py={2} style={{ borderBottom: '1px solid #dee2e6' }}>
                            <Typography variant="body1" style={{ width: '140px' }}>
                                Subscription Plan:
                            </Typography>
                            <Typography variant="body1" style={{ width: '300px' }}>
                                {user.status >= 2 && productDescriptor !== null && (
                                    <span>{productDescriptor}</span>
                                )}
                                {user.status === 1 && (
                                    <span>Free Account</span>
                                )}
                            </Typography>
                            <Typography variant="body1">
                                {user.status === 1 && (
                                    <Link component={RouterLink} to="/subscribe">Subscribe to Paid</Link>
                                )}
                            </Typography>
                        </Stack>

                        {user.status >= 2 && dateNextPayment !== null && cancel === false && cardNumber !== null && expDate !== null && (
                            <Stack direction="row" spacing={3} py={2} style={{ borderBottom: '1px solid #dee2e6' }}>
                                <Typography variant="body1" style={{ width: '140px' }}>
                                    Payment Method:
                                </Typography>
                                <Typography variant="body1" style={{ width: '300px' }}>
                                    {cardNumber + ' ' + expDate}
                                </Typography>
                                <Typography variant="body1">
                                    <Link href="/update-payment-method">Update Payment Method</Link>
                                </Typography>
                            </Stack>
                        )}
                        {user.status >= 2 && dateNextPayment !== null && cancel === false && (
                            <Stack direction="row" spacing={3} py={2} style={{ borderBottom: '1px solid #dee2e6' }}>
                                <Typography variant="body1" style={{ width: '140px' }}>
                                    Next Billing Date:
                                </Typography>
                                <Typography variant="body1" style={{ width: '300px' }}>
                                    {dateNextPayment}
                                </Typography>
                                <Typography variant="body1">
                                    <Link component={RouterLink} to="/cancel">Cancel Subscription</Link>
                                </Typography>
                            </Stack>
                        )}
                        {user.status >= 2 && apiKey !== null && (
                            <Stack direction="row" spacing={3} py={2} style={{ borderBottom: '1px solid #dee2e6' }}>
                                <Typography variant="body1" style={{ width: '140px' }}>
                                    API Key:
                                </Typography>
                                <Typography variant="body1" style={{ width: '300px' }}>
                                    {apiKey}
                                </Typography>
                                <Typography component="span" variant="body1">
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={() => setCopiedToolTipOpen(false)}
                                        open={copiedToolTipOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title="Copied"
                                    >
                                        <Link component="button" variant="body1" onClick={(event) => {
                                            navigator.clipboard.writeText(apiKey);
                                            setCopiedToolTipOpen(true);
                                            setTimeout(() => {
                                                setCopiedToolTipOpen(false);
                                            }, 1000);
                                        }}>
                                            Copy
                                        </Link>
                                    </Tooltip>
                                </Typography>
                            </Stack>
                        )}
                        {user.status === 1 && hasBilling === false && (
                            <Typography variant="body1" mt={2}>
                                You currently have a free account with limited query functionality. <Link component={RouterLink} to="/subscribe">Subscribe</Link> to a paid account for unlimited query functionality, data exports, and API access.
                            </Typography>
                        )}
                        {user.status === 1 && hasBilling === true && (
                            <Typography variant="body1" mt={2}>
                                Your paid subscription is no longer active, and you have been reverted to a free account. <Link component={RouterLink} to="/subscribe">Subscribe</Link> again to restore full functionality.
                            </Typography>
                        )}
                        {user.status >= 2 && cancel === true && dateNextPayment !== null && (
                            <Typography variant="body1" mt={2}>
                                Your paid subscription has been canceled and will be deactivated on {dateNextPayment}. <Link component={RouterLink} to="/reinstate">Reinstate</Link> to keep full functionality.
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        </Container>
    );
}

export default Account;