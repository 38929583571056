import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import PageHeader from './PageHeader';

const About = (props) => {
    useEffect(() => {
        document.title = 'About Us - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <PageHeader title="About Us" />
            <Typography variant="body1" mb={2}>
                Market Computing is a financial technology company with a mission to democratize access to valuable market insights.
                Our goal is to develop high quality software and information services for traders, investors, and financial firms, and offer these products with transparent, competitive pricing.
            </Typography>
            <Typography variant="body1" mb={2}>
                EODmetrics is our new product, built to be the best source of end-of-day quantitative metrics for U.S. exchange-listed securities.
                With hundreds of data fields covering a multitude of different dimensions, EODmetrics is a uniquely powerful tool for stock screening, analysis, and research.
            </Typography>
        </Container>
    );
}

export default About;