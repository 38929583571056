import { React, useState, useEffect } from 'react';
import { useLocation, Outlet } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import Footer from './Footer';

import { matchSorter } from 'match-sorter';

import { getUserLocalStorage, fetchService, getCookie } from './Util';

import './Global.css';

const filterOptionsTickers = (options, { inputValue }) => {
    let matches = matchSorter(options, inputValue, {
        keys: [
            { threshold: matchSorter.rankings.STARTS_WITH, key: 'ticker' },
            { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
        ],
    });

    let filterOptions = [];
    if (inputValue !== '') {
        filterOptions = matches.slice(0, 10);
    }
    return filterOptions;
}

const renderTickerOption = (props, option) => (
    <li {...props} key={option.ticker}>
        <span className="suggestion-ticker">{option.ticker}</span>{option.name}
    </li>
)

function App() {
    const [apiKey, setApiKey] = useState(() => null);
    const [securityList, setSecurityList] = useState(() => []);

    const location = useLocation();

    useEffect(() => {
        window.r = getCookie('c');

        fetch('/s.json')
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response
            })
            .then((response) => response.json())
            .then((data) => {
                setSecurityList(data);
            })
            .catch((err) => { });
    }, []);

    useEffect(() => {
        let userLocalStorage = getUserLocalStorage();
        if (userLocalStorage.status <= 1 && apiKey !== null) {
            setApiKey(null);
        } else if (userLocalStorage.status >= 2 && apiKey === null) {
            fetchService('GET', '/service/api-key', {})
                .then((response) => response.json())
                .then((data) => {
                    if (data['error']) { } else {
                        setApiKey(data['apiKey']);
                    }
                })
                .catch((err) => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div>
            <CssBaseline />
            <Header securityList={securityList} filterOptionsTickers={filterOptionsTickers} renderTickerOption={renderTickerOption} />
            <Outlet context={[apiKey, securityList, filterOptionsTickers, renderTickerOption]} />
            <Footer />
        </div>
    );
}

export default App;