import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

window.authNetResponseHandlerWrapperUpdatePaymentMethod = null;

const theme = createTheme();

const UpdatePaymentMethod = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);
    const [errorDetails, setErrorDetails] = useState(() => null);

    const [cardNumber, setCardNumber] = useState(() => null);
    const [expDate, setExpDate] = useState(() => null);

    const [authNetApiLoginId, setAuthNetApiLoginId] = useState(() => '');
    const [authNetPublicClientKey, setAuthNetPublicClientKey] = useState(() => '');
    const [authNetAcceptJsSrc, setAuthNetAcceptJsSrc] = useState(() => '');

    const navigate = useNavigate();

    const authNetResponseHandler = (response) => {
        if (
            response['messages'] &&
            response['messages']['resultCode'] &&
            response['messages']['resultCode'] === 'Error' &&
            response['messages']['message'] &&
            response['messages']['message'].length > 0 &&
            response['messages']['message'][0]['text']
        ) {
            setError(response['messages']['message'][0]['text']);
            return;
        }

        if (submitting) {
            return;
        }

        setSubmitting(true);
        fetchService('POST', '/service/update-payment-method', {
            nonceData: JSON.stringify(response),
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    setError(data['error']);
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                        setErrorDetails(data['errorDetails']);
                    } else {
                        setErrorDetails(null);
                    }
                } else {
                    setError(null);
                    setErrorDetails(null);
                    navigate('/account', { state: { successMessage: 'You have successfully updated your payment method.' } });
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
                setErrorDetails(null);
            });
    }

    useEffect(() => {
        if (!user.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (user.status === 1) {
            navigate('/account');
            return;
        }

        if (window.authNetLoaded) {
            window.location.reload();
        }

        document.title = 'Update Payment Method - EODmetrics';

        fetchService('GET', '/service/account', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setLoading(false);
                    setError('Error retrieving account information: ' + data['error'])
                } else {
                    setCardNumber(data['cardNumber']);
                    setExpDate(data['expDate']);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error retrieving account information: ' + err.toString())
            });

        if (window.authNetResponseHandlerWrapperUpdatePaymentMethod === null) {
            window.authNetResponseHandlerWrapperUpdatePaymentMethod = authNetResponseHandler
        }

        fetchService('GET', '/service/authnet-info', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    setError('Error retrieving payment gateway information: ' + data['error']);
                } else {
                    setAuthNetApiLoginId(data['apiLoginId']);
                    setAuthNetPublicClientKey(data['publicClientKey']);
                    setAuthNetAcceptJsSrc(data['acceptJsSrc']);
                }
            })
            .catch((err) => {
                setError('Error retrieving payment gateway information: ' + err.toString());
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (document.getElementById('script-accept-ui')) {
            return;
        }
        if (!authNetApiLoginId || !authNetPublicClientKey || !authNetAcceptJsSrc) {
            return;
        }

        let scriptElem = document.createElement('script');
        scriptElem.setAttribute('src', authNetAcceptJsSrc);
        scriptElem.setAttribute('charset', 'utf-8');
        scriptElem.setAttribute('id', 'script-accept-ui');
        document.getElementById('btn-container').appendChild(scriptElem);
        window.authNetLoaded = true;
    }, [authNetApiLoginId, authNetPublicClientKey, authNetAcceptJsSrc]);

    let loadingContent = (
        <Box style={{ display: 'flex' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={40} />
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PageHeader title="Update Payment Method" />
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {error && !errorDetails &&
                        <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                    }
                    <Box sx={{ mt: 3 }}>
                        {loading ? loadingContent : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={3} py={1}>
                                        <Typography variant="body1">
                                            Current Payment Method:
                                        </Typography>
                                        <Typography variant="body1">
                                            {cardNumber + ' ' + expDate}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )}
                        <Box id="btn-container" style={{ minHeight: '40px' }} sx={{ mt: 3, mb: 2 }}
                            justifyContent="center"
                        >
                            <Box style={{ minHeight: '82px' }} sx={{ display: submitting ? 'flex' : 'none', mt: 3, mb: 2 }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress size={40} />
                            </Box>
                            <Box style={{ minHeight: '82px' }} sx={{ display: submitting ? 'none' : 'block', mt: 3, mb: 2 }}
                                justifyContent="center"
                            >
                                <Button
                                    type="button"
                                    variant="contained"
                                    fullWidth
                                    className="AcceptUI"
                                    data-billingaddressoptions='{"show": true, "required": true}'
                                    data-apiloginid={authNetApiLoginId}
                                    data-clientkey={authNetPublicClientKey}
                                    data-acceptuiformbtntxt="Submit"
                                    data-acceptuiformheadertxt="New Payment Information"
                                    data-paymentoptions='{"showCreditCard": true, "showBankAccount": false}'
                                    data-responsehandler="authNetResponseHandlerWrapperUpdatePaymentMethod"
                                >
                                    Update Payment Method
                                </Button>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                    onClick={() => navigate('/account')}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default UpdatePaymentMethod;