import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import EmailIcon from '@mui/icons-material/Email';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageHeader from './PageHeader';

const Contact = (props) => {
    useEffect(() => {
        document.title = 'Contact Us - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Contact Us" />
                <Typography variant="body1" mb={2}>
                    Please feel free to contact us with any questions or comments you have, or regarding any issues you encounter with the site.
                </Typography>
                <Stack direction="row" spacing={2}>
                    <EmailIcon />
                    <Typography variant="body1" mb={2}>
                        connect@eodmetrics.com
                    </Typography>
                </Stack>
            </Box>
        </Container>
    );
}

export default Contact;