import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

const Cancel = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);

    const [dateNextPayment, setDateNextPayment] = useState(() => null);

    const [reason, setReason] = useState(() => '');
    const [otherText, setOtherText] = useState(() => '');
    const [comments, setComments] = useState(() => '');

    const navigate = useNavigate();

    useEffect(() => {
        if (!user.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (user.status === 1) {
            navigate('/account');
            return;
        }

        document.title = 'Cancel Subscription - EODmetrics';

        fetchService('GET', '/service/account', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setLoading(false);
                    setError('Error retrieving account information: ' + data['error'])
                } else {
                    setDateNextPayment(data['dateNextPayment']);
                    setError(null);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error retrieving account information: ' + err.toString())
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let loadingContent = (
        <Box style={{ display: 'flex', minHeight: '285px' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={80} />
        </Box>
    );

    const reasons = [
        'No longer using / not using enough',
        'Too expensive (would continue at a lower monthly price)',
        'Not enough value in the product / missing functionality',
        'Free account is sufficient for my purposes',
        'Other',
    ];

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    }

    const handleOtherTextChange = (event) => {
        setOtherText(event.target.value);
    }

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/cancel', {
            reason: reason,
            otherText: otherText,
            comments: comments,
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError(data['error']);
                } else {
                    setError(null);
                    navigate('/account', { state: { successMessage: 'Your subscription has been canceled.' } });
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
            });
    }

    return (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Cancel Subscription" />
                {loading ? loadingContent : (
                    <>
                        <Typography variant="body1" mb={2}>
                            Click <strong>Confirm Cancellation</strong> below to cancel your paid subscription.
                        </Typography>
                        <Typography variant="body1" mb={2}>
                            By canceling, you understand that when your paid subscription deactivates, you will lose access to the functionality of a paid account, and you will be reverted to a free account. Your cancellation will take effect at the end of your current billing period on <strong>{dateNextPayment}</strong>.
                        </Typography>
                        <Typography variant="body1" mb={1}>
                            To help us improve, please tell us the primary reason why you are canceling:
                        </Typography>
                        <FormControl>
                            <RadioGroup onChange={handleReasonChange}>
                                {reasons.map((cancelReason, index) => (
                                    <FormControlLabel key={index} value={cancelReason} control={<Radio />} label={cancelReason} />
                                ))}
                            </RadioGroup>
                            {reason === 'Other' &&
                                <TextField size="small" value={otherText} onChange={handleOtherTextChange} />
                            }
                        </FormControl>
                        <Typography variant="body1" mb={2} mt={3}>
                            Additional comments:
                        </Typography>
                        <TextareaAutosize
                            value={comments}
                            minRows={3}
                            style={{ width: 444 }}
                            maxLength="10000"
                            onChange={handleCommentsChange}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {error &&
                                <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                            }
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                {submitting ? (
                                    <Box style={{ minHeight: '82px' }} sx={{ display: 'flex', mt: 3, mb: 2 }}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress size={40} />
                                    </Box>
                                ) : (
                                    <Box style={{ minHeight: '82px' }} sx={{ mt: 3, mb: 2 }}
                                        justifyContent="center"
                                    >
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                        >
                                            Confirm Cancellation
                                        </Button>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mt: 1 }}
                                            onClick={() => navigate('/account')}
                                        >
                                            Go Back
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
}

export default Cancel;