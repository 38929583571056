import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useOutletContext } from "react-router-dom";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { getUserLocalStorage } from './Util';
import PageHeader from './PageHeader';
import SampleCode from './SampleCode';
import SubHeader from './SubHeader';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';

import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('bash', bash);

const CodeSampleBox = styled(Box)({
    maxHeight: '600px',
    overflowY: 'auto !important'
});

const exampleQueryList = [`
{
    "fields": [
      "ticker",
      "name",
      "close_price",
      "volume",
      "percent_change_price_1_day"
    ],
    "conditions": [
      {
        "field": "close_price",
        "operator": "gt",
        "value": "10.00"
      },
      {
        "field": "volume",
        "operator": "gt",
        "value": "500000"
      },
      {
        "field": "sma_50",
        "operator": "lt",
        "value": "sma_200"
      }
    ],
    "order": [
      {
        "field": "market_cap",
        "direction": "desc"
      }
    ],
    "limit": 20
}
`,
    `
{
    "fields": [
      "ticker",
      "name",
      "close_price",
      "volume",
      "percent_change_price_1_day"
    ],
    "tickers": ["AXP", "AMGN", "AAPL", "BA", "CAT", "CSCO", "CVX", "GS", "HD", "HON",
                "IBM", "INTC", "JNJ", "KO", "JPM", "MCD", "MMM", "MRK", "MSFT", "NKE",
                "PG", "TRV", "UNH", "CRM", "VZ", "V", "WBA", "WMT", "DIS", "DOW"]
}
`];

const exampleApiCallList = [`
curl -G https://api.eodmetrics.com/query \\
  -d 'apiKey=YOUR_API_KEY' \\
  -d 'format=csv' \\
  -d 'data={"fields":["ticker","name","close_price","volume"],"tickers":["META","AMZN","AAPL","GOOGL","MSFT"]}'

Ticker,Security Name,Closing Price,Volume
AAPL,Apple Inc.,153.04,64605671
AMZN,"Amazon.com, Inc.",122.77,71002065
GOOGL,Alphabet Inc.,113.90,35223536
META,"Meta Platforms, Inc.",183.09,24519258
MSFT,Microsoft Corporation,262.27,22301704
`,
    `
curl -G https://api.eodmetrics.com/query \\
  -d 'apiKey=YOUR_API_KEY' \\
  -d 'format=json' \\
  -d 'pretty=1' \\
  -d 'data=SELECT ticker AS \`Ticker\`, name AS \`Security Name\`, close_price AS \`Closing Price\`, volume AS \`Volume\` FROM data WHERE ticker IN ("META", "AMZN", "AAPL", "GOOGL", "MSFT")'

{
    "columnNames": [
        "Ticker",
        "Security Name",
        "Closing Price",
        "Volume"
    ],
    "records": [
        [
            "AAPL",
            "Apple Inc.",
            153.04,
            64605671
        ],
        [
            "AMZN",
            "Amazon.com, Inc.",
            122.77,
            71002065
        ],
        [
            "GOOGL",
            "Alphabet Inc.",
            113.90,
            35223536
        ],
        [
            "META",
            "Meta Platforms, Inc.",
            183.09,
            24519258
        ],
        [
            "MSFT",
            "Microsoft Corporation",
            262.27,
            22301704
        ]
    ]
}
`,
    `
curl -G https://api.eodmetrics.com/query \\
  -d 'apiKey=YOUR_API_KEY' \\
  -d 'format=xml' \\
  -d 'pretty=1' \\
  -d 'sqid=g0IZCzJ6'

<?xml version="1.0" encoding="UTF-8"?>
<results>
    <columnNames>
        <columnName>Ticker</columnName>
        <columnName>Security Name</columnName>
        <columnName>Closing Price</columnName>
        <columnName>Volume</columnName>
    </columnNames>
    <records>
        <record>
            <value>AAPL</value>
            <value>Apple Inc.</value>
            <value>153.04</value>
            <value>64605671</value>
        </record>
        <record>
            <value>AMZN</value>
            <value>Amazon.com, Inc.</value>
            <value>122.77</value>
            <value>71002065</value>
        </record>
        <record>
            <value>GOOGL</value>
            <value>Alphabet Inc.</value>
            <value>113.90</value>
            <value>35223536</value>
        </record>
        <record>
            <value>META</value>
            <value>Meta Platforms, Inc.</value>
            <value>183.09</value>
            <value>24519258</value>
        </record>
        <record>
            <value>MSFT</value>
            <value>Microsoft Corporation</value>
            <value>262.27</value>
            <value>22301704</value>
        </record>
    </records>
</results>
`];

const sampleCodeList = [
    {
        langKey: 'python',
        langTitle: 'Python',
        code: SampleCode['py'],
    },
    {
        langKey: 'javascript',
        langTitle: 'JS',
        code: SampleCode['js']
    },
    {
        langKey: 'go',
        langTitle: 'Go',
        code: SampleCode['go']
    },
    {
        langKey: 'java',
        langTitle: 'Java',
        code: SampleCode['java']
    },
]

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`sample-code-tabpanel-${index}`}
            aria-labelledby={`sample-code-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `sample-code-tab-${index}`,
        'aria-controls': `sample-code-tabpanel-${index}`,
    };
}

const Docs = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [sampleCodeLangIndex, setSampleCodeLangIndex] = useState(() => 0);

    const handleSampleCodeLangIndexChange = (event, index) => {
        setSampleCodeLangIndex(index);
    }

    const [apiKey] = useOutletContext();

    useEffect(() => {
        document.title = 'API Documentation - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    for (let i = 0; i < exampleApiCallList.length; i++) {
        exampleApiCallList[i] = exampleApiCallList[i].replace('YOUR_API_KEY', (user.status >= 2 && apiKey !== null ? apiKey : 'YOUR_API_KEY'))
    }

    for (let i = 0; i < sampleCodeList.length; i++) {
        sampleCodeList[i].code = sampleCodeList[i].code.replace('YOUR_API_KEY', (user.status >= 2 && apiKey !== null ? apiKey : 'YOUR_API_KEY'))
    }

    return (
        <Container>
            <PageHeader title="API Documentation" />
            <Typography variant="body1" mb={2}>
                The EODmetrics API can be used to fetch data programmatically, either for use directly in application code or to download data files.
                Any query that can be constructed in the web user interface can also be submitted to the API, and data can be obtained in CSV, Excel, JSON, or XML format.
            </Typography>
            <Typography variant="body1" mb={2}>
                API access is restricted to paid subscribers only.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Endpoint" />
            <Typography variant="body1" mb={2}>
                <strong>GET</strong> https://api.eodmetrics.com/query
            </Typography>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Parameters" />
            <Typography variant="body1" mb={0}>
                <strong>apiKey</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={2}>
                User's API key. Paid subscribers can find this on their Account page.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>format</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={2}>
                Requested data format. Must be one of 'csv', 'excel', 'json', or 'xml'.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>data</strong> (string, required unless <strong>sqid</strong> parameter is sent)
            </Typography>
            <Typography variant="body1" mb={2}>
                Query data, as either a query object JSON string or an SQL query. See below for more information.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>sqid</strong> (string, required if <strong>data</strong> parameter is not sent)
            </Typography>
            <Typography variant="body1" mb={2}>
                Saved query ID. Paid subscribers can find this ID value on their Saved Queries page.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>zip</strong> (integer, optional)
            </Typography>
            <Typography variant="body1" mb={2}>
                Return data as a zip file. Must be one of 1 or 0, if present (defaults to 0).
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>pretty</strong> (integer, optional)
            </Typography>
            <Typography variant="body1" mb={2}>
                Return data formatted with newlines and indentation. Applies only when format=json or format=xml. Must be one of 1 or 0, if present (defaults to 0).
            </Typography>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Response Codes" />
            <Typography variant="body1" mb={0}>
                <strong>200</strong> (OK)
            </Typography>
            <Typography variant="body1" mb={2}>
                Request was successful.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>400</strong> (Bad Request)
            </Typography>
            <Typography variant="body1" mb={2}>
                Missing or invalid parameter value, such as a malformed JSON string or an error in an SQL query.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>401</strong> (Unauthorized)
            </Typography>
            <Typography variant="body1" mb={2}>
                Invalid API key, or paid subscription is not active.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>500</strong> (Internal Server Error)
            </Typography>
            <Typography variant="body1" mb={2}>
                Unexpected server error.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Usage" />
            <Typography variant="body1" mb={2}>
                There are three modes for submitting a query to the API:
            </Typography>
            <ol>
                <li>
                    <Typography variant="body1" mb={0}>
                        <strong>Query object JSON</strong>
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        This mode corresponds to <Link component={RouterLink} to="/query?type=1">Basic Mode</Link> in the Query UI. A query object JSON string is sent in the <strong>data</strong> parameter.
                        The schema of a query object is specified below.
                        Data fields referenced must use the <Link component={RouterLink} to="/fields">data field column names</Link>.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" mb={0}>
                        <strong>SQL query</strong>
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        This mode corresponds to <Link component={RouterLink} to="/query?type=2">SQL Mode</Link> in the Query UI. An SQL query string is sent in the <strong>data</strong> parameter.
                        The query must be a SELECT query on the 'data' table, and reference the <Link component={RouterLink} to="/fields">data field column names</Link>.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" mb={0}>
                        <strong>Saved query ID</strong>
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        The ID for a saved query can be looked up in the ID column on the Saved Queries page. This value is sent in the <strong>sqid</strong> parameter.
                    </Typography>
                </li>
            </ol>
            <Typography variant="body1" mb={2}>
                Note that instead of manually constructing an API URL, it is always possible to enter a query in the UI (either in Basic Mode or SQL Mode) and then click the <strong>API&nbsp;URL</strong> button to obtain the URL for the specified query.
                Passing a saved query ID in the <strong>sqid</strong> parameter is perhaps the cleanest method of all, avoiding a long and complex URL.
            </Typography>
            <Typography variant="body1" mb={2}>
                Depending on your client/environment, it will likely be necessary to URL-encode the query JSON string or SQL string before sending your request to the API.
                The URL rendered when clicking the <strong>API&nbsp;URL</strong> button is already URL-encoded.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Query Object Schema (JSON mode)" />
            <Typography variant="body1" mb={2}>
                When submitting a query to the API as JSON, the structure of a query object is as follows.
            </Typography>
            <Typography variant="body1" mb={2}>
                <span style={{ textDecoration: 'underline' }}><strong>Query:</strong></span>
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>fields</strong> ([]string, optional)
            </Typography>
            <Typography variant="body1" mb={2}>
                Array of data fields, referenced by API/SQL column name. If no fields are provided, by either omitting this field or submitting an empty array, then all fields will be returned.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>conditions</strong> ([]Condition, optional)
            </Typography>
            <Typography variant="body1" mb={2}>
                Array of Condition objects (see below) to filter the results by. If no conditions are provided, by either omitting this field or submitting an empty array, then all securities will be returned.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>tickers</strong> ([]string, optional)
            </Typography>
            <Typography variant="body1" mb={2}>
                Array of tickers to which to restrict the results. If no tickers are provided, by either omitting this field or submitting an empty array, then all securities will be returned.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>order</strong> ([]Ordering), optional
            </Typography>
            <Typography variant="body1" mb={2}>
                Array of Ordering objects (see below) specifying which field(s) to order the results by. If no order is provided, by either omitting this field or submitting an empty array, then the results may be returned in any order.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>limit</strong> (integer, optional)
            </Typography>
            <Typography variant="body1" mb={2}>
                Number of records to which to limit the results. If no limit is desired, then this field should be omitted or set to <code>null</code>.
            </Typography>
            <Typography variant="body1" mb={2}>
                <span style={{ textDecoration: 'underline' }}><strong>Condition:</strong></span>
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>field</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={2}>
                A data field, referenced by API/SQL column name.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>operator</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={1}>
                A string representing a relational operator. Must be on one of:
            </Typography>
            <ul>
                <li>'gt' or {"'>'"} (greater than)</li>
                <li>'ge' or {"'>='"} (greater than or equal to)</li>
                <li>'lt' or {"'<'"} (less than)</li>
                <li>'le' or {"'<='"} (less than or equal to)</li>
                <li>'eq' or {"'='"} (equal to)</li>
                <li>'ne' or {"'!='"} (not equal to)</li>
            </ul>
            <Typography variant="body1" mb={0}>
                <strong>value</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={2}>
                A string representing a value, which can either be a number (integer or decimal) or another data field, referenced by API/SQL column name.
                A number value can be suffixed with 't', 'm', 'b', or 'r' for thousand, million, billion, or trillion, for example '500t' or '2.5b'.
                For the exchange, sector, and industry fields, an appropriate string value should be provided.
            </Typography>
            <Typography variant="body1" mb={2}>
                <span style={{ textDecoration: 'underline' }}><strong>Ordering:</strong></span>
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>field</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={2}>
                A data field, referenced by API/SQL column name.
            </Typography>
            <Typography variant="body1" mb={0}>
                <strong>direction</strong> (string, required)
            </Typography>
            <Typography variant="body1" mb={2}>
                The direction of the ordering, must be one of 'asc' (ascending) or 'desc' (descending).
            </Typography>
            <Typography variant="body1" mb={2}>
                Note that all properties of a Query object are optional. To download all fields for all securities, you can simply pass <code>{'{}'}</code> for the query object (data={'{ }'}).
            </Typography>
            <Typography variant="body1" mb={2}>
                <strong>Example Query Objects</strong>
            </Typography>
            <Typography variant="body1" mb={1}>
                The following query returns the Ticker, Security Name, Closing Price, Volume, and Percent Change in Price, 1 Day for the 20 largest companies by Market Capitalization which satisfy:
            </Typography>
            <ul>
                <li>
                    Closing Price is greater than 10.00
                </li>
                <li>
                    Volume is greater than 500,000
                </li>
                <li>
                    Simple Moving Average (50-Day) is less than Simple Moving Average (200-Day)
                </li>
            </ul>
            <SyntaxHighlighter language="json" style={docco}>
                {exampleQueryList[0]}
            </SyntaxHighlighter>
            <Typography variant="body1" mt={4} mb={2}>
                This query returns the Ticker, Security Name, Closing Price, Volume, and Percent Change in Price, 1 Day for the Dow 30 stocks:
            </Typography>
            <SyntaxHighlighter language="json" style={docco}>
                {exampleQueryList[1]}
            </SyntaxHighlighter>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Example API Calls" />
            <Typography variant="body1" mb={2}>
                The following are example requests/responses to the API using the <code>curl</code> command line application.
            </Typography>
            <Typography variant="body1" mb={2}>
                JSON query mode, CSV response format:
            </Typography>
            <SyntaxHighlighter language="bash" style={docco}>
                {exampleApiCallList[0]}
            </SyntaxHighlighter>
            <Typography variant="body1" mt={4} mb={2}>
                SQL query mode, JSON response format:
            </Typography>
            <SyntaxHighlighter language="bash" style={docco}>
                {exampleApiCallList[1]}
            </SyntaxHighlighter>
            <Typography variant="body1" mt={4} mb={2}>
                Saved query ID, XML response format:
            </Typography>
            <SyntaxHighlighter language="bash" style={docco}>
                {exampleApiCallList[2]}
            </SyntaxHighlighter>
            <Divider sx={{ my: 2 }} />
            <SubHeader title="Sample Code" />
            <Typography variant="body1" mb={2}>
                Below we present sample code for making an API request in various programming languages.
            </Typography>
            <Box sx={{ width: '100%' }}>
                <Box>
                    <Tabs value={sampleCodeLangIndex} onChange={handleSampleCodeLangIndexChange}>
                        {sampleCodeList.map((sampleCode, index) => (
                            <Tab label={sampleCode.langTitle} key={index} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </Box>
                {sampleCodeList.map((sampleCode, index) => (
                    <TabPanel value={sampleCodeLangIndex} index={index} key={index}>
                        <CodeSampleBox>
                            <SyntaxHighlighter language={sampleCode.langKey} style={docco}>
                                {sampleCode.code}
                            </SyntaxHighlighter>
                        </CodeSampleBox>
                    </TabPanel>
                ))}
            </Box>
        </Container >
    );
}

export default Docs;