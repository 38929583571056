import React, { useEffect } from 'react';
import { Link as RouterLink } from "react-router-dom";

// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import Box from '@mui/material/Box';
// import ComputerIcon from '@mui/icons-material/Computer';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
// import ShowChartIcon from '@mui/icons-material/ShowChart';
// import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

// import { getUserLocalStorage } from './Util';

const Home = (props) => {
    // const [user] = useState(() => getUserLocalStorage());

    useEffect(() => {
        document.title = 'EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container component="main">
            <Typography variant="h2" align="center" component="p" sx={{ mt: 3 }}>
                Powerful stock insights, accessed instantly.
            </Typography>
            {/* <Box sx={{ mt: 3 }}>
                <Stack direction={{ sm: 'column', md: 'row' }} alignItems="center" justifyContent="center" spacing={12}>
                    <ComputerIcon sx={{ fontSize: 240 }} />
                    <ShowChartIcon sx={{ fontSize: 240 }} />
                    <AttachMoneyIcon sx={{ fontSize: 240 }} />
                </Stack>
            </Box> */}
            <Typography variant="h2" align="center" component="p" sx={{ mt: 1 }}>
                A wealth of valuable data at your fingertips.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Container maxWidth="lg">
                <Typography variant="h5" component="p" sx={{ mt: 3, mb: 3 }}>
                    <Link component={RouterLink} to="/query" style={{ textDecoration: 'none' }}>Query</Link> over 750 <Link component={RouterLink} to="/fields" style={{ textDecoration: 'none' }}>data fields</Link> for exactly the stocks and information you want to see:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="h6" mb={1}>
                            Comprehensive financial metrics and fundamental ratios
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h6" mb={1}>
                            Historical volatility, implied volatility, put-call ratios, and other option statistics
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h6" mb={1}>
                            Price data, highs/lows, percent changes, moving averages, and technical indicators
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h6" mb={1}>
                            52-Week High, Low, Rank, and Percentile for all volatility/option metrics and time frames
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h6" mb={1}>
                            Coverage of all U.S. securities trading on NYSE, NASDAQ, NYSE American (NYSE MKT/AMEX) and ARCA
                        </Typography>
                    </li>
                    {/* <li>
                        <Typography variant="h6" mb={1}>
                            Download data in CSV, Excel, JSON, or XML format, via web user interface or API
                        </Typography>
                    </li> */}
                </ul>
                <Typography variant="h5" component="p" sx={{ mt: 3, mb: 3 }}>
                    Download data in CSV, Excel, JSON, or XML format, via web user interface or API
                </Typography>
            </Container>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h2" align="center" component="p" sx={{ mt: 1 }}>
                <Link component={RouterLink} to="/signup" style={{ textDecoration: 'none' }}>Get started with a completely free account.</Link>
            </Typography>
        </Container >
    )
}

export default Home;