import { React } from 'react';
import { Link as RouterLink } from "react-router-dom";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Copyright = () => {
    return (
        <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
            {'Copyright © '} {new Date().getFullYear()} Market Computing, LLC.
        </Typography>
    );
}

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 15,
            }}
            alignItems="center"
            justifyContent="center"
        >
            <Stack
                direction="column"
                spacing={1}
                divider={<Divider orientation="horizontal" flexItem />}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={{ xs: 2, sm: 5 }}
                >
                    <Typography variant="body2">
                        <Link component={RouterLink} to="/about">ABOUT</Link>
                    </Typography>
                    <Typography variant="body2">
                        <Link component={RouterLink} to="/contact">CONTACT</Link>
                    </Typography>
                    <Typography variant="body2">
                        <Link component={RouterLink} to="/terms">TERMS</Link>
                    </Typography>
                    <Typography variant="body2">
                        <Link component={RouterLink} to="/privacy">PRIVACY</Link>
                    </Typography>
                </Stack>
                <Box
                    sx={{ display: 'flex' }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Copyright />
                </Box>
            </Stack>
        </Box >
    );
}
