const FieldsList = [
  {
    "id": "ticker",
    "name": "Ticker",
    "shortName": "Ticker",
    "category": "Profile",
    "description": "Trading symbol."
  },
  {
    "id": "name",
    "name": "Security Name",
    "shortName": "Security Name",
    "category": "Profile",
    "description": "Name of the security."
  },
  {
    "id": "exchange",
    "name": "Exchange",
    "shortName": "Exchange",
    "category": "Profile",
    "description": "Exchange the security trades on."
  },
  {
    "id": "sector",
    "name": "Sector",
    "shortName": "Sector",
    "category": "Profile",
    "description": "Economic sector of the company."
  },
  {
    "id": "industry",
    "name": "Industry",
    "shortName": "Industry",
    "category": "Profile",
    "description": "Economic industry of the company."
  },
  {
    "id": "market_cap",
    "name": "Market Capitalization",
    "shortName": "Market Cap",
    "category": "Profile",
    "description": "Measure of the company's value, calculated as the product of common shares outstanding and current market price per share, with a possible share factor adjustment."
  },
  {
    "id": "enterprise_value",
    "name": "Enterprise Value (EV)",
    "shortName": "Enterprise Value",
    "category": "Profile",
    "description": "Measure of the total value of a business, calculated as current market capitalization plus total debt minus cash and cash equivalents."
  },
  {
    "id": "close_price",
    "name": "Closing Price",
    "shortName": "Closing Price",
    "category": "Recent Price/Volume",
    "description": "Closing price on the last trading day."
  },
  {
    "id": "open_price",
    "name": "Opening Price",
    "shortName": "Opening Price",
    "category": "Recent Price/Volume",
    "description": "Opening price on the last trading day."
  },
  {
    "id": "high_price",
    "name": "High Price",
    "shortName": "High Price",
    "category": "Recent Price/Volume",
    "description": "High price on the last trading day."
  },
  {
    "id": "low_price",
    "name": "Low Price",
    "shortName": "Low Price",
    "category": "Recent Price/Volume",
    "description": "Low price on the last trading day."
  },
  {
    "id": "volume",
    "name": "Volume",
    "shortName": "Volume",
    "category": "Recent Price/Volume",
    "description": "Volume on the last trading day."
  },
  {
    "id": "previous_close_price",
    "name": "Previous Closing Price",
    "shortName": "Previous Closing Price",
    "category": "Recent Price/Volume",
    "description": "Closing price on the trading day before the last trading day."
  },
  {
    "id": "previous_open_price",
    "name": "Previous Opening Price",
    "shortName": "Previous Opening Price",
    "category": "Recent Price/Volume",
    "description": "Opening price on the trading day before the last trading day."
  },
  {
    "id": "previous_high_price",
    "name": "Previous High Price",
    "shortName": "Previous High Price",
    "category": "Recent Price/Volume",
    "description": "High price on the trading day before the last trading day."
  },
  {
    "id": "previous_low_price",
    "name": "Previous Low Price",
    "shortName": "Previous Low Price",
    "category": "Recent Price/Volume",
    "description": "Low price on the trading day before the last trading day."
  },
  {
    "id": "previous_volume",
    "name": "Previous Volume",
    "shortName": "Previous Volume",
    "category": "Recent Price/Volume",
    "description": "Volume on the trading day before the last trading day."
  },
  {
    "id": "high_price_52_week",
    "name": "52-Week High Price",
    "shortName": "52-Week High Price",
    "category": "High/Low Price",
    "description": "52-week high price, adjusting for splits and dividends."
  },
  {
    "id": "high_price_26_week",
    "name": "26-Week High Price",
    "shortName": "26-Week High Price",
    "category": "High/Low Price",
    "description": "26-week high price, adjusting for splits and dividends."
  },
  {
    "id": "high_price_13_week",
    "name": "13-Week High Price",
    "shortName": "13-Week High Price",
    "category": "High/Low Price",
    "description": "13-week high price, adjusting for splits and dividends."
  },
  {
    "id": "high_price_4_week",
    "name": "4-Week High Price",
    "shortName": "4-Week High Price",
    "category": "High/Low Price",
    "description": "4-week high price, adjusting for splits and dividends."
  },
  {
    "id": "high_price_2_week",
    "name": "2-Week High Price",
    "shortName": "2-Week High Price",
    "category": "High/Low Price",
    "description": "2-week high price, adjusting for splits and dividends."
  },
  {
    "id": "high_price_1_week",
    "name": "1-Week High Price",
    "shortName": "1-Week High Price",
    "category": "High/Low Price",
    "description": "1-week high price, adjusting for splits and dividends."
  },
  {
    "id": "low_price_52_week",
    "name": "52-Week Low Price",
    "shortName": "52-Week Low Price",
    "category": "High/Low Price",
    "description": "52-week low price, adjusting for splits and dividends."
  },
  {
    "id": "low_price_26_week",
    "name": "26-Week Low Price",
    "shortName": "26-Week Low Price",
    "category": "High/Low Price",
    "description": "26-week low price, adjusting for splits and dividends."
  },
  {
    "id": "low_price_13_week",
    "name": "13-Week Low Price",
    "shortName": "13-Week Low Price",
    "category": "High/Low Price",
    "description": "13-week low price, adjusting for splits and dividends."
  },
  {
    "id": "low_price_4_week",
    "name": "4-Week Low Price",
    "shortName": "4-Week Low Price",
    "category": "High/Low Price",
    "description": "4-week low price, adjusting for splits and dividends."
  },
  {
    "id": "low_price_2_week",
    "name": "2-Week Low Price",
    "shortName": "2-Week Low Price",
    "category": "High/Low Price",
    "description": "2-week low price, adjusting for splits and dividends."
  },
  {
    "id": "low_price_1_week",
    "name": "1-Week Low Price",
    "shortName": "1-Week Low Price",
    "category": "High/Low Price",
    "description": "1-week low price, adjusting for splits and dividends."
  },
  {
    "id": "high_volume_52_week",
    "name": "52-Week High Volume",
    "shortName": "52-Week High Volume",
    "category": "High/Low Volume",
    "description": "52-week high volume, adjusting for splits."
  },
  {
    "id": "high_volume_26_week",
    "name": "26-Week High Volume",
    "shortName": "26-Week High Volume",
    "category": "High/Low Volume",
    "description": "26-week high volume, adjusting for splits."
  },
  {
    "id": "high_volume_13_week",
    "name": "13-Week High Volume",
    "shortName": "13-Week High Volume",
    "category": "High/Low Volume",
    "description": "13-week high volume, adjusting for splits."
  },
  {
    "id": "high_volume_4_week",
    "name": "4-Week High Volume",
    "shortName": "4-Week High Volume",
    "category": "High/Low Volume",
    "description": "4-week high volume, adjusting for splits."
  },
  {
    "id": "high_volume_2_week",
    "name": "2-Week High Volume",
    "shortName": "2-Week High Volume",
    "category": "High/Low Volume",
    "description": "2-week high volume, adjusting for splits."
  },
  {
    "id": "high_volume_1_week",
    "name": "1-Week High Volume",
    "shortName": "1-Week High Volume",
    "category": "High/Low Volume",
    "description": "1-week high volume, adjusting for splits."
  },
  {
    "id": "low_volume_52_week",
    "name": "52-Week Low Volume",
    "shortName": "52-Week Low Volume",
    "category": "High/Low Volume",
    "description": "52-week low volume, adjusting for splits."
  },
  {
    "id": "low_volume_26_week",
    "name": "26-Week Low Volume",
    "shortName": "26-Week Low Volume",
    "category": "High/Low Volume",
    "description": "26-week low volume, adjusting for splits."
  },
  {
    "id": "low_volume_13_week",
    "name": "13-Week Low Volume",
    "shortName": "13-Week Low Volume",
    "category": "High/Low Volume",
    "description": "13-week low volume, adjusting for splits."
  },
  {
    "id": "low_volume_4_week",
    "name": "4-Week Low Volume",
    "shortName": "4-Week Low Volume",
    "category": "High/Low Volume",
    "description": "4-week low volume, adjusting for splits."
  },
  {
    "id": "low_volume_2_week",
    "name": "2-Week Low Volume",
    "shortName": "2-Week Low Volume",
    "category": "High/Low Volume",
    "description": "2-week low volume, adjusting for splits."
  },
  {
    "id": "low_volume_1_week",
    "name": "1-Week Low Volume",
    "shortName": "1-Week Low Volume",
    "category": "High/Low Volume",
    "description": "1-week low volume, adjusting for splits."
  },
  {
    "id": "money_flow_52_week",
    "name": "Total Money Flow, Past 52 Weeks",
    "shortName": "Total Money Flow, 52 Weeks",
    "category": "Money Flow",
    "description": "Total money flow over the past 52 weeks. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "money_flow_26_week",
    "name": "Total Money Flow, Past 26 Weeks",
    "shortName": "Total Money Flow, 26 Weeks",
    "category": "Money Flow",
    "description": "Total money flow over the past 26 weeks. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "money_flow_13_week",
    "name": "Total Money Flow, Past 13 Weeks",
    "shortName": "Total Money Flow, 13 Weeks",
    "category": "Money Flow",
    "description": "Total money flow over the past 13 weeks. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "money_flow_4_week",
    "name": "Total Money Flow, Past 4 Weeks",
    "shortName": "Total Money Flow, 4 Weeks",
    "category": "Money Flow",
    "description": "Total money flow over the past 4 weeks. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "money_flow_2_week",
    "name": "Total Money Flow, Past 2 Weeks",
    "shortName": "Total Money Flow, 2 Weeks",
    "category": "Money Flow",
    "description": "Total money flow over the past 2 weeks. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "money_flow_1_week",
    "name": "Total Money Flow, Past Week",
    "shortName": "Total Money Flow, 1 Week",
    "category": "Money Flow",
    "description": "Total money flow over the past week. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "money_flow_1_day",
    "name": "Total Money Flow, 1 Day",
    "shortName": "Total Money Flow, 1 Day",
    "category": "Money Flow",
    "description": "Total money flow on the last trading day. Money flow of a security for a given day is defined to be the average of the high, low, and closing prices multiplied by volume."
  },
  {
    "id": "total_volume_52_week",
    "name": "Total Volume, Past 52 Weeks",
    "shortName": "Total Volume, 52 Weeks",
    "category": "Total Volume",
    "description": "Total number of shares traded over the past 52 weeks. Volume on past days is adjusted for splits when calculating total volume over a period of time."
  },
  {
    "id": "total_volume_26_week",
    "name": "Total Volume, Past 26 Weeks",
    "shortName": "Total Volume, 26 Weeks",
    "category": "Total Volume",
    "description": "Total number of shares traded over the past 26 weeks. Volume on past days is adjusted for splits when calculating total volume over a period of time."
  },
  {
    "id": "total_volume_13_week",
    "name": "Total Volume, Past 13 Weeks",
    "shortName": "Total Volume, 13 Weeks",
    "category": "Total Volume",
    "description": "Total number of shares traded over the past 13 weeks. Volume on past days is adjusted for splits when calculating total volume over a period of time."
  },
  {
    "id": "total_volume_4_week",
    "name": "Total Volume, Past 4 Weeks",
    "shortName": "Total Volume, 4 Weeks",
    "category": "Total Volume",
    "description": "Total number of shares traded over the past 4 weeks. Volume on past days is adjusted for splits when calculating total volume over a period of time."
  },
  {
    "id": "total_volume_2_week",
    "name": "Total Volume, Past 2 Weeks",
    "shortName": "Total Volume, 2 Weeks",
    "category": "Total Volume",
    "description": "Total number of shares traded over the past 2 weeks. Volume on past days is adjusted for splits when calculating total volume over a period of time."
  },
  {
    "id": "total_volume_1_week",
    "name": "Total Volume, Past Week",
    "shortName": "Total Volume, 1 Week",
    "category": "Total Volume",
    "description": "Total number of shares traded over the past week. Volume on past days is adjusted for splits when calculating total volume over a period of time."
  },
  {
    "id": "new_high_price_52_week",
    "name": "New 52-Week High Price",
    "shortName": "New 52-Week High Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 52-week high price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_price_26_week",
    "name": "New 26-Week High Price",
    "shortName": "New 26-Week High Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 26-week high price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_price_13_week",
    "name": "New 13-Week High Price",
    "shortName": "New 13-Week High Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 13-week high price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_price_4_week",
    "name": "New 4-Week High Price",
    "shortName": "New 4-Week High Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 4-week high price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_price_2_week",
    "name": "New 2-Week High Price",
    "shortName": "New 2-Week High Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 2-week high price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_price_1_week",
    "name": "New 1-Week High Price",
    "shortName": "New 1-Week High Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 1-week high price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_price_52_week",
    "name": "New 52-Week Low Price",
    "shortName": "New 52-Week Low Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 52-week low price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_price_26_week",
    "name": "New 26-Week Low Price",
    "shortName": "New 26-Week Low Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 26-week low price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_price_13_week",
    "name": "New 13-Week Low Price",
    "shortName": "New 13-Week Low Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 13-week low price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_price_4_week",
    "name": "New 4-Week Low Price",
    "shortName": "New 4-Week Low Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 4-week low price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_price_2_week",
    "name": "New 2-Week Low Price",
    "shortName": "New 2-Week Low Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 2-week low price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_price_1_week",
    "name": "New 1-Week Low Price",
    "shortName": "New 1-Week Low Price",
    "category": "New High/Low in Price",
    "description": "Indicates if the security reached a new 1-week low price on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_volume_52_week",
    "name": "New 52-Week High Volume",
    "shortName": "New 52-Week High Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 52-week high volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_volume_26_week",
    "name": "New 26-Week High Volume",
    "shortName": "New 26-Week High Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 26-week high volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_volume_13_week",
    "name": "New 13-Week High Volume",
    "shortName": "New 13-Week High Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 13-week high volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_volume_4_week",
    "name": "New 4-Week High Volume",
    "shortName": "New 4-Week High Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 4-week high volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_volume_2_week",
    "name": "New 2-Week High Volume",
    "shortName": "New 2-Week High Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 2-week high volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_volume_1_week",
    "name": "New 1-Week High Volume",
    "shortName": "New 1-Week High Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 1-week high volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_volume_52_week",
    "name": "New 52-Week Low Volume",
    "shortName": "New 52-Week Low Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 52-week low volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_volume_26_week",
    "name": "New 26-Week Low Volume",
    "shortName": "New 26-Week Low Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 26-week low volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_volume_13_week",
    "name": "New 13-Week Low Volume",
    "shortName": "New 13-Week Low Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 13-week low volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_volume_4_week",
    "name": "New 4-Week Low Volume",
    "shortName": "New 4-Week Low Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 4-week low volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_volume_2_week",
    "name": "New 2-Week Low Volume",
    "shortName": "New 2-Week Low Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 2-week low volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_volume_1_week",
    "name": "New 1-Week Low Volume",
    "shortName": "New 1-Week Low Volume",
    "category": "New High/Low in Volume",
    "description": "Indicates if the security reached a new 1-week low volume on the last trading day. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "percent_change_price_52_week",
    "name": "Percent Change in Price, Past 52 Weeks",
    "shortName": "% Change in Price, 52 Weeks",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price over the past 52 weeks."
  },
  {
    "id": "percent_change_price_26_week",
    "name": "Percent Change in Price, Past 26 Weeks",
    "shortName": "% Change in Price, 26 Weeks",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price over the past 26 weeks."
  },
  {
    "id": "percent_change_price_13_week",
    "name": "Percent Change in Price, Past 13 Weeks",
    "shortName": "% Change in Price, 13 Weeks",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price over the past 13 weeks."
  },
  {
    "id": "percent_change_price_4_week",
    "name": "Percent Change in Price, Past 4 Weeks",
    "shortName": "% Change in Price, 4 Weeks",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price over the past 4 weeks."
  },
  {
    "id": "percent_change_price_2_week",
    "name": "Percent Change in Price, Past 2 Weeks",
    "shortName": "% Change in Price, 2 Weeks",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price over the past 2 weeks."
  },
  {
    "id": "percent_change_price_1_week",
    "name": "Percent Change in Price, Past Week",
    "shortName": "% Change in Price, 1 Week",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price over the past week."
  },
  {
    "id": "percent_change_price_1_day",
    "name": "Percent Change in Price, 1 Day",
    "shortName": "% Change in Price, 1 Day",
    "category": "Percent Change in Price",
    "description": "Close-to-close percent change in price on the last trading day."
  },
  {
    "id": "consecutive_up_price_day",
    "name": "Consecutive Days of Increasing Price",
    "shortName": "Days of Increasing Price",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Price",
    "description": "Number of consecutive trading days the security has had a close-to-close increase in price."
  },
  {
    "id": "consecutive_up_price_week",
    "name": "Consecutive Weeks of Increasing Price",
    "shortName": "Weeks of Increasing Price",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Price",
    "description": "Number of consecutive weeks the security has had a close-to-close increase in price."
  },
  {
    "id": "consecutive_down_price_day",
    "name": "Consecutive Days of Decreasing Price",
    "shortName": "Days of Decreasing Price",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Price",
    "description": "Number of consecutive trading days the security has had a close-to-close decrease in price."
  },
  {
    "id": "consecutive_down_price_week",
    "name": "Consecutive Weeks of Decreasing Price",
    "shortName": "Weeks of Decreasing Price",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Price",
    "description": "Number of consecutive weeks the security has had a close-to-close decrease in price."
  },
  {
    "id": "consecutive_up_volume_day",
    "name": "Consecutive Days of Increasing Volume",
    "shortName": "Days of Increasing Volume",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Volume",
    "description": "Number of consecutive trading days the security has had increasing volume."
  },
  {
    "id": "consecutive_up_volume_week",
    "name": "Consecutive Weeks of Increasing Volume",
    "shortName": "Weeks of Increasing Volume",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Volume",
    "description": "Number of consecutive weeks the security has had increasing weekly volume."
  },
  {
    "id": "consecutive_down_volume_day",
    "name": "Consecutive Days of Decreasing Volume",
    "shortName": "Days of Decreasing Volume",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Volume",
    "description": "Number of consecutive trading days the security has had decreasing volume."
  },
  {
    "id": "consecutive_down_volume_week",
    "name": "Consecutive Weeks of Decreasing Volume",
    "shortName": "Weeks of Decreasing Volume",
    "category": "Consecutive Days/Weeks of Increasing/Decreasing Volume",
    "description": "Number of consecutive weeks the security has had decreasing weekly volume."
  },
  {
    "id": "average_volume_5",
    "name": "Average Volume (5-Day)",
    "shortName": "Average Volume (5-Day)",
    "category": "Average Volumes",
    "description": "5-day average of the security's trading volume."
  },
  {
    "id": "average_volume_10",
    "name": "Average Volume (10-Day)",
    "shortName": "Average Volume (10-Day)",
    "category": "Average Volumes",
    "description": "10-day average of the security's trading volume."
  },
  {
    "id": "average_volume_20",
    "name": "Average Volume (20-Day)",
    "shortName": "Average Volume (20-Day)",
    "category": "Average Volumes",
    "description": "20-day average of the security's trading volume."
  },
  {
    "id": "average_volume_50",
    "name": "Average Volume (50-Day)",
    "shortName": "Average Volume (50-Day)",
    "category": "Average Volumes",
    "description": "50-day average of the security's trading volume."
  },
  {
    "id": "average_volume_100",
    "name": "Average Volume (100-Day)",
    "shortName": "Average Volume (100-Day)",
    "category": "Average Volumes",
    "description": "100-day average of the security's trading volume."
  },
  {
    "id": "average_volume_200",
    "name": "Average Volume (200-Day)",
    "shortName": "Average Volume (200-Day)",
    "category": "Average Volumes",
    "description": "200-day average of the security's trading volume."
  },
  {
    "id": "previous_average_volume_5",
    "name": "Previous Average Volume (5-Day)",
    "shortName": "Previous Average Volume (5-Day)",
    "category": "Average Volumes",
    "description": "5-day average of the security's trading volume, on the trading day before the last trading day."
  },
  {
    "id": "previous_average_volume_10",
    "name": "Previous Average Volume (10-Day)",
    "shortName": "Previous Average Volume (10-Day)",
    "category": "Average Volumes",
    "description": "10-day average of the security's trading volume, on the trading day before the last trading day."
  },
  {
    "id": "previous_average_volume_20",
    "name": "Previous Average Volume (20-Day)",
    "shortName": "Previous Average Volume (20-Day)",
    "category": "Average Volumes",
    "description": "20-day average of the security's trading volume, on the trading day before the last trading day."
  },
  {
    "id": "previous_average_volume_50",
    "name": "Previous Average Volume (50-Day)",
    "shortName": "Previous Average Volume (50-Day)",
    "category": "Average Volumes",
    "description": "50-day average of the security's trading volume, on the trading day before the last trading day."
  },
  {
    "id": "previous_average_volume_100",
    "name": "Previous Average Volume (100-Day)",
    "shortName": "Previous Average Volume (100-Day)",
    "category": "Average Volumes",
    "description": "100-day average of the security's trading volume, on the trading day before the last trading day."
  },
  {
    "id": "previous_average_volume_200",
    "name": "Previous Average Volume (200-Day)",
    "shortName": "Previous Average Volume (200-Day)",
    "category": "Average Volumes",
    "description": "200-day average of the security's trading volume, on the trading day before the last trading day."
  },
  {
    "id": "sma_5",
    "name": "Simple Moving Average (5-Day)",
    "shortName": "5-Day SMA",
    "category": "Moving Averages",
    "description": "5-day simple moving average of the security's closing price."
  },
  {
    "id": "sma_10",
    "name": "Simple Moving Average (10-Day)",
    "shortName": "10-Day SMA",
    "category": "Moving Averages",
    "description": "10-day simple moving average of the security's closing price."
  },
  {
    "id": "sma_20",
    "name": "Simple Moving Average (20-Day)",
    "shortName": "20-Day SMA",
    "category": "Moving Averages",
    "description": "20-day simple moving average of the security's closing price."
  },
  {
    "id": "sma_50",
    "name": "Simple Moving Average (50-Day)",
    "shortName": "50-Day SMA",
    "category": "Moving Averages",
    "description": "50-day simple moving average of the security's closing price."
  },
  {
    "id": "sma_100",
    "name": "Simple Moving Average (100-Day)",
    "shortName": "100-Day SMA",
    "category": "Moving Averages",
    "description": "100-day simple moving average of the security's closing price."
  },
  {
    "id": "sma_200",
    "name": "Simple Moving Average (200-Day)",
    "shortName": "200-Day SMA",
    "category": "Moving Averages",
    "description": "200-day simple moving average of the security's closing price."
  },
  {
    "id": "previous_sma_5",
    "name": "Previous Simple Moving Average (5-Day)",
    "shortName": "Previous 5-Day SMA",
    "category": "Moving Averages",
    "description": "5-day simple moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_sma_10",
    "name": "Previous Simple Moving Average (10-Day)",
    "shortName": "Previous 10-Day SMA",
    "category": "Moving Averages",
    "description": "10-day simple moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_sma_20",
    "name": "Previous Simple Moving Average (20-Day)",
    "shortName": "Previous 20-Day SMA",
    "category": "Moving Averages",
    "description": "20-day simple moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_sma_50",
    "name": "Previous Simple Moving Average (50-Day)",
    "shortName": "Previous 50-Day SMA",
    "category": "Moving Averages",
    "description": "50-day simple moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_sma_100",
    "name": "Previous Simple Moving Average (100-Day)",
    "shortName": "Previous 100-Day SMA",
    "category": "Moving Averages",
    "description": "100-day simple moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_sma_200",
    "name": "Previous Simple Moving Average (200-Day)",
    "shortName": "Previous 200-Day SMA",
    "category": "Moving Averages",
    "description": "200-day simple moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "ema_5",
    "name": "Exponential Moving Average (5-Day)",
    "shortName": "5-Day EMA",
    "category": "Moving Averages",
    "description": "5-day exponential moving average of the security's closing price."
  },
  {
    "id": "ema_10",
    "name": "Exponential Moving Average (10-Day)",
    "shortName": "10-Day EMA",
    "category": "Moving Averages",
    "description": "10-day exponential moving average of the security's closing price."
  },
  {
    "id": "ema_20",
    "name": "Exponential Moving Average (20-Day)",
    "shortName": "20-Day EMA",
    "category": "Moving Averages",
    "description": "20-day exponential moving average of the security's closing price."
  },
  {
    "id": "ema_50",
    "name": "Exponential Moving Average (50-Day)",
    "shortName": "50-Day EMA",
    "category": "Moving Averages",
    "description": "50-day exponential moving average of the security's closing price."
  },
  {
    "id": "ema_100",
    "name": "Exponential Moving Average (100-Day)",
    "shortName": "100-Day EMA",
    "category": "Moving Averages",
    "description": "100-day exponential moving average of the security's closing price."
  },
  {
    "id": "ema_200",
    "name": "Exponential Moving Average (200-Day)",
    "shortName": "200-Day EMA",
    "category": "Moving Averages",
    "description": "200-day exponential moving average of the security's closing price."
  },
  {
    "id": "previous_ema_5",
    "name": "Previous Exponential Moving Average (5-Day)",
    "shortName": "Previous 5-Day EMA",
    "category": "Moving Averages",
    "description": "5-day exponential moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_ema_10",
    "name": "Previous Exponential Moving Average (10-Day)",
    "shortName": "Previous 10-Day EMA",
    "category": "Moving Averages",
    "description": "10-day exponential moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_ema_20",
    "name": "Previous Exponential Moving Average (20-Day)",
    "shortName": "Previous 20-Day EMA",
    "category": "Moving Averages",
    "description": "20-day exponential moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_ema_50",
    "name": "Previous Exponential Moving Average (50-Day)",
    "shortName": "Previous 50-Day EMA",
    "category": "Moving Averages",
    "description": "50-day exponential moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_ema_100",
    "name": "Previous Exponential Moving Average (100-Day)",
    "shortName": "Previous 100-Day EMA",
    "category": "Moving Averages",
    "description": "100-day exponential moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "previous_ema_200",
    "name": "Previous Exponential Moving Average (200-Day)",
    "shortName": "Previous 200-Day EMA",
    "category": "Moving Averages",
    "description": "200-day exponential moving average of the security's closing price, on the trading day before the last trading day."
  },
  {
    "id": "macd_12_26",
    "name": "MACD (12, 26, 9)",
    "shortName": "MACD (12, 26, 9)",
    "category": "Technical Indicators",
    "description": "Moving Average Convergence Divergence (MACD) indicator with a 12-day fast period and a 26-day slow period."
  },
  {
    "id": "macd_signal_12_26_9",
    "name": "MACD (12, 26, 9) Signal",
    "shortName": "MACD (12, 26, 9) Signal",
    "category": "Technical Indicators",
    "description": "Moving Average Convergence Divergence (MACD) signal line with a 12-day fast period, 26-day slow period, and 9-day EMA signal line."
  },
  {
    "id": "previous_macd_12_26",
    "name": "Previous MACD (12, 26, 9)",
    "shortName": "Previous MACD (12, 26, 9)",
    "category": "Technical Indicators",
    "description": "Moving Average Convergence Divergence (MACD) indicator with a 12-day fast period and a 26-day slow period, on the trading day before the last trading day."
  },
  {
    "id": "previous_macd_signal_12_26_9",
    "name": "Previous MACD (12, 26, 9) Signal",
    "shortName": "Previous MACD (12, 26, 9) Signal",
    "category": "Technical Indicators",
    "description": "Moving Average Convergence Divergence (MACD) signal line with a 12-day fast period, 26-day slow period, and 9-day EMA signal line, on the trading day before the last trading day."
  },
  {
    "id": "rsi_14",
    "name": "RSI (14-Day)",
    "shortName": "RSI (14-Day)",
    "category": "Technical Indicators",
    "description": "14-day Relative Strength Index (RSI) on the last trading day."
  },
  {
    "id": "previous_rsi_14",
    "name": "Previous RSI (14-Day)",
    "shortName": "Previous RSI (14-Day)",
    "category": "Technical Indicators",
    "description": "14-day Relative Strength Index (RSI) on the trading day before the last trading day."
  },
  {
    "id": "stochastic_14_3_3_k",
    "name": "Stochastic (14, 3, 3) %K",
    "shortName": "Stochastic (14, 3, 3) %K",
    "category": "Technical Indicators",
    "description": "14-day Stochastic Oscillator %K line with a 3-day %K smoothing period."
  },
  {
    "id": "stochastic_14_3_3_d",
    "name": "Stochastic (14, 3, 3) %D",
    "shortName": "Stochastic (14, 3, 3) %D",
    "category": "Technical Indicators",
    "description": "14-day Stochastic Oscillator %D line with a 3-day %K smoothing period and a 3-day %D period."
  },
  {
    "id": "previous_stochastic_14_3_3_k",
    "name": "Previous Stochastic (14, 3, 3) %K",
    "shortName": "Previous Stochastic (14, 3, 3) %K",
    "category": "Technical Indicators",
    "description": "14-day Stochastic Oscillator %K line with a 3-day %K smoothing period, on the trading day before the last trading day."
  },
  {
    "id": "previous_stochastic_14_3_3_d",
    "name": "Previous Stochastic (14, 3, 3) %D",
    "shortName": "Previous Stochastic (14, 3, 3) %D",
    "category": "Technical Indicators",
    "description": "14-day Stochastic Oscillator %D line with a 3-day %K smoothing period and a 3-day %D period, on the trading day before the last trading day."
  },
  {
    "id": "bollinger_upper_20_2",
    "name": "Upper Bollinger Band (20, 2)",
    "shortName": "Upper BB(20, 2)",
    "category": "Technical Indicators",
    "description": "20-day, 2-standard-deviation upper Bollinger Band on the last trading day."
  },
  {
    "id": "bollinger_lower_20_2",
    "name": "Lower Bollinger Band (20, 2)",
    "shortName": "Lower BB(20, 2)",
    "category": "Technical Indicators",
    "description": "20-day, 2-standard-deviation lower Bollinger Band on the last trading day."
  },
  {
    "id": "previous_bollinger_upper_20_2",
    "name": "Previous Upper Bollinger Band (20, 2)",
    "shortName": "Previous Upper BB(20, 2)",
    "category": "Technical Indicators",
    "description": "20-day, 2-standard-deviation upper Bollinger Band on the trading day before the last trading day."
  },
  {
    "id": "previous_bollinger_lower_20_2",
    "name": "Previous Lower Bollinger Band (20, 2)",
    "shortName": "Previous Lower BB(20, 2)",
    "category": "Technical Indicators",
    "description": "20-day, 2-standard-deviation lower Bollinger Band on the trading day before the last trading day."
  },
  {
    "id": "hv_10",
    "name": "Historical Volatility (Close-to-Close) (10-Day)",
    "shortName": "10-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 10 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_20",
    "name": "Historical Volatility (Close-to-Close) (20-Day)",
    "shortName": "20-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 20 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_30",
    "name": "Historical Volatility (Close-to-Close) (30-Day)",
    "shortName": "30-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 30 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_60",
    "name": "Historical Volatility (Close-to-Close) (60-Day)",
    "shortName": "60-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 60 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_90",
    "name": "Historical Volatility (Close-to-Close) (90-Day)",
    "shortName": "90-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 90 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_120",
    "name": "Historical Volatility (Close-to-Close) (120-Day)",
    "shortName": "120-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 120 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_150",
    "name": "Historical Volatility (Close-to-Close) (150-Day)",
    "shortName": "150-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 150 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_180",
    "name": "Historical Volatility (Close-to-Close) (180-Day)",
    "shortName": "180-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 180 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_270",
    "name": "Historical Volatility (Close-to-Close) (270-Day)",
    "shortName": "270-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 270 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "hv_360",
    "name": "Historical Volatility (Close-to-Close) (360-Day)",
    "shortName": "360-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 360 calendar days, calculated using the closing prices on each trading day."
  },
  {
    "id": "previous_hv_10",
    "name": "Previous Historical Volatility (Close-to-Close) (10-Day)",
    "shortName": "10-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 10 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_20",
    "name": "Previous Historical Volatility (Close-to-Close) (20-Day)",
    "shortName": "20-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 20 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_30",
    "name": "Previous Historical Volatility (Close-to-Close) (30-Day)",
    "shortName": "30-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 30 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_60",
    "name": "Previous Historical Volatility (Close-to-Close) (60-Day)",
    "shortName": "60-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 60 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_90",
    "name": "Previous Historical Volatility (Close-to-Close) (90-Day)",
    "shortName": "90-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 90 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_120",
    "name": "Previous Historical Volatility (Close-to-Close) (120-Day)",
    "shortName": "120-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 120 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_150",
    "name": "Previous Historical Volatility (Close-to-Close) (150-Day)",
    "shortName": "150-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 150 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_180",
    "name": "Previous Historical Volatility (Close-to-Close) (180-Day)",
    "shortName": "180-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 180 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_270",
    "name": "Previous Historical Volatility (Close-to-Close) (270-Day)",
    "shortName": "270-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 270 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_hv_360",
    "name": "Previous Historical Volatility (Close-to-Close) (360-Day)",
    "shortName": "360-Day HV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 360 calendar days, calculated using the closing prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "hv_10_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (10-Day) 52-Week Low",
    "shortName": "10-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 10-day historical volatility."
  },
  {
    "id": "hv_20_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (20-Day) 52-Week Low",
    "shortName": "20-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 20-day historical volatility."
  },
  {
    "id": "hv_30_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (30-Day) 52-Week Low",
    "shortName": "30-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 30-day historical volatility."
  },
  {
    "id": "hv_60_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (60-Day) 52-Week Low",
    "shortName": "60-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 60-day historical volatility."
  },
  {
    "id": "hv_90_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (90-Day) 52-Week Low",
    "shortName": "90-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 90-day historical volatility."
  },
  {
    "id": "hv_120_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (120-Day) 52-Week Low",
    "shortName": "120-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 120-day historical volatility."
  },
  {
    "id": "hv_150_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (150-Day) 52-Week Low",
    "shortName": "150-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 150-day historical volatility."
  },
  {
    "id": "hv_180_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (180-Day) 52-Week Low",
    "shortName": "180-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 180-day historical volatility."
  },
  {
    "id": "hv_270_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (270-Day) 52-Week Low",
    "shortName": "270-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 270-day historical volatility."
  },
  {
    "id": "hv_360_low_52_week",
    "name": "Historical Volatility (Close-to-Close) (360-Day) 52-Week Low",
    "shortName": "360-Day HV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 360-day historical volatility."
  },
  {
    "id": "hv_10_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (10-Day) 52-Week High",
    "shortName": "10-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 10-day historical volatility."
  },
  {
    "id": "hv_20_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (20-Day) 52-Week High",
    "shortName": "20-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 20-day historical volatility."
  },
  {
    "id": "hv_30_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (30-Day) 52-Week High",
    "shortName": "30-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 30-day historical volatility."
  },
  {
    "id": "hv_60_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (60-Day) 52-Week High",
    "shortName": "60-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 60-day historical volatility."
  },
  {
    "id": "hv_90_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (90-Day) 52-Week High",
    "shortName": "90-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 90-day historical volatility."
  },
  {
    "id": "hv_120_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (120-Day) 52-Week High",
    "shortName": "120-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 120-day historical volatility."
  },
  {
    "id": "hv_150_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (150-Day) 52-Week High",
    "shortName": "150-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 150-day historical volatility."
  },
  {
    "id": "hv_180_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (180-Day) 52-Week High",
    "shortName": "180-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 180-day historical volatility."
  },
  {
    "id": "hv_270_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (270-Day) 52-Week High",
    "shortName": "270-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 270-day historical volatility."
  },
  {
    "id": "hv_360_high_52_week",
    "name": "Historical Volatility (Close-to-Close) (360-Day) 52-Week High",
    "shortName": "360-Day HV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 360-day historical volatility."
  },
  {
    "id": "hv_10_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (10-Day) 52-Week Rank",
    "shortName": "10-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 10-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_20_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (20-Day) 52-Week Rank",
    "shortName": "20-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 20-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_30_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (30-Day) 52-Week Rank",
    "shortName": "30-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 30-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_60_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (60-Day) 52-Week Rank",
    "shortName": "60-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 60-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_90_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (90-Day) 52-Week Rank",
    "shortName": "90-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 90-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_120_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (120-Day) 52-Week Rank",
    "shortName": "120-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 120-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_150_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (150-Day) 52-Week Rank",
    "shortName": "150-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 150-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_180_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (180-Day) 52-Week Rank",
    "shortName": "180-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 180-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_270_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (270-Day) 52-Week Rank",
    "shortName": "270-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 270-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_360_rank_52_week",
    "name": "Historical Volatility (Close-to-Close) (360-Day) 52-Week Rank",
    "shortName": "360-Day HV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 360-day historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "hv_10_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (10-Day) 52-Week Percentile",
    "shortName": "10-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 10-day historical volatility."
  },
  {
    "id": "hv_20_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (20-Day) 52-Week Percentile",
    "shortName": "20-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 20-day historical volatility."
  },
  {
    "id": "hv_30_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (30-Day) 52-Week Percentile",
    "shortName": "30-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 30-day historical volatility."
  },
  {
    "id": "hv_60_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (60-Day) 52-Week Percentile",
    "shortName": "60-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 60-day historical volatility."
  },
  {
    "id": "hv_90_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (90-Day) 52-Week Percentile",
    "shortName": "90-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 90-day historical volatility."
  },
  {
    "id": "hv_120_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (120-Day) 52-Week Percentile",
    "shortName": "120-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 120-day historical volatility."
  },
  {
    "id": "hv_150_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (150-Day) 52-Week Percentile",
    "shortName": "150-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 150-day historical volatility."
  },
  {
    "id": "hv_180_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (180-Day) 52-Week Percentile",
    "shortName": "180-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 180-day historical volatility."
  },
  {
    "id": "hv_270_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (270-Day) 52-Week Percentile",
    "shortName": "270-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 270-day historical volatility."
  },
  {
    "id": "hv_360_percentile_52_week",
    "name": "Historical Volatility (Close-to-Close) (360-Day) 52-Week Percentile",
    "shortName": "360-Day HV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 360-day historical volatility."
  },
  {
    "id": "phv_10",
    "name": "Historical Volatility (Parkinson) (10-Day)",
    "shortName": "10-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 10 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_20",
    "name": "Historical Volatility (Parkinson) (20-Day)",
    "shortName": "20-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 20 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_30",
    "name": "Historical Volatility (Parkinson) (30-Day)",
    "shortName": "30-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 30 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_60",
    "name": "Historical Volatility (Parkinson) (60-Day)",
    "shortName": "60-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 60 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_90",
    "name": "Historical Volatility (Parkinson) (90-Day)",
    "shortName": "90-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 90 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_120",
    "name": "Historical Volatility (Parkinson) (120-Day)",
    "shortName": "120-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 120 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_150",
    "name": "Historical Volatility (Parkinson) (150-Day)",
    "shortName": "150-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 150 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_180",
    "name": "Historical Volatility (Parkinson) (180-Day)",
    "shortName": "180-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 180 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_270",
    "name": "Historical Volatility (Parkinson) (270-Day)",
    "shortName": "270-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 270 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "phv_360",
    "name": "Historical Volatility (Parkinson) (360-Day)",
    "shortName": "360-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 360 calendar days, calculated using the high and low prices on each trading day."
  },
  {
    "id": "previous_phv_10",
    "name": "Previous Historical Volatility (Parkinson) (10-Day)",
    "shortName": "10-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 10 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_20",
    "name": "Previous Historical Volatility (Parkinson) (20-Day)",
    "shortName": "20-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 20 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_30",
    "name": "Previous Historical Volatility (Parkinson) (30-Day)",
    "shortName": "30-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 30 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_60",
    "name": "Previous Historical Volatility (Parkinson) (60-Day)",
    "shortName": "60-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 60 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_90",
    "name": "Previous Historical Volatility (Parkinson) (90-Day)",
    "shortName": "90-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 90 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_120",
    "name": "Previous Historical Volatility (Parkinson) (120-Day)",
    "shortName": "120-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 120 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_150",
    "name": "Previous Historical Volatility (Parkinson) (150-Day)",
    "shortName": "150-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 150 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_180",
    "name": "Previous Historical Volatility (Parkinson) (180-Day)",
    "shortName": "180-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 180 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_270",
    "name": "Previous Historical Volatility (Parkinson) (270-Day)",
    "shortName": "270-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 270 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "previous_phv_360",
    "name": "Previous Historical Volatility (Parkinson) (360-Day)",
    "shortName": "360-Day PHV",
    "category": "Historical Volatility",
    "description": "Volatility of the security over the past 360 calendar days, calculated using the high and low prices on each trading day, on the trading day before the last trading day."
  },
  {
    "id": "phv_10_low_52_week",
    "name": "Historical Volatility (Parkinson) (10-Day) 52-Week Low",
    "shortName": "10-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 10-day Parkinson historical volatility."
  },
  {
    "id": "phv_20_low_52_week",
    "name": "Historical Volatility (Parkinson) (20-Day) 52-Week Low",
    "shortName": "20-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 20-day Parkinson historical volatility."
  },
  {
    "id": "phv_30_low_52_week",
    "name": "Historical Volatility (Parkinson) (30-Day) 52-Week Low",
    "shortName": "30-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 30-day Parkinson historical volatility."
  },
  {
    "id": "phv_60_low_52_week",
    "name": "Historical Volatility (Parkinson) (60-Day) 52-Week Low",
    "shortName": "60-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 60-day Parkinson historical volatility."
  },
  {
    "id": "phv_90_low_52_week",
    "name": "Historical Volatility (Parkinson) (90-Day) 52-Week Low",
    "shortName": "90-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 90-day Parkinson historical volatility."
  },
  {
    "id": "phv_120_low_52_week",
    "name": "Historical Volatility (Parkinson) (120-Day) 52-Week Low",
    "shortName": "120-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 120-day Parkinson historical volatility."
  },
  {
    "id": "phv_150_low_52_week",
    "name": "Historical Volatility (Parkinson) (150-Day) 52-Week Low",
    "shortName": "150-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 150-day Parkinson historical volatility."
  },
  {
    "id": "phv_180_low_52_week",
    "name": "Historical Volatility (Parkinson) (180-Day) 52-Week Low",
    "shortName": "180-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 180-day Parkinson historical volatility."
  },
  {
    "id": "phv_270_low_52_week",
    "name": "Historical Volatility (Parkinson) (270-Day) 52-Week Low",
    "shortName": "270-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 270-day Parkinson historical volatility."
  },
  {
    "id": "phv_360_low_52_week",
    "name": "Historical Volatility (Parkinson) (360-Day) 52-Week Low",
    "shortName": "360-Day PHV 52-Week Low",
    "category": "Historical Volatility",
    "description": "52-week low of the security's 360-day Parkinson historical volatility."
  },
  {
    "id": "phv_10_high_52_week",
    "name": "Historical Volatility (Parkinson) (10-Day) 52-Week High",
    "shortName": "10-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 10-day Parkinson historical volatility."
  },
  {
    "id": "phv_20_high_52_week",
    "name": "Historical Volatility (Parkinson) (20-Day) 52-Week High",
    "shortName": "20-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 20-day Parkinson historical volatility."
  },
  {
    "id": "phv_30_high_52_week",
    "name": "Historical Volatility (Parkinson) (30-Day) 52-Week High",
    "shortName": "30-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 30-day Parkinson historical volatility."
  },
  {
    "id": "phv_60_high_52_week",
    "name": "Historical Volatility (Parkinson) (60-Day) 52-Week High",
    "shortName": "60-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 60-day Parkinson historical volatility."
  },
  {
    "id": "phv_90_high_52_week",
    "name": "Historical Volatility (Parkinson) (90-Day) 52-Week High",
    "shortName": "90-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 90-day Parkinson historical volatility."
  },
  {
    "id": "phv_120_high_52_week",
    "name": "Historical Volatility (Parkinson) (120-Day) 52-Week High",
    "shortName": "120-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 120-day Parkinson historical volatility."
  },
  {
    "id": "phv_150_high_52_week",
    "name": "Historical Volatility (Parkinson) (150-Day) 52-Week High",
    "shortName": "150-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 150-day Parkinson historical volatility."
  },
  {
    "id": "phv_180_high_52_week",
    "name": "Historical Volatility (Parkinson) (180-Day) 52-Week High",
    "shortName": "180-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 180-day Parkinson historical volatility."
  },
  {
    "id": "phv_270_high_52_week",
    "name": "Historical Volatility (Parkinson) (270-Day) 52-Week High",
    "shortName": "270-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 270-day Parkinson historical volatility."
  },
  {
    "id": "phv_360_high_52_week",
    "name": "Historical Volatility (Parkinson) (360-Day) 52-Week High",
    "shortName": "360-Day PHV 52-Week High",
    "category": "Historical Volatility",
    "description": "52-week high of the security's 360-day Parkinson historical volatility."
  },
  {
    "id": "phv_10_rank_52_week",
    "name": "Historical Volatility (Parkinson) (10-Day) 52-Week Rank",
    "shortName": "10-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 10-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_20_rank_52_week",
    "name": "Historical Volatility (Parkinson) (20-Day) 52-Week Rank",
    "shortName": "20-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 20-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_30_rank_52_week",
    "name": "Historical Volatility (Parkinson) (30-Day) 52-Week Rank",
    "shortName": "30-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 30-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_60_rank_52_week",
    "name": "Historical Volatility (Parkinson) (60-Day) 52-Week Rank",
    "shortName": "60-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 60-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_90_rank_52_week",
    "name": "Historical Volatility (Parkinson) (90-Day) 52-Week Rank",
    "shortName": "90-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 90-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_120_rank_52_week",
    "name": "Historical Volatility (Parkinson) (120-Day) 52-Week Rank",
    "shortName": "120-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 120-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_150_rank_52_week",
    "name": "Historical Volatility (Parkinson) (150-Day) 52-Week Rank",
    "shortName": "150-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 150-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_180_rank_52_week",
    "name": "Historical Volatility (Parkinson) (180-Day) 52-Week Rank",
    "shortName": "180-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 180-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_270_rank_52_week",
    "name": "Historical Volatility (Parkinson) (270-Day) 52-Week Rank",
    "shortName": "270-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 270-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_360_rank_52_week",
    "name": "Historical Volatility (Parkinson) (360-Day) 52-Week Rank",
    "shortName": "360-Day PHV 52-Week Rank",
    "category": "Historical Volatility",
    "description": "52-week rank of the security's 360-day Parkinson historical volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "phv_10_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (10-Day) 52-Week Percentile",
    "shortName": "10-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 10-day Parkinson historical volatility."
  },
  {
    "id": "phv_20_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (20-Day) 52-Week Percentile",
    "shortName": "20-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 20-day Parkinson historical volatility."
  },
  {
    "id": "phv_30_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (30-Day) 52-Week Percentile",
    "shortName": "30-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 30-day Parkinson historical volatility."
  },
  {
    "id": "phv_60_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (60-Day) 52-Week Percentile",
    "shortName": "60-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 60-day Parkinson historical volatility."
  },
  {
    "id": "phv_90_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (90-Day) 52-Week Percentile",
    "shortName": "90-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 90-day Parkinson historical volatility."
  },
  {
    "id": "phv_120_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (120-Day) 52-Week Percentile",
    "shortName": "120-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 120-day Parkinson historical volatility."
  },
  {
    "id": "phv_150_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (150-Day) 52-Week Percentile",
    "shortName": "150-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 150-day Parkinson historical volatility."
  },
  {
    "id": "phv_180_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (180-Day) 52-Week Percentile",
    "shortName": "180-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 180-day Parkinson historical volatility."
  },
  {
    "id": "phv_270_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (270-Day) 52-Week Percentile",
    "shortName": "270-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 270-day Parkinson historical volatility."
  },
  {
    "id": "phv_360_percentile_52_week",
    "name": "Historical Volatility (Parkinson) (360-Day) 52-Week Percentile",
    "shortName": "360-Day PHV 52-Week Percentile",
    "category": "Historical Volatility",
    "description": "52-week percentile of the security's 360-day Parkinson historical volatility."
  },
  {
    "id": "iv_call_10",
    "name": "Implied Volatility (Calls) (10-Day)",
    "shortName": "10-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 10 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_20",
    "name": "Implied Volatility (Calls) (20-Day)",
    "shortName": "20-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 20 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_30",
    "name": "Implied Volatility (Calls) (30-Day)",
    "shortName": "30-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 30 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_60",
    "name": "Implied Volatility (Calls) (60-Day)",
    "shortName": "60-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 60 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_90",
    "name": "Implied Volatility (Calls) (90-Day)",
    "shortName": "90-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 90 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_120",
    "name": "Implied Volatility (Calls) (120-Day)",
    "shortName": "120-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 120 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_150",
    "name": "Implied Volatility (Calls) (150-Day)",
    "shortName": "150-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 150 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_180",
    "name": "Implied Volatility (Calls) (180-Day)",
    "shortName": "180-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 180 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_270",
    "name": "Implied Volatility (Calls) (270-Day)",
    "shortName": "270-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 270 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "iv_call_360",
    "name": "Implied Volatility (Calls) (360-Day)",
    "shortName": "360-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 360 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date."
  },
  {
    "id": "previous_iv_call_10",
    "name": "Previous Implied Volatility (Calls) (10-Day)",
    "shortName": "10-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 10 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_20",
    "name": "Previous Implied Volatility (Calls) (20-Day)",
    "shortName": "20-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 20 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_30",
    "name": "Previous Implied Volatility (Calls) (30-Day)",
    "shortName": "30-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 30 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_60",
    "name": "Previous Implied Volatility (Calls) (60-Day)",
    "shortName": "60-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 60 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_90",
    "name": "Previous Implied Volatility (Calls) (90-Day)",
    "shortName": "90-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 90 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_120",
    "name": "Previous Implied Volatility (Calls) (120-Day)",
    "shortName": "120-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 120 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_150",
    "name": "Previous Implied Volatility (Calls) (150-Day)",
    "shortName": "150-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 150 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_180",
    "name": "Previous Implied Volatility (Calls) (180-Day)",
    "shortName": "180-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 180 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_270",
    "name": "Previous Implied Volatility (Calls) (270-Day)",
    "shortName": "270-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 270 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_call_360",
    "name": "Previous Implied Volatility (Calls) (360-Day)",
    "shortName": "360-Day Call IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 360 calendar days, derived from the pricing of the at-the-money call options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "iv_call_10_low_52_week",
    "name": "Implied Volatility (Calls) (10-Day) 52-Week Low",
    "shortName": "10-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 10-day call implied volatility."
  },
  {
    "id": "iv_call_20_low_52_week",
    "name": "Implied Volatility (Calls) (20-Day) 52-Week Low",
    "shortName": "20-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 20-day call implied volatility."
  },
  {
    "id": "iv_call_30_low_52_week",
    "name": "Implied Volatility (Calls) (30-Day) 52-Week Low",
    "shortName": "30-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 30-day call implied volatility."
  },
  {
    "id": "iv_call_60_low_52_week",
    "name": "Implied Volatility (Calls) (60-Day) 52-Week Low",
    "shortName": "60-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 60-day call implied volatility."
  },
  {
    "id": "iv_call_90_low_52_week",
    "name": "Implied Volatility (Calls) (90-Day) 52-Week Low",
    "shortName": "90-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 90-day call implied volatility."
  },
  {
    "id": "iv_call_120_low_52_week",
    "name": "Implied Volatility (Calls) (120-Day) 52-Week Low",
    "shortName": "120-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 120-day call implied volatility."
  },
  {
    "id": "iv_call_150_low_52_week",
    "name": "Implied Volatility (Calls) (150-Day) 52-Week Low",
    "shortName": "150-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 150-day call implied volatility."
  },
  {
    "id": "iv_call_180_low_52_week",
    "name": "Implied Volatility (Calls) (180-Day) 52-Week Low",
    "shortName": "180-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 180-day call implied volatility."
  },
  {
    "id": "iv_call_270_low_52_week",
    "name": "Implied Volatility (Calls) (270-Day) 52-Week Low",
    "shortName": "270-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 270-day call implied volatility."
  },
  {
    "id": "iv_call_360_low_52_week",
    "name": "Implied Volatility (Calls) (360-Day) 52-Week Low",
    "shortName": "360-Day Call IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 360-day call implied volatility."
  },
  {
    "id": "iv_call_10_high_52_week",
    "name": "Implied Volatility (Calls) (10-Day) 52-Week High",
    "shortName": "10-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 10-day call implied volatility."
  },
  {
    "id": "iv_call_20_high_52_week",
    "name": "Implied Volatility (Calls) (20-Day) 52-Week High",
    "shortName": "20-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 20-day call implied volatility."
  },
  {
    "id": "iv_call_30_high_52_week",
    "name": "Implied Volatility (Calls) (30-Day) 52-Week High",
    "shortName": "30-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 30-day call implied volatility."
  },
  {
    "id": "iv_call_60_high_52_week",
    "name": "Implied Volatility (Calls) (60-Day) 52-Week High",
    "shortName": "60-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 60-day call implied volatility."
  },
  {
    "id": "iv_call_90_high_52_week",
    "name": "Implied Volatility (Calls) (90-Day) 52-Week High",
    "shortName": "90-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 90-day call implied volatility."
  },
  {
    "id": "iv_call_120_high_52_week",
    "name": "Implied Volatility (Calls) (120-Day) 52-Week High",
    "shortName": "120-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 120-day call implied volatility."
  },
  {
    "id": "iv_call_150_high_52_week",
    "name": "Implied Volatility (Calls) (150-Day) 52-Week High",
    "shortName": "150-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 150-day call implied volatility."
  },
  {
    "id": "iv_call_180_high_52_week",
    "name": "Implied Volatility (Calls) (180-Day) 52-Week High",
    "shortName": "180-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 180-day call implied volatility."
  },
  {
    "id": "iv_call_270_high_52_week",
    "name": "Implied Volatility (Calls) (270-Day) 52-Week High",
    "shortName": "270-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 270-day call implied volatility."
  },
  {
    "id": "iv_call_360_high_52_week",
    "name": "Implied Volatility (Calls) (360-Day) 52-Week High",
    "shortName": "360-Day Call IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 360-day call implied volatility."
  },
  {
    "id": "iv_call_10_rank_52_week",
    "name": "Implied Volatility (Calls) (10-Day) 52-Week Rank",
    "shortName": "10-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 10-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_20_rank_52_week",
    "name": "Implied Volatility (Calls) (20-Day) 52-Week Rank",
    "shortName": "20-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 20-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_30_rank_52_week",
    "name": "Implied Volatility (Calls) (30-Day) 52-Week Rank",
    "shortName": "30-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 30-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_60_rank_52_week",
    "name": "Implied Volatility (Calls) (60-Day) 52-Week Rank",
    "shortName": "60-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 60-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_90_rank_52_week",
    "name": "Implied Volatility (Calls) (90-Day) 52-Week Rank",
    "shortName": "90-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 90-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_120_rank_52_week",
    "name": "Implied Volatility (Calls) (120-Day) 52-Week Rank",
    "shortName": "120-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 120-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_150_rank_52_week",
    "name": "Implied Volatility (Calls) (150-Day) 52-Week Rank",
    "shortName": "150-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 150-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_180_rank_52_week",
    "name": "Implied Volatility (Calls) (180-Day) 52-Week Rank",
    "shortName": "180-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 180-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_270_rank_52_week",
    "name": "Implied Volatility (Calls) (270-Day) 52-Week Rank",
    "shortName": "270-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 270-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_360_rank_52_week",
    "name": "Implied Volatility (Calls) (360-Day) 52-Week Rank",
    "shortName": "360-Day Call IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 360-day call implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_call_10_percentile_52_week",
    "name": "Implied Volatility (Calls) (10-Day) 52-Week Percentile",
    "shortName": "10-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 10-day call implied volatility."
  },
  {
    "id": "iv_call_20_percentile_52_week",
    "name": "Implied Volatility (Calls) (20-Day) 52-Week Percentile",
    "shortName": "20-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 20-day call implied volatility."
  },
  {
    "id": "iv_call_30_percentile_52_week",
    "name": "Implied Volatility (Calls) (30-Day) 52-Week Percentile",
    "shortName": "30-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 30-day call implied volatility."
  },
  {
    "id": "iv_call_60_percentile_52_week",
    "name": "Implied Volatility (Calls) (60-Day) 52-Week Percentile",
    "shortName": "60-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 60-day call implied volatility."
  },
  {
    "id": "iv_call_90_percentile_52_week",
    "name": "Implied Volatility (Calls) (90-Day) 52-Week Percentile",
    "shortName": "90-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 90-day call implied volatility."
  },
  {
    "id": "iv_call_120_percentile_52_week",
    "name": "Implied Volatility (Calls) (120-Day) 52-Week Percentile",
    "shortName": "120-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 120-day call implied volatility."
  },
  {
    "id": "iv_call_150_percentile_52_week",
    "name": "Implied Volatility (Calls) (150-Day) 52-Week Percentile",
    "shortName": "150-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 150-day call implied volatility."
  },
  {
    "id": "iv_call_180_percentile_52_week",
    "name": "Implied Volatility (Calls) (180-Day) 52-Week Percentile",
    "shortName": "180-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 180-day call implied volatility."
  },
  {
    "id": "iv_call_270_percentile_52_week",
    "name": "Implied Volatility (Calls) (270-Day) 52-Week Percentile",
    "shortName": "270-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 270-day call implied volatility."
  },
  {
    "id": "iv_call_360_percentile_52_week",
    "name": "Implied Volatility (Calls) (360-Day) 52-Week Percentile",
    "shortName": "360-Day Call IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 360-day call implied volatility."
  },
  {
    "id": "iv_put_10",
    "name": "Implied Volatility (Puts) (10-Day)",
    "shortName": "10-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 10 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_20",
    "name": "Implied Volatility (Puts) (20-Day)",
    "shortName": "20-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 20 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_30",
    "name": "Implied Volatility (Puts) (30-Day)",
    "shortName": "30-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 30 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_60",
    "name": "Implied Volatility (Puts) (60-Day)",
    "shortName": "60-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 60 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_90",
    "name": "Implied Volatility (Puts) (90-Day)",
    "shortName": "90-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 90 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_120",
    "name": "Implied Volatility (Puts) (120-Day)",
    "shortName": "120-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 120 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_150",
    "name": "Implied Volatility (Puts) (150-Day)",
    "shortName": "150-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 150 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_180",
    "name": "Implied Volatility (Puts) (180-Day)",
    "shortName": "180-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 180 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_270",
    "name": "Implied Volatility (Puts) (270-Day)",
    "shortName": "270-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 270 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "iv_put_360",
    "name": "Implied Volatility (Puts) (360-Day)",
    "shortName": "360-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 360 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date."
  },
  {
    "id": "previous_iv_put_10",
    "name": "Previous Implied Volatility (Puts) (10-Day)",
    "shortName": "10-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 10 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_20",
    "name": "Previous Implied Volatility (Puts) (20-Day)",
    "shortName": "20-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 20 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_30",
    "name": "Previous Implied Volatility (Puts) (30-Day)",
    "shortName": "30-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 30 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_60",
    "name": "Previous Implied Volatility (Puts) (60-Day)",
    "shortName": "60-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 60 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_90",
    "name": "Previous Implied Volatility (Puts) (90-Day)",
    "shortName": "90-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 90 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_120",
    "name": "Previous Implied Volatility (Puts) (120-Day)",
    "shortName": "120-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 120 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_150",
    "name": "Previous Implied Volatility (Puts) (150-Day)",
    "shortName": "150-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 150 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_180",
    "name": "Previous Implied Volatility (Puts) (180-Day)",
    "shortName": "180-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 180 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_270",
    "name": "Previous Implied Volatility (Puts) (270-Day)",
    "shortName": "270-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 270 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_put_360",
    "name": "Previous Implied Volatility (Puts) (360-Day)",
    "shortName": "360-Day Put IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 360 calendar days, derived from the pricing of the at-the-money put options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "iv_put_10_low_52_week",
    "name": "Implied Volatility (Puts) (10-Day) 52-Week Low",
    "shortName": "10-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 10-day put implied volatility."
  },
  {
    "id": "iv_put_20_low_52_week",
    "name": "Implied Volatility (Puts) (20-Day) 52-Week Low",
    "shortName": "20-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 20-day put implied volatility."
  },
  {
    "id": "iv_put_30_low_52_week",
    "name": "Implied Volatility (Puts) (30-Day) 52-Week Low",
    "shortName": "30-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 30-day put implied volatility."
  },
  {
    "id": "iv_put_60_low_52_week",
    "name": "Implied Volatility (Puts) (60-Day) 52-Week Low",
    "shortName": "60-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 60-day put implied volatility."
  },
  {
    "id": "iv_put_90_low_52_week",
    "name": "Implied Volatility (Puts) (90-Day) 52-Week Low",
    "shortName": "90-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 90-day put implied volatility."
  },
  {
    "id": "iv_put_120_low_52_week",
    "name": "Implied Volatility (Puts) (120-Day) 52-Week Low",
    "shortName": "120-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 120-day put implied volatility."
  },
  {
    "id": "iv_put_150_low_52_week",
    "name": "Implied Volatility (Puts) (150-Day) 52-Week Low",
    "shortName": "150-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 150-day put implied volatility."
  },
  {
    "id": "iv_put_180_low_52_week",
    "name": "Implied Volatility (Puts) (180-Day) 52-Week Low",
    "shortName": "180-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 180-day put implied volatility."
  },
  {
    "id": "iv_put_270_low_52_week",
    "name": "Implied Volatility (Puts) (270-Day) 52-Week Low",
    "shortName": "270-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 270-day put implied volatility."
  },
  {
    "id": "iv_put_360_low_52_week",
    "name": "Implied Volatility (Puts) (360-Day) 52-Week Low",
    "shortName": "360-Day Put IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 360-day put implied volatility."
  },
  {
    "id": "iv_put_10_high_52_week",
    "name": "Implied Volatility (Puts) (10-Day) 52-Week High",
    "shortName": "10-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 10-day put implied volatility."
  },
  {
    "id": "iv_put_20_high_52_week",
    "name": "Implied Volatility (Puts) (20-Day) 52-Week High",
    "shortName": "20-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 20-day put implied volatility."
  },
  {
    "id": "iv_put_30_high_52_week",
    "name": "Implied Volatility (Puts) (30-Day) 52-Week High",
    "shortName": "30-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 30-day put implied volatility."
  },
  {
    "id": "iv_put_60_high_52_week",
    "name": "Implied Volatility (Puts) (60-Day) 52-Week High",
    "shortName": "60-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 60-day put implied volatility."
  },
  {
    "id": "iv_put_90_high_52_week",
    "name": "Implied Volatility (Puts) (90-Day) 52-Week High",
    "shortName": "90-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 90-day put implied volatility."
  },
  {
    "id": "iv_put_120_high_52_week",
    "name": "Implied Volatility (Puts) (120-Day) 52-Week High",
    "shortName": "120-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 120-day put implied volatility."
  },
  {
    "id": "iv_put_150_high_52_week",
    "name": "Implied Volatility (Puts) (150-Day) 52-Week High",
    "shortName": "150-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 150-day put implied volatility."
  },
  {
    "id": "iv_put_180_high_52_week",
    "name": "Implied Volatility (Puts) (180-Day) 52-Week High",
    "shortName": "180-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 180-day put implied volatility."
  },
  {
    "id": "iv_put_270_high_52_week",
    "name": "Implied Volatility (Puts) (270-Day) 52-Week High",
    "shortName": "270-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 270-day put implied volatility."
  },
  {
    "id": "iv_put_360_high_52_week",
    "name": "Implied Volatility (Puts) (360-Day) 52-Week High",
    "shortName": "360-Day Put IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 360-day put implied volatility."
  },
  {
    "id": "iv_put_10_rank_52_week",
    "name": "Implied Volatility (Puts) (10-Day) 52-Week Rank",
    "shortName": "10-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 10-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_20_rank_52_week",
    "name": "Implied Volatility (Puts) (20-Day) 52-Week Rank",
    "shortName": "20-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 20-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_30_rank_52_week",
    "name": "Implied Volatility (Puts) (30-Day) 52-Week Rank",
    "shortName": "30-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 30-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_60_rank_52_week",
    "name": "Implied Volatility (Puts) (60-Day) 52-Week Rank",
    "shortName": "60-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 60-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_90_rank_52_week",
    "name": "Implied Volatility (Puts) (90-Day) 52-Week Rank",
    "shortName": "90-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 90-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_120_rank_52_week",
    "name": "Implied Volatility (Puts) (120-Day) 52-Week Rank",
    "shortName": "120-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 120-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_150_rank_52_week",
    "name": "Implied Volatility (Puts) (150-Day) 52-Week Rank",
    "shortName": "150-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 150-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_180_rank_52_week",
    "name": "Implied Volatility (Puts) (180-Day) 52-Week Rank",
    "shortName": "180-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 180-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_270_rank_52_week",
    "name": "Implied Volatility (Puts) (270-Day) 52-Week Rank",
    "shortName": "270-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 270-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_360_rank_52_week",
    "name": "Implied Volatility (Puts) (360-Day) 52-Week Rank",
    "shortName": "360-Day Put IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 360-day put implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_put_10_percentile_52_week",
    "name": "Implied Volatility (Puts) (10-Day) 52-Week Percentile",
    "shortName": "10-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 10-day put implied volatility."
  },
  {
    "id": "iv_put_20_percentile_52_week",
    "name": "Implied Volatility (Puts) (20-Day) 52-Week Percentile",
    "shortName": "20-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 20-day put implied volatility."
  },
  {
    "id": "iv_put_30_percentile_52_week",
    "name": "Implied Volatility (Puts) (30-Day) 52-Week Percentile",
    "shortName": "30-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 30-day put implied volatility."
  },
  {
    "id": "iv_put_60_percentile_52_week",
    "name": "Implied Volatility (Puts) (60-Day) 52-Week Percentile",
    "shortName": "60-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 60-day put implied volatility."
  },
  {
    "id": "iv_put_90_percentile_52_week",
    "name": "Implied Volatility (Puts) (90-Day) 52-Week Percentile",
    "shortName": "90-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 90-day put implied volatility."
  },
  {
    "id": "iv_put_120_percentile_52_week",
    "name": "Implied Volatility (Puts) (120-Day) 52-Week Percentile",
    "shortName": "120-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 120-day put implied volatility."
  },
  {
    "id": "iv_put_150_percentile_52_week",
    "name": "Implied Volatility (Puts) (150-Day) 52-Week Percentile",
    "shortName": "150-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 150-day put implied volatility."
  },
  {
    "id": "iv_put_180_percentile_52_week",
    "name": "Implied Volatility (Puts) (180-Day) 52-Week Percentile",
    "shortName": "180-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 180-day put implied volatility."
  },
  {
    "id": "iv_put_270_percentile_52_week",
    "name": "Implied Volatility (Puts) (270-Day) 52-Week Percentile",
    "shortName": "270-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 270-day put implied volatility."
  },
  {
    "id": "iv_put_360_percentile_52_week",
    "name": "Implied Volatility (Puts) (360-Day) 52-Week Percentile",
    "shortName": "360-Day Put IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 360-day put implied volatility."
  },
  {
    "id": "iv_mean_10",
    "name": "Implied Volatility (Mean) (10-Day)",
    "shortName": "10-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 10 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_20",
    "name": "Implied Volatility (Mean) (20-Day)",
    "shortName": "20-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 20 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_30",
    "name": "Implied Volatility (Mean) (30-Day)",
    "shortName": "30-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 30 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_60",
    "name": "Implied Volatility (Mean) (60-Day)",
    "shortName": "60-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 60 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_90",
    "name": "Implied Volatility (Mean) (90-Day)",
    "shortName": "90-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 90 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_120",
    "name": "Implied Volatility (Mean) (120-Day)",
    "shortName": "120-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 120 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_150",
    "name": "Implied Volatility (Mean) (150-Day)",
    "shortName": "150-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 150 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_180",
    "name": "Implied Volatility (Mean) (180-Day)",
    "shortName": "180-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 180 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_270",
    "name": "Implied Volatility (Mean) (270-Day)",
    "shortName": "270-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 270 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "iv_mean_360",
    "name": "Implied Volatility (Mean) (360-Day)",
    "shortName": "360-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 360 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date."
  },
  {
    "id": "previous_iv_mean_10",
    "name": "Previous Implied Volatility (Mean) (10-Day)",
    "shortName": "10-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 10 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_20",
    "name": "Previous Implied Volatility (Mean) (20-Day)",
    "shortName": "20-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 20 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_30",
    "name": "Previous Implied Volatility (Mean) (30-Day)",
    "shortName": "30-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 30 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_60",
    "name": "Previous Implied Volatility (Mean) (60-Day)",
    "shortName": "60-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 60 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_90",
    "name": "Previous Implied Volatility (Mean) (90-Day)",
    "shortName": "90-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 90 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_120",
    "name": "Previous Implied Volatility (Mean) (120-Day)",
    "shortName": "120-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 120 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_150",
    "name": "Previous Implied Volatility (Mean) (150-Day)",
    "shortName": "150-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 150 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_180",
    "name": "Previous Implied Volatility (Mean) (180-Day)",
    "shortName": "180-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 180 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_270",
    "name": "Previous Implied Volatility (Mean) (270-Day)",
    "shortName": "270-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 270 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_mean_360",
    "name": "Previous Implied Volatility (Mean) (360-Day)",
    "shortName": "360-Day Mean IV",
    "category": "Implied Volatility",
    "description": "Forecasted future volatility of the security over the next 360 calendar days, derived from the average of the put and call implied volatilities of the at-the-money options with expiration dates closest to the target expiration date, on the trading day before the last trading day."
  },
  {
    "id": "iv_mean_10_low_52_week",
    "name": "Implied Volatility (Mean) (10-Day) 52-Week Low",
    "shortName": "10-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 10-day mean implied volatility."
  },
  {
    "id": "iv_mean_20_low_52_week",
    "name": "Implied Volatility (Mean) (20-Day) 52-Week Low",
    "shortName": "20-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 20-day mean implied volatility."
  },
  {
    "id": "iv_mean_30_low_52_week",
    "name": "Implied Volatility (Mean) (30-Day) 52-Week Low",
    "shortName": "30-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 30-day mean implied volatility."
  },
  {
    "id": "iv_mean_60_low_52_week",
    "name": "Implied Volatility (Mean) (60-Day) 52-Week Low",
    "shortName": "60-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 60-day mean implied volatility."
  },
  {
    "id": "iv_mean_90_low_52_week",
    "name": "Implied Volatility (Mean) (90-Day) 52-Week Low",
    "shortName": "90-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 90-day mean implied volatility."
  },
  {
    "id": "iv_mean_120_low_52_week",
    "name": "Implied Volatility (Mean) (120-Day) 52-Week Low",
    "shortName": "120-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 120-day mean implied volatility."
  },
  {
    "id": "iv_mean_150_low_52_week",
    "name": "Implied Volatility (Mean) (150-Day) 52-Week Low",
    "shortName": "150-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 150-day mean implied volatility."
  },
  {
    "id": "iv_mean_180_low_52_week",
    "name": "Implied Volatility (Mean) (180-Day) 52-Week Low",
    "shortName": "180-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 180-day mean implied volatility."
  },
  {
    "id": "iv_mean_270_low_52_week",
    "name": "Implied Volatility (Mean) (270-Day) 52-Week Low",
    "shortName": "270-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 270-day mean implied volatility."
  },
  {
    "id": "iv_mean_360_low_52_week",
    "name": "Implied Volatility (Mean) (360-Day) 52-Week Low",
    "shortName": "360-Day Mean IV 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 360-day mean implied volatility."
  },
  {
    "id": "iv_mean_10_high_52_week",
    "name": "Implied Volatility (Mean) (10-Day) 52-Week High",
    "shortName": "10-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 10-day mean implied volatility."
  },
  {
    "id": "iv_mean_20_high_52_week",
    "name": "Implied Volatility (Mean) (20-Day) 52-Week High",
    "shortName": "20-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 20-day mean implied volatility."
  },
  {
    "id": "iv_mean_30_high_52_week",
    "name": "Implied Volatility (Mean) (30-Day) 52-Week High",
    "shortName": "30-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 30-day mean implied volatility."
  },
  {
    "id": "iv_mean_60_high_52_week",
    "name": "Implied Volatility (Mean) (60-Day) 52-Week High",
    "shortName": "60-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 60-day mean implied volatility."
  },
  {
    "id": "iv_mean_90_high_52_week",
    "name": "Implied Volatility (Mean) (90-Day) 52-Week High",
    "shortName": "90-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 90-day mean implied volatility."
  },
  {
    "id": "iv_mean_120_high_52_week",
    "name": "Implied Volatility (Mean) (120-Day) 52-Week High",
    "shortName": "120-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 120-day mean implied volatility."
  },
  {
    "id": "iv_mean_150_high_52_week",
    "name": "Implied Volatility (Mean) (150-Day) 52-Week High",
    "shortName": "150-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 150-day mean implied volatility."
  },
  {
    "id": "iv_mean_180_high_52_week",
    "name": "Implied Volatility (Mean) (180-Day) 52-Week High",
    "shortName": "180-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 180-day mean implied volatility."
  },
  {
    "id": "iv_mean_270_high_52_week",
    "name": "Implied Volatility (Mean) (270-Day) 52-Week High",
    "shortName": "270-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 270-day mean implied volatility."
  },
  {
    "id": "iv_mean_360_high_52_week",
    "name": "Implied Volatility (Mean) (360-Day) 52-Week High",
    "shortName": "360-Day Mean IV 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 360-day mean implied volatility."
  },
  {
    "id": "iv_mean_10_rank_52_week",
    "name": "Implied Volatility (Mean) (10-Day) 52-Week Rank",
    "shortName": "10-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 10-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_20_rank_52_week",
    "name": "Implied Volatility (Mean) (20-Day) 52-Week Rank",
    "shortName": "20-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 20-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_30_rank_52_week",
    "name": "Implied Volatility (Mean) (30-Day) 52-Week Rank",
    "shortName": "30-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 30-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_60_rank_52_week",
    "name": "Implied Volatility (Mean) (60-Day) 52-Week Rank",
    "shortName": "60-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 60-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_90_rank_52_week",
    "name": "Implied Volatility (Mean) (90-Day) 52-Week Rank",
    "shortName": "90-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 90-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_120_rank_52_week",
    "name": "Implied Volatility (Mean) (120-Day) 52-Week Rank",
    "shortName": "120-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 120-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_150_rank_52_week",
    "name": "Implied Volatility (Mean) (150-Day) 52-Week Rank",
    "shortName": "150-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 150-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_180_rank_52_week",
    "name": "Implied Volatility (Mean) (180-Day) 52-Week Rank",
    "shortName": "180-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 180-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_270_rank_52_week",
    "name": "Implied Volatility (Mean) (270-Day) 52-Week Rank",
    "shortName": "270-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 270-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_360_rank_52_week",
    "name": "Implied Volatility (Mean) (360-Day) 52-Week Rank",
    "shortName": "360-Day Mean IV 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 360-day mean implied volatility, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_mean_10_percentile_52_week",
    "name": "Implied Volatility (Mean) (10-Day) 52-Week Percentile",
    "shortName": "10-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 10-day mean implied volatility."
  },
  {
    "id": "iv_mean_20_percentile_52_week",
    "name": "Implied Volatility (Mean) (20-Day) 52-Week Percentile",
    "shortName": "20-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 20-day mean implied volatility."
  },
  {
    "id": "iv_mean_30_percentile_52_week",
    "name": "Implied Volatility (Mean) (30-Day) 52-Week Percentile",
    "shortName": "30-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 30-day mean implied volatility."
  },
  {
    "id": "iv_mean_60_percentile_52_week",
    "name": "Implied Volatility (Mean) (60-Day) 52-Week Percentile",
    "shortName": "60-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 60-day mean implied volatility."
  },
  {
    "id": "iv_mean_90_percentile_52_week",
    "name": "Implied Volatility (Mean) (90-Day) 52-Week Percentile",
    "shortName": "90-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 90-day mean implied volatility."
  },
  {
    "id": "iv_mean_120_percentile_52_week",
    "name": "Implied Volatility (Mean) (120-Day) 52-Week Percentile",
    "shortName": "120-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 120-day mean implied volatility."
  },
  {
    "id": "iv_mean_150_percentile_52_week",
    "name": "Implied Volatility (Mean) (150-Day) 52-Week Percentile",
    "shortName": "150-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 150-day mean implied volatility."
  },
  {
    "id": "iv_mean_180_percentile_52_week",
    "name": "Implied Volatility (Mean) (180-Day) 52-Week Percentile",
    "shortName": "180-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 180-day mean implied volatility."
  },
  {
    "id": "iv_mean_270_percentile_52_week",
    "name": "Implied Volatility (Mean) (270-Day) 52-Week Percentile",
    "shortName": "270-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 270-day mean implied volatility."
  },
  {
    "id": "iv_mean_360_percentile_52_week",
    "name": "Implied Volatility (Mean) (360-Day) 52-Week Percentile",
    "shortName": "360-Day Mean IV 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 360-day mean implied volatility."
  },
  {
    "id": "iv_skew_10",
    "name": "Implied Volatility Skew (10-Day)",
    "shortName": "10-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 10-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_20",
    "name": "Implied Volatility Skew (20-Day)",
    "shortName": "20-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 20-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_30",
    "name": "Implied Volatility Skew (30-Day)",
    "shortName": "30-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 30-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_60",
    "name": "Implied Volatility Skew (60-Day)",
    "shortName": "60-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 60-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_90",
    "name": "Implied Volatility Skew (90-Day)",
    "shortName": "90-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 90-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_120",
    "name": "Implied Volatility Skew (120-Day)",
    "shortName": "120-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 120-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_150",
    "name": "Implied Volatility Skew (150-Day)",
    "shortName": "150-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 150-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_180",
    "name": "Implied Volatility Skew (180-Day)",
    "shortName": "180-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 180-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_270",
    "name": "Implied Volatility Skew (270-Day)",
    "shortName": "270-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 270-day options at lower and higher strike prices."
  },
  {
    "id": "iv_skew_360",
    "name": "Implied Volatility Skew (360-Day)",
    "shortName": "360-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 360-day options at lower and higher strike prices."
  },
  {
    "id": "previous_iv_skew_10",
    "name": "Previous Implied Volatility Skew (10-Day)",
    "shortName": "10-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 10-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_20",
    "name": "Previous Implied Volatility Skew (20-Day)",
    "shortName": "20-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 20-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_30",
    "name": "Previous Implied Volatility Skew (30-Day)",
    "shortName": "30-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 30-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_60",
    "name": "Previous Implied Volatility Skew (60-Day)",
    "shortName": "60-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 60-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_90",
    "name": "Previous Implied Volatility Skew (90-Day)",
    "shortName": "90-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 90-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_120",
    "name": "Previous Implied Volatility Skew (120-Day)",
    "shortName": "120-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 120-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_150",
    "name": "Previous Implied Volatility Skew (150-Day)",
    "shortName": "150-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 150-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_180",
    "name": "Previous Implied Volatility Skew (180-Day)",
    "shortName": "180-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 180-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_270",
    "name": "Previous Implied Volatility Skew (270-Day)",
    "shortName": "270-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 270-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "previous_iv_skew_360",
    "name": "Previous Implied Volatility Skew (360-Day)",
    "shortName": "360-Day IV Skew",
    "category": "Implied Volatility",
    "description": "Measure of the difference in implied volatility of 360-day options at lower and higher strike prices, on the trading day before the last trading day."
  },
  {
    "id": "iv_skew_10_low_52_week",
    "name": "Implied Volatility Skew (10-Day) 52-Week Low",
    "shortName": "10-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 10-day implied volatility skew."
  },
  {
    "id": "iv_skew_20_low_52_week",
    "name": "Implied Volatility Skew (20-Day) 52-Week Low",
    "shortName": "20-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 20-day implied volatility skew."
  },
  {
    "id": "iv_skew_30_low_52_week",
    "name": "Implied Volatility Skew (30-Day) 52-Week Low",
    "shortName": "30-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 30-day implied volatility skew."
  },
  {
    "id": "iv_skew_60_low_52_week",
    "name": "Implied Volatility Skew (60-Day) 52-Week Low",
    "shortName": "60-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 60-day implied volatility skew."
  },
  {
    "id": "iv_skew_90_low_52_week",
    "name": "Implied Volatility Skew (90-Day) 52-Week Low",
    "shortName": "90-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 90-day implied volatility skew."
  },
  {
    "id": "iv_skew_120_low_52_week",
    "name": "Implied Volatility Skew (120-Day) 52-Week Low",
    "shortName": "120-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 120-day implied volatility skew."
  },
  {
    "id": "iv_skew_150_low_52_week",
    "name": "Implied Volatility Skew (150-Day) 52-Week Low",
    "shortName": "150-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 150-day implied volatility skew."
  },
  {
    "id": "iv_skew_180_low_52_week",
    "name": "Implied Volatility Skew (180-Day) 52-Week Low",
    "shortName": "180-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 180-day implied volatility skew."
  },
  {
    "id": "iv_skew_270_low_52_week",
    "name": "Implied Volatility Skew (270-Day) 52-Week Low",
    "shortName": "270-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 270-day implied volatility skew."
  },
  {
    "id": "iv_skew_360_low_52_week",
    "name": "Implied Volatility Skew (360-Day) 52-Week Low",
    "shortName": "360-Day IV Skew 52-Week Low",
    "category": "Implied Volatility",
    "description": "52-week low of the security's 360-day implied volatility skew."
  },
  {
    "id": "iv_skew_10_high_52_week",
    "name": "Implied Volatility Skew (10-Day) 52-Week High",
    "shortName": "10-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 10-day implied volatility skew."
  },
  {
    "id": "iv_skew_20_high_52_week",
    "name": "Implied Volatility Skew (20-Day) 52-Week High",
    "shortName": "20-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 20-day implied volatility skew."
  },
  {
    "id": "iv_skew_30_high_52_week",
    "name": "Implied Volatility Skew (30-Day) 52-Week High",
    "shortName": "30-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 30-day implied volatility skew."
  },
  {
    "id": "iv_skew_60_high_52_week",
    "name": "Implied Volatility Skew (60-Day) 52-Week High",
    "shortName": "60-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 60-day implied volatility skew."
  },
  {
    "id": "iv_skew_90_high_52_week",
    "name": "Implied Volatility Skew (90-Day) 52-Week High",
    "shortName": "90-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 90-day implied volatility skew."
  },
  {
    "id": "iv_skew_120_high_52_week",
    "name": "Implied Volatility Skew (120-Day) 52-Week High",
    "shortName": "120-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 120-day implied volatility skew."
  },
  {
    "id": "iv_skew_150_high_52_week",
    "name": "Implied Volatility Skew (150-Day) 52-Week High",
    "shortName": "150-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 150-day implied volatility skew."
  },
  {
    "id": "iv_skew_180_high_52_week",
    "name": "Implied Volatility Skew (180-Day) 52-Week High",
    "shortName": "180-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 180-day implied volatility skew."
  },
  {
    "id": "iv_skew_270_high_52_week",
    "name": "Implied Volatility Skew (270-Day) 52-Week High",
    "shortName": "270-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 270-day implied volatility skew."
  },
  {
    "id": "iv_skew_360_high_52_week",
    "name": "Implied Volatility Skew (360-Day) 52-Week High",
    "shortName": "360-Day IV Skew 52-Week High",
    "category": "Implied Volatility",
    "description": "52-week high of the security's 360-day implied volatility skew."
  },
  {
    "id": "iv_skew_10_rank_52_week",
    "name": "Implied Volatility Skew (10-Day) 52-Week Rank",
    "shortName": "10-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 10-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_20_rank_52_week",
    "name": "Implied Volatility Skew (20-Day) 52-Week Rank",
    "shortName": "20-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 20-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_30_rank_52_week",
    "name": "Implied Volatility Skew (30-Day) 52-Week Rank",
    "shortName": "30-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 30-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_60_rank_52_week",
    "name": "Implied Volatility Skew (60-Day) 52-Week Rank",
    "shortName": "60-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 60-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_90_rank_52_week",
    "name": "Implied Volatility Skew (90-Day) 52-Week Rank",
    "shortName": "90-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 90-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_120_rank_52_week",
    "name": "Implied Volatility Skew (120-Day) 52-Week Rank",
    "shortName": "120-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 120-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_150_rank_52_week",
    "name": "Implied Volatility Skew (150-Day) 52-Week Rank",
    "shortName": "150-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 150-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_180_rank_52_week",
    "name": "Implied Volatility Skew (180-Day) 52-Week Rank",
    "shortName": "180-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 180-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_270_rank_52_week",
    "name": "Implied Volatility Skew (270-Day) 52-Week Rank",
    "shortName": "270-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 270-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_360_rank_52_week",
    "name": "Implied Volatility Skew (360-Day) 52-Week Rank",
    "shortName": "360-Day IV Skew 52-Week Rank",
    "category": "Implied Volatility",
    "description": "52-week rank of the security's 360-day implied volatility skew, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "iv_skew_10_percentile_52_week",
    "name": "Implied Volatility Skew (10-Day) 52-Week Percentile",
    "shortName": "10-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 10-day implied volatility skew."
  },
  {
    "id": "iv_skew_20_percentile_52_week",
    "name": "Implied Volatility Skew (20-Day) 52-Week Percentile",
    "shortName": "20-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 20-day implied volatility skew."
  },
  {
    "id": "iv_skew_30_percentile_52_week",
    "name": "Implied Volatility Skew (30-Day) 52-Week Percentile",
    "shortName": "30-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 30-day implied volatility skew."
  },
  {
    "id": "iv_skew_60_percentile_52_week",
    "name": "Implied Volatility Skew (60-Day) 52-Week Percentile",
    "shortName": "60-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 60-day implied volatility skew."
  },
  {
    "id": "iv_skew_90_percentile_52_week",
    "name": "Implied Volatility Skew (90-Day) 52-Week Percentile",
    "shortName": "90-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 90-day implied volatility skew."
  },
  {
    "id": "iv_skew_120_percentile_52_week",
    "name": "Implied Volatility Skew (120-Day) 52-Week Percentile",
    "shortName": "120-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 120-day implied volatility skew."
  },
  {
    "id": "iv_skew_150_percentile_52_week",
    "name": "Implied Volatility Skew (150-Day) 52-Week Percentile",
    "shortName": "150-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 150-day implied volatility skew."
  },
  {
    "id": "iv_skew_180_percentile_52_week",
    "name": "Implied Volatility Skew (180-Day) 52-Week Percentile",
    "shortName": "180-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 180-day implied volatility skew."
  },
  {
    "id": "iv_skew_270_percentile_52_week",
    "name": "Implied Volatility Skew (270-Day) 52-Week Percentile",
    "shortName": "270-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 270-day implied volatility skew."
  },
  {
    "id": "iv_skew_360_percentile_52_week",
    "name": "Implied Volatility Skew (360-Day) 52-Week Percentile",
    "shortName": "360-Day IV Skew 52-Week Percentile",
    "category": "Implied Volatility",
    "description": "52-week percentile of the security's 360-day implied volatility skew."
  },
  {
    "id": "pcr_open_interest_10",
    "name": "Put-Call Ratio (Open Interest) (10-Day)",
    "shortName": "10-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 10 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_20",
    "name": "Put-Call Ratio (Open Interest) (20-Day)",
    "shortName": "20-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 20 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_30",
    "name": "Put-Call Ratio (Open Interest) (30-Day)",
    "shortName": "30-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 30 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_60",
    "name": "Put-Call Ratio (Open Interest) (60-Day)",
    "shortName": "60-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 60 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_90",
    "name": "Put-Call Ratio (Open Interest) (90-Day)",
    "shortName": "90-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 90 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_120",
    "name": "Put-Call Ratio (Open Interest) (120-Day)",
    "shortName": "120-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 120 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_150",
    "name": "Put-Call Ratio (Open Interest) (150-Day)",
    "shortName": "150-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 150 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_180",
    "name": "Put-Call Ratio (Open Interest) (180-Day)",
    "shortName": "180-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 180 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_270",
    "name": "Put-Call Ratio (Open Interest) (270-Day)",
    "shortName": "270-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 270 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_open_interest_360",
    "name": "Put-Call Ratio (Open Interest) (360-Day)",
    "shortName": "360-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 360 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "previous_pcr_open_interest_10",
    "name": "Previous Put-Call Ratio (Open Interest) (10-Day)",
    "shortName": "10-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 10 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_20",
    "name": "Previous Put-Call Ratio (Open Interest) (20-Day)",
    "shortName": "20-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 20 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_30",
    "name": "Previous Put-Call Ratio (Open Interest) (30-Day)",
    "shortName": "30-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 30 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_60",
    "name": "Previous Put-Call Ratio (Open Interest) (60-Day)",
    "shortName": "60-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 60 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_90",
    "name": "Previous Put-Call Ratio (Open Interest) (90-Day)",
    "shortName": "90-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 90 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_120",
    "name": "Previous Put-Call Ratio (Open Interest) (120-Day)",
    "shortName": "120-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 120 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_150",
    "name": "Previous Put-Call Ratio (Open Interest) (150-Day)",
    "shortName": "150-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 150 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_180",
    "name": "Previous Put-Call Ratio (Open Interest) (180-Day)",
    "shortName": "180-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 180 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_270",
    "name": "Previous Put-Call Ratio (Open Interest) (270-Day)",
    "shortName": "270-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 270 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_open_interest_360",
    "name": "Previous Put-Call Ratio (Open Interest) (360-Day)",
    "shortName": "360-Day PCR (Open Interest)",
    "category": "Put-Call Ratio",
    "description": "Ratio of outstanding put contracts to outstanding call contracts for options expiring in 360 calendar days, derived from the open interest of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "pcr_open_interest_10_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (10-Day) 52-Week Low",
    "shortName": "10-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 10-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_20_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (20-Day) 52-Week Low",
    "shortName": "20-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 20-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_30_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (30-Day) 52-Week Low",
    "shortName": "30-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 30-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_60_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (60-Day) 52-Week Low",
    "shortName": "60-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 60-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_90_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (90-Day) 52-Week Low",
    "shortName": "90-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 90-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_120_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (120-Day) 52-Week Low",
    "shortName": "120-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 120-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_150_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (150-Day) 52-Week Low",
    "shortName": "150-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 150-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_180_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (180-Day) 52-Week Low",
    "shortName": "180-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 180-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_270_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (270-Day) 52-Week Low",
    "shortName": "270-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 270-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_360_low_52_week",
    "name": "Put-Call Ratio (Open Interest) (360-Day) 52-Week Low",
    "shortName": "360-Day PCR (OI) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 360-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_10_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (10-Day) 52-Week High",
    "shortName": "10-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 10-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_20_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (20-Day) 52-Week High",
    "shortName": "20-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 20-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_30_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (30-Day) 52-Week High",
    "shortName": "30-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 30-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_60_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (60-Day) 52-Week High",
    "shortName": "60-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 60-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_90_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (90-Day) 52-Week High",
    "shortName": "90-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 90-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_120_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (120-Day) 52-Week High",
    "shortName": "120-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 120-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_150_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (150-Day) 52-Week High",
    "shortName": "150-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 150-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_180_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (180-Day) 52-Week High",
    "shortName": "180-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 180-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_270_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (270-Day) 52-Week High",
    "shortName": "270-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 270-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_360_high_52_week",
    "name": "Put-Call Ratio (Open Interest) (360-Day) 52-Week High",
    "shortName": "360-Day PCR (OI) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 360-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_10_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (10-Day) 52-Week Rank",
    "shortName": "10-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 10-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_20_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (20-Day) 52-Week Rank",
    "shortName": "20-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 20-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_30_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (30-Day) 52-Week Rank",
    "shortName": "30-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 30-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_60_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (60-Day) 52-Week Rank",
    "shortName": "60-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 60-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_90_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (90-Day) 52-Week Rank",
    "shortName": "90-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 90-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_120_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (120-Day) 52-Week Rank",
    "shortName": "120-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 120-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_150_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (150-Day) 52-Week Rank",
    "shortName": "150-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 150-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_180_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (180-Day) 52-Week Rank",
    "shortName": "180-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 180-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_270_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (270-Day) 52-Week Rank",
    "shortName": "270-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 270-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_360_rank_52_week",
    "name": "Put-Call Ratio (Open Interest) (360-Day) 52-Week Rank",
    "shortName": "360-Day PCR (OI) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 360-day option put-call ratio by open interest, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_open_interest_10_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (10-Day) 52-Week Percentile",
    "shortName": "10-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 10-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_20_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (20-Day) 52-Week Percentile",
    "shortName": "20-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 20-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_30_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (30-Day) 52-Week Percentile",
    "shortName": "30-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 30-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_60_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (60-Day) 52-Week Percentile",
    "shortName": "60-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 60-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_90_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (90-Day) 52-Week Percentile",
    "shortName": "90-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 90-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_120_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (120-Day) 52-Week Percentile",
    "shortName": "120-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 120-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_150_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (150-Day) 52-Week Percentile",
    "shortName": "150-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 150-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_180_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (180-Day) 52-Week Percentile",
    "shortName": "180-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 180-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_270_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (270-Day) 52-Week Percentile",
    "shortName": "270-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 270-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_open_interest_360_percentile_52_week",
    "name": "Put-Call Ratio (Open Interest) (360-Day) 52-Week Percentile",
    "shortName": "360-Day PCR (OI) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 360-day option put-call ratio by open interest."
  },
  {
    "id": "pcr_volume_10",
    "name": "Put-Call Ratio (Volume) (10-Day)",
    "shortName": "10-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 10 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_20",
    "name": "Put-Call Ratio (Volume) (20-Day)",
    "shortName": "20-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 20 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_30",
    "name": "Put-Call Ratio (Volume) (30-Day)",
    "shortName": "30-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 30 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_60",
    "name": "Put-Call Ratio (Volume) (60-Day)",
    "shortName": "60-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 60 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_90",
    "name": "Put-Call Ratio (Volume) (90-Day)",
    "shortName": "90-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 90 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_120",
    "name": "Put-Call Ratio (Volume) (120-Day)",
    "shortName": "120-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 120 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_150",
    "name": "Put-Call Ratio (Volume) (150-Day)",
    "shortName": "150-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 150 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_180",
    "name": "Put-Call Ratio (Volume) (180-Day)",
    "shortName": "180-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 180 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_270",
    "name": "Put-Call Ratio (Volume) (270-Day)",
    "shortName": "270-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 270 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "pcr_volume_360",
    "name": "Put-Call Ratio (Volume) (360-Day)",
    "shortName": "360-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 360 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date."
  },
  {
    "id": "previous_pcr_volume_10",
    "name": "Previous Put-Call Ratio (Volume) (10-Day)",
    "shortName": "10-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 10 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_20",
    "name": "Previous Put-Call Ratio (Volume) (20-Day)",
    "shortName": "20-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 20 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_30",
    "name": "Previous Put-Call Ratio (Volume) (30-Day)",
    "shortName": "30-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 30 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_60",
    "name": "Previous Put-Call Ratio (Volume) (60-Day)",
    "shortName": "60-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 60 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_90",
    "name": "Previous Put-Call Ratio (Volume) (90-Day)",
    "shortName": "90-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 90 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_120",
    "name": "Previous Put-Call Ratio (Volume) (120-Day)",
    "shortName": "120-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 120 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_150",
    "name": "Previous Put-Call Ratio (Volume) (150-Day)",
    "shortName": "150-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 150 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_180",
    "name": "Previous Put-Call Ratio (Volume) (180-Day)",
    "shortName": "180-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 180 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_270",
    "name": "Previous Put-Call Ratio (Volume) (270-Day)",
    "shortName": "270-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 270 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "previous_pcr_volume_360",
    "name": "Previous Put-Call Ratio (Volume) (360-Day)",
    "shortName": "360-Day PCR (Volume)",
    "category": "Put-Call Ratio",
    "description": "Ratio of puts traded to calls traded for options expiring in 360 calendar days, derived from the volume of the at-the-money options with expiration dates closest to the target date, on the trading day before the last trading day."
  },
  {
    "id": "pcr_volume_10_low_52_week",
    "name": "Put-Call Ratio (Volume) (10-Day) 52-Week Low",
    "shortName": "10-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 10-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_20_low_52_week",
    "name": "Put-Call Ratio (Volume) (20-Day) 52-Week Low",
    "shortName": "20-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 20-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_30_low_52_week",
    "name": "Put-Call Ratio (Volume) (30-Day) 52-Week Low",
    "shortName": "30-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 30-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_60_low_52_week",
    "name": "Put-Call Ratio (Volume) (60-Day) 52-Week Low",
    "shortName": "60-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 60-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_90_low_52_week",
    "name": "Put-Call Ratio (Volume) (90-Day) 52-Week Low",
    "shortName": "90-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 90-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_120_low_52_week",
    "name": "Put-Call Ratio (Volume) (120-Day) 52-Week Low",
    "shortName": "120-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 120-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_150_low_52_week",
    "name": "Put-Call Ratio (Volume) (150-Day) 52-Week Low",
    "shortName": "150-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 150-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_180_low_52_week",
    "name": "Put-Call Ratio (Volume) (180-Day) 52-Week Low",
    "shortName": "180-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 180-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_270_low_52_week",
    "name": "Put-Call Ratio (Volume) (270-Day) 52-Week Low",
    "shortName": "270-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 270-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_360_low_52_week",
    "name": "Put-Call Ratio (Volume) (360-Day) 52-Week Low",
    "shortName": "360-Day PCR (Vol) 52-Week Low",
    "category": "Put-Call Ratio",
    "description": "52-week low of the security's 360-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_10_high_52_week",
    "name": "Put-Call Ratio (Volume) (10-Day) 52-Week High",
    "shortName": "10-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 10-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_20_high_52_week",
    "name": "Put-Call Ratio (Volume) (20-Day) 52-Week High",
    "shortName": "20-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 20-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_30_high_52_week",
    "name": "Put-Call Ratio (Volume) (30-Day) 52-Week High",
    "shortName": "30-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 30-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_60_high_52_week",
    "name": "Put-Call Ratio (Volume) (60-Day) 52-Week High",
    "shortName": "60-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 60-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_90_high_52_week",
    "name": "Put-Call Ratio (Volume) (90-Day) 52-Week High",
    "shortName": "90-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 90-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_120_high_52_week",
    "name": "Put-Call Ratio (Volume) (120-Day) 52-Week High",
    "shortName": "120-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 120-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_150_high_52_week",
    "name": "Put-Call Ratio (Volume) (150-Day) 52-Week High",
    "shortName": "150-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 150-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_180_high_52_week",
    "name": "Put-Call Ratio (Volume) (180-Day) 52-Week High",
    "shortName": "180-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 180-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_270_high_52_week",
    "name": "Put-Call Ratio (Volume) (270-Day) 52-Week High",
    "shortName": "270-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 270-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_360_high_52_week",
    "name": "Put-Call Ratio (Volume) (360-Day) 52-Week High",
    "shortName": "360-Day PCR (Vol) 52-Week High",
    "category": "Put-Call Ratio",
    "description": "52-week high of the security's 360-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_10_rank_52_week",
    "name": "Put-Call Ratio (Volume) (10-Day) 52-Week Rank",
    "shortName": "10-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 10-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_20_rank_52_week",
    "name": "Put-Call Ratio (Volume) (20-Day) 52-Week Rank",
    "shortName": "20-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 20-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_30_rank_52_week",
    "name": "Put-Call Ratio (Volume) (30-Day) 52-Week Rank",
    "shortName": "30-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 30-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_60_rank_52_week",
    "name": "Put-Call Ratio (Volume) (60-Day) 52-Week Rank",
    "shortName": "60-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 60-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_90_rank_52_week",
    "name": "Put-Call Ratio (Volume) (90-Day) 52-Week Rank",
    "shortName": "90-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 90-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_120_rank_52_week",
    "name": "Put-Call Ratio (Volume) (120-Day) 52-Week Rank",
    "shortName": "120-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 120-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_150_rank_52_week",
    "name": "Put-Call Ratio (Volume) (150-Day) 52-Week Rank",
    "shortName": "150-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 150-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_180_rank_52_week",
    "name": "Put-Call Ratio (Volume) (180-Day) 52-Week Rank",
    "shortName": "180-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 180-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_270_rank_52_week",
    "name": "Put-Call Ratio (Volume) (270-Day) 52-Week Rank",
    "shortName": "270-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 270-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_360_rank_52_week",
    "name": "Put-Call Ratio (Volume) (360-Day) 52-Week Rank",
    "shortName": "360-Day PCR (Vol) 52-Week Rank",
    "category": "Put-Call Ratio",
    "description": "52-week rank of the security's 360-day option put-call ratio by volume, where rank = (value - low)*100 / (high - low)."
  },
  {
    "id": "pcr_volume_10_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (10-Day) 52-Week Percentile",
    "shortName": "10-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 10-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_20_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (20-Day) 52-Week Percentile",
    "shortName": "20-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 20-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_30_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (30-Day) 52-Week Percentile",
    "shortName": "30-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 30-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_60_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (60-Day) 52-Week Percentile",
    "shortName": "60-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 60-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_90_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (90-Day) 52-Week Percentile",
    "shortName": "90-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 90-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_120_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (120-Day) 52-Week Percentile",
    "shortName": "120-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 120-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_150_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (150-Day) 52-Week Percentile",
    "shortName": "150-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 150-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_180_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (180-Day) 52-Week Percentile",
    "shortName": "180-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 180-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_270_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (270-Day) 52-Week Percentile",
    "shortName": "270-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 270-day option put-call ratio by volume."
  },
  {
    "id": "pcr_volume_360_percentile_52_week",
    "name": "Put-Call Ratio (Volume) (360-Day) 52-Week Percentile",
    "shortName": "360-Day PCR (Vol) 52-Week Percentile",
    "category": "Put-Call Ratio",
    "description": "52-week percentile of the security's 360-day option put-call ratio by volume."
  },
  {
    "id": "revenue_mry",
    "name": "Revenue (Most Recent Fiscal Year)",
    "shortName": "Revenue (MRY)",
    "category": "Income Statement Financials",
    "description": "Amount of money earned from the company's sales of goods and services (most recent fiscal year)."
  },
  {
    "id": "revenue_ttm",
    "name": "Revenue (Trailing 12 Months)",
    "shortName": "Revenue (TTM)",
    "category": "Income Statement Financials",
    "description": "Amount of money earned from the company's sales of goods and services (trailing 12 months)."
  },
  {
    "id": "revenue_mrq",
    "name": "Revenue (Most Recent Fiscal Quarter)",
    "shortName": "Revenue (MRQ)",
    "category": "Income Statement Financials",
    "description": "Amount of money earned from the company's sales of goods and services (most recent fiscal quarter)."
  },
  {
    "id": "gross_profit_mry",
    "name": "Gross Profit (Most Recent Fiscal Year)",
    "shortName": "Gross Profit (MRY)",
    "category": "Income Statement Financials",
    "description": "Profit earned after deducting the cost of producing and selling the company's products and services from revenue (most recent fiscal year)."
  },
  {
    "id": "gross_profit_ttm",
    "name": "Gross Profit (Trailing 12 Months)",
    "shortName": "Gross Profit (TTM)",
    "category": "Income Statement Financials",
    "description": "Profit earned after deducting the cost of producing and selling the company's products and services from revenue (trailing 12 months)."
  },
  {
    "id": "gross_profit_mrq",
    "name": "Gross Profit (Most Recent Fiscal Quarter)",
    "shortName": "Gross Profit (MRQ)",
    "category": "Income Statement Financials",
    "description": "Profit earned after deducting the cost of producing and selling the company's products and services from revenue (most recent fiscal quarter)."
  },
  {
    "id": "operating_income_mry",
    "name": "Operating Income (Most Recent Fiscal Year)",
    "shortName": "Operating Income (MRY)",
    "category": "Income Statement Financials",
    "description": "Profit earned after deducting both cost of revenue and operating expenses from revenue (most recent fiscal year)."
  },
  {
    "id": "operating_income_ttm",
    "name": "Operating Income (Trailing 12 Months)",
    "shortName": "Operating Income (TTM)",
    "category": "Income Statement Financials",
    "description": "Profit earned after deducting both cost of revenue and operating expenses from revenue (trailing 12 months)."
  },
  {
    "id": "operating_income_mrq",
    "name": "Operating Income (Most Recent Fiscal Quarter)",
    "shortName": "Operating Income (MRQ)",
    "category": "Income Statement Financials",
    "description": "Profit earned after deducting both cost of revenue and operating expenses from revenue (most recent fiscal quarter)."
  },
  {
    "id": "ebitda_mry",
    "name": "Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA) (Most Recent Fiscal Year)",
    "shortName": "EBITDA (MRY)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of interest, taxes, depreciation, and amortization (most recent fiscal year)."
  },
  {
    "id": "ebitda_ttm",
    "name": "Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA) (Trailing 12 Months)",
    "shortName": "EBITDA (TTM)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of interest, taxes, depreciation, and amortization (trailing 12 months)."
  },
  {
    "id": "ebitda_mrq",
    "name": "Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA) (Most Recent Fiscal Quarter)",
    "shortName": "EBITDA (MRQ)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of interest, taxes, depreciation, and amortization (most recent fiscal quarter)."
  },
  {
    "id": "ebit_mry",
    "name": "Earnings Before Interest and Taxes (EBIT) (Most Recent Fiscal Year)",
    "shortName": "EBIT (MRY)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of interest and taxes (most recent fiscal year)."
  },
  {
    "id": "ebit_ttm",
    "name": "Earnings Before Interest and Taxes (EBIT) (Trailing 12 Months)",
    "shortName": "EBIT (TTM)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of interest and taxes (trailing 12 months)."
  },
  {
    "id": "ebit_mrq",
    "name": "Earnings Before Interest and Taxes (EBIT) (Most Recent Fiscal Quarter)",
    "shortName": "EBIT (MRQ)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of interest and taxes (most recent fiscal quarter)."
  },
  {
    "id": "ebt_mry",
    "name": "Earnings Before Taxes (EBT) (Most Recent Fiscal Year)",
    "shortName": "EBT (MRY)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of taxes (most recent fiscal year)."
  },
  {
    "id": "ebt_ttm",
    "name": "Earnings Before Taxes (EBT) (Trailing 12 Months)",
    "shortName": "EBT (TTM)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of taxes (trailing 12 months)."
  },
  {
    "id": "ebt_mrq",
    "name": "Earnings Before Taxes (EBT) (Most Recent Fiscal Quarter)",
    "shortName": "EBT (MRQ)",
    "category": "Income Statement Financials",
    "description": "Company's income before deduction of taxes (most recent fiscal quarter)."
  },
  {
    "id": "consolidated_net_income_mry",
    "name": "Consolidated Net Income (Most Recent Fiscal Year)",
    "shortName": "Consolidated Net Income (MRY)",
    "category": "Income Statement Financials",
    "description": "Combined earnings of the parent company and its subsidiaries, net of income taxes (most recent fiscal year)."
  },
  {
    "id": "consolidated_net_income_ttm",
    "name": "Consolidated Net Income (Trailing 12 Months)",
    "shortName": "Consolidated Net Income (TTM)",
    "category": "Income Statement Financials",
    "description": "Combined earnings of the parent company and its subsidiaries, net of income taxes (trailing 12 months)."
  },
  {
    "id": "consolidated_net_income_mrq",
    "name": "Consolidated Net Income (Most Recent Fiscal Quarter)",
    "shortName": "Consolidated Net Income (MRQ)",
    "category": "Income Statement Financials",
    "description": "Combined earnings of the parent company and its subsidiaries, net of income taxes (most recent fiscal quarter)."
  },
  {
    "id": "net_income_mry",
    "name": "Net Income (Most Recent Fiscal Year)",
    "shortName": "Net Income (MRY)",
    "category": "Income Statement Financials",
    "description": "Net profit of the company after all revenues and expenses have been accounted for, after deduction of preferred dividends and net income to non-controlling interests; these are the earnings allotted to common shareholders (most recent fiscal year)."
  },
  {
    "id": "net_income_ttm",
    "name": "Net Income (Trailing 12 Months)",
    "shortName": "Net Income (TTM)",
    "category": "Income Statement Financials",
    "description": "Net profit of the company after all revenues and expenses have been accounted for, after deduction of preferred dividends and net income to non-controlling interests; these are the earnings allotted to common shareholders (trailing 12 months)."
  },
  {
    "id": "net_income_mrq",
    "name": "Net Income (Most Recent Fiscal Quarter)",
    "shortName": "Net Income (MRQ)",
    "category": "Income Statement Financials",
    "description": "Net profit of the company after all revenues and expenses have been accounted for, after deduction of preferred dividends and net income to non-controlling interests; these are the earnings allotted to common shareholders (most recent fiscal quarter)."
  },
  {
    "id": "eps_basic_mry",
    "name": "Basic Earnings per Share (EPS) (Most Recent Fiscal Year)",
    "shortName": "Basic EPS (MRY)",
    "category": "Income Statement Financials",
    "description": "Amount of net income allotted to each outstanding share of common stock (most recent fiscal year)."
  },
  {
    "id": "eps_basic_ttm",
    "name": "Basic Earnings per Share (EPS) (Trailing 12 Months)",
    "shortName": "Basic EPS (TTM)",
    "category": "Income Statement Financials",
    "description": "Amount of net income allotted to each outstanding share of common stock (trailing 12 months)."
  },
  {
    "id": "eps_basic_mrq",
    "name": "Basic Earnings per Share (EPS) (Most Recent Fiscal Quarter)",
    "shortName": "Basic Earnings EPS (MRQ)",
    "category": "Income Statement Financials",
    "description": "Amount of net income allotted to each outstanding share of common stock (most recent fiscal quarter)."
  },
  {
    "id": "eps_diluted_mry",
    "name": "Diluted Earnings per Share (EPS) (Most Recent Fiscal Year)",
    "shortName": "Diluted EPS (MRY)",
    "category": "Income Statement Financials",
    "description": "Amount of net income allotted to each share of common stock, accounting for the potential dilution effect of convertible securities such as stock options or convertible bonds (most recent fiscal year)."
  },
  {
    "id": "eps_diluted_ttm",
    "name": "Diluted Earnings per Share (EPS) (Trailing 12 Months)",
    "shortName": "Diluted EPS (TTM)",
    "category": "Income Statement Financials",
    "description": "Amount of net income allotted to each share of common stock, accounting for the potential dilution effect of convertible securities such as stock options or convertible bonds (trailing 12 months)."
  },
  {
    "id": "eps_diluted_mrq",
    "name": "Diluted Earnings per Share (EPS) (Most Recent Fiscal Quarter)",
    "shortName": "Diluted Earnings EPS (MRQ)",
    "category": "Income Statement Financials",
    "description": "Amount of net income allotted to each share of common stock, accounting for the potential dilution effect of convertible securities such as stock options or convertible bonds (most recent fiscal quarter)."
  },
  {
    "id": "cost_of_revenue_mry",
    "name": "Cost of Revenue (Most Recent Fiscal Year)",
    "shortName": "Cost of Revenue (MRY)",
    "category": "Income Statement Financials",
    "description": "Total cost of producing and selling the company's products and services (most recent fiscal year)."
  },
  {
    "id": "cost_of_revenue_ttm",
    "name": "Cost of Revenue (Trailing 12 Months)",
    "shortName": "Cost of Revenue (TTM)",
    "category": "Income Statement Financials",
    "description": "Total cost of producing and selling the company's products and services (trailing 12 months)."
  },
  {
    "id": "cost_of_revenue_mrq",
    "name": "Cost of Revenue (Most Recent Fiscal Quarter)",
    "shortName": "Cost of Revenue (MRQ)",
    "category": "Income Statement Financials",
    "description": "Total cost of producing and selling the company's products and services (most recent fiscal quarter)."
  },
  {
    "id": "operating_expenses_mry",
    "name": "Operating Expenses (Most Recent Fiscal Year)",
    "shortName": "Operating Expenses (MRY)",
    "category": "Income Statement Financials",
    "description": "Costs incurred through the company's normal business operations, not directly tied to the production and sale of products and services (most recent fiscal year)."
  },
  {
    "id": "operating_expenses_ttm",
    "name": "Operating Expenses (Trailing 12 Months)",
    "shortName": "Operating Expenses (TTM)",
    "category": "Income Statement Financials",
    "description": "Costs incurred through the company's normal business operations, not directly tied to the production and sale of products and services (trailing 12 months)."
  },
  {
    "id": "operating_expenses_mrq",
    "name": "Operating Expenses (Most Recent Fiscal Quarter)",
    "shortName": "Operating Expenses (MRQ)",
    "category": "Income Statement Financials",
    "description": "Costs incurred through the company's normal business operations, not directly tied to the production and sale of products and services (most recent fiscal quarter)."
  },
  {
    "id": "selling_general_admin_expense_mry",
    "name": "Selling, General, and Administrative Expense (SG\u0026A) (Most Recent Fiscal Year)",
    "shortName": "SG\u0026A Expense (MRY)",
    "category": "Income Statement Financials",
    "description": "Part of the company's operating expenses related to the selling of products and services, such as advertising and marketing, as well as other general costs such as salaries and office rent (most recent fiscal year)."
  },
  {
    "id": "selling_general_admin_expense_ttm",
    "name": "Selling, General, and Administrative Expense (SG\u0026A) (Trailing 12 Months)",
    "shortName": "SG\u0026A Expense (TTM)",
    "category": "Income Statement Financials",
    "description": "Part of the company's operating expenses related to the selling of products and services, such as advertising and marketing, as well as other general costs such as salaries and office rent (trailing 12 months)."
  },
  {
    "id": "selling_general_admin_expense_mrq",
    "name": "Selling, General, and Administrative Expense (SG\u0026A) (Most Recent Fiscal Quarter)",
    "shortName": "SG\u0026A Expense (MRQ)",
    "category": "Income Statement Financials",
    "description": "Part of the company's operating expenses related to the selling of products and services, such as advertising and marketing, as well as other general costs such as salaries and office rent (most recent fiscal quarter)."
  },
  {
    "id": "research_development_expense_mry",
    "name": "Research and Development Expense (R\u0026D) (Most Recent Fiscal Year)",
    "shortName": "R\u0026D Expense (MRY)",
    "category": "Income Statement Financials",
    "description": "Part of the company's operating expenses incurred in the process of developing new products and services (most recent fiscal year)."
  },
  {
    "id": "research_development_expense_ttm",
    "name": "Research and Development Expense (R\u0026D) (Trailing 12 Months)",
    "shortName": "R\u0026D Expense (TTM)",
    "category": "Income Statement Financials",
    "description": "Part of the company's operating expenses incurred in the process of developing new products and services (trailing 12 months)."
  },
  {
    "id": "research_development_expense_mrq",
    "name": "Research and Development Expense (R\u0026D) (Most Recent Fiscal Quarter)",
    "shortName": "R\u0026D Expense (MRQ)",
    "category": "Income Statement Financials",
    "description": "Part of the company's operating expenses incurred in the process of developing new products and services (most recent fiscal quarter)."
  },
  {
    "id": "interest_expense_mry",
    "name": "Interest Expense (Most Recent Fiscal Year)",
    "shortName": "Interest Expense (MRY)",
    "category": "Income Statement Financials",
    "description": "Cost of the company's borrowed funds (most recent fiscal year)."
  },
  {
    "id": "interest_expense_ttm",
    "name": "Interest Expense (Trailing 12 Months)",
    "shortName": "Interest Expense (TTM)",
    "category": "Income Statement Financials",
    "description": "Cost of the company's borrowed funds (trailing 12 months)."
  },
  {
    "id": "interest_expense_mrq",
    "name": "Interest Expense (Most Recent Fiscal Quarter)",
    "shortName": "Interest Expense (MRQ)",
    "category": "Income Statement Financials",
    "description": "Cost of the company's borrowed funds (most recent fiscal quarter)."
  },
  {
    "id": "income_tax_expense_mry",
    "name": "Income Tax Expense (Most Recent Fiscal Year)",
    "shortName": "Income Tax Expense (MRY)",
    "category": "Income Statement Financials",
    "description": "Tax owned by the company on its income (most recent fiscal year)."
  },
  {
    "id": "income_tax_expense_ttm",
    "name": "Income Tax Expense (Trailing 12 Months)",
    "shortName": "Income Tax Expense (TTM)",
    "category": "Income Statement Financials",
    "description": "Tax owned by the company on its income (trailing 12 months)."
  },
  {
    "id": "income_tax_expense_mrq",
    "name": "Income Tax Expense (Most Recent Fiscal Quarter)",
    "shortName": "Income Tax Expense (MRQ)",
    "category": "Income Statement Financials",
    "description": "Tax owned by the company on its income (most recent fiscal quarter)."
  },
  {
    "id": "depreciation_amortization_mry",
    "name": "Depreciation and Amortization (Most Recent Fiscal Year)",
    "shortName": "D\u0026A (MRY)",
    "category": "Income Statement Financials",
    "description": "Adjustments for expensing the reduction in value of fixed assets, and spreading the cost of intangible assets over time (most recent fiscal year)."
  },
  {
    "id": "depreciation_amortization_ttm",
    "name": "Depreciation and Amortization (Trailing 12 Months)",
    "shortName": "D\u0026A (TTM)",
    "category": "Income Statement Financials",
    "description": "Adjustments for expensing the reduction in value of fixed assets, and spreading the cost of intangible assets over time (trailing 12 months)."
  },
  {
    "id": "depreciation_amortization_mrq",
    "name": "Depreciation and Amortization (Most Recent Fiscal Quarter)",
    "shortName": "D\u0026A (MRQ)",
    "category": "Income Statement Financials",
    "description": "Adjustments for expensing the reduction in value of fixed assets, and spreading the cost of intangible assets over time (most recent fiscal quarter)."
  },
  {
    "id": "net_income_discontinued_operations_mry",
    "name": "Net Income from Discontinued Operations (Most Recent Fiscal Year)",
    "shortName": "Net Income from DOs (MRY)",
    "category": "Income Statement Financials",
    "description": "Income (or loss), net of taxes, from a segment of the business that has been discontinued or sold (most recent fiscal year)."
  },
  {
    "id": "net_income_discontinued_operations_ttm",
    "name": "Net Income from Discontinued Operations (Trailing 12 Months)",
    "shortName": "Net Income from DOs (TTM)",
    "category": "Income Statement Financials",
    "description": "Income (or loss), net of taxes, from a segment of the business that has been discontinued or sold (trailing 12 months)."
  },
  {
    "id": "net_income_discontinued_operations_mrq",
    "name": "Net Income from Discontinued Operations (Most Recent Fiscal Quarter)",
    "shortName": "Net Income from DOs (MRQ)",
    "category": "Income Statement Financials",
    "description": "Income (or loss), net of taxes, from a segment of the business that has been discontinued or sold (most recent fiscal quarter)."
  },
  {
    "id": "net_income_noncontrolling_interests_mry",
    "name": "Net Income to Non-Controlling Interests (Most Recent Fiscal Year)",
    "shortName": "Net Income to NCIs (MRY)",
    "category": "Income Statement Financials",
    "description": "Part of income attributable to non-controlling interest shareholders; deducted from consolidated net income before arriving at net income attributable to common shareholders (most recent fiscal year)."
  },
  {
    "id": "net_income_noncontrolling_interests_ttm",
    "name": "Net Income to Non-Controlling Interests (Trailing 12 Months)",
    "shortName": "Net Income to NCIs (TTM)",
    "category": "Income Statement Financials",
    "description": "Part of income attributable to non-controlling interest shareholders; deducted from consolidated net income before arriving at net income attributable to common shareholders (trailing 12 months)."
  },
  {
    "id": "net_income_noncontrolling_interests_mrq",
    "name": "Net Income to Non-Controlling Interests (Most Recent Fiscal Quarter)",
    "shortName": "Net Income to NCIs (MRQ)",
    "category": "Income Statement Financials",
    "description": "Part of income attributable to non-controlling interest shareholders; deducted from consolidated net income before arriving at net income attributable to common shareholders (most recent fiscal quarter)."
  },
  {
    "id": "preferred_dividends_mry",
    "name": "Preferred Dividends (Most Recent Fiscal Year)",
    "shortName": "Preferred Dividends (MRY)",
    "category": "Income Statement Financials",
    "description": "Dividend payments to preferred shareholders; deducted from consolidated net income before arriving at net income attributable to common shareholders (most recent fiscal year)."
  },
  {
    "id": "preferred_dividends_ttm",
    "name": "Preferred Dividends (Trailing 12 Months)",
    "shortName": "Preferred Dividends (TTM)",
    "category": "Income Statement Financials",
    "description": "Dividend payments to preferred shareholders; deducted from consolidated net income before arriving at net income attributable to common shareholders (trailing 12 months)."
  },
  {
    "id": "preferred_dividends_mrq",
    "name": "Preferred Dividends (Most Recent Fiscal Quarter)",
    "shortName": "Preferred Dividends (MRQ)",
    "category": "Income Statement Financials",
    "description": "Dividend payments to preferred shareholders; deducted from consolidated net income before arriving at net income attributable to common shareholders (most recent fiscal quarter)."
  },
  {
    "id": "new_high_revenue_mry_yoy_5_year",
    "name": "New 5-Year High Annual Revenue (Year-Over-Year)",
    "shortName": "New 5-Year High Annual Revenue (YoY)",
    "category": "High/Low Revenue",
    "description": "Indicates if the company had its highest annual revenue in 5 years, in the most recent fiscal year. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_revenue_mrq_qoq_5_year",
    "name": "New 5-Year High Quarterly Revenue (Quarter-Over-Quarter)",
    "shortName": "New 5-Year High Quarterly Revenue (QoQ)",
    "category": "High/Low Revenue",
    "description": "Indicates if the company had its highest quarterly revenue in 5 years, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_revenue_mrq_yoy_5_year",
    "name": "New 5-Year High Quarterly Revenue (Year-Over-Year)",
    "shortName": "New 5-Year High Quarterly Revenue (YoY)",
    "category": "High/Low Revenue",
    "description": "Indicates if the company had its highest quarterly revenue in 5 years, comparing quarters on a year-over-year basis, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_revenue_mry_yoy_5_year",
    "name": "New 5-Year Low Annual Revenue (Year-Over-Year)",
    "shortName": "New 5-Year Low Annual Revenue (YoY)",
    "category": "High/Low Revenue",
    "description": "Indicates if the company had its lowest annual revenue in 5 years, in the most recent fiscal year. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_revenue_mrq_qoq_5_year",
    "name": "New 5-Year Low Quarterly Revenue (Quarter-Over-Quarter)",
    "shortName": "New 5-Year Low Quarterly Revenue (QoQ)",
    "category": "High/Low Revenue",
    "description": "Indicates if the company had its lowest quarterly revenue in 5 years, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_revenue_mrq_yoy_5_year",
    "name": "New 5-Year Low Quarterly Revenue (Year-Over-Year)",
    "shortName": "New 5-Year Low Quarterly Revenue (YoY)",
    "category": "High/Low Revenue",
    "description": "Indicates if the company had its lowest quarterly revenue in 5 years, comparing quarters on a year-over-year basis, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_net_income_mry_yoy_5_year",
    "name": "New 5-Year High Annual Net Income (Year-Over-Year)",
    "shortName": "New 5-Year High Annual Net Income (YoY)",
    "category": "High/Low Net Income",
    "description": "Indicates if the company had its highest annual net income in 5 years, in the most recent fiscal year. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_net_income_mrq_qoq_5_year",
    "name": "New 5-Year High Quarterly Net Income (Quarter-Over-Quarter)",
    "shortName": "New 5-Year High Quarterly Net Income (QoQ)",
    "category": "High/Low Net Income",
    "description": "Indicates if the company had its highest quarterly net income in 5 years, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_high_net_income_mrq_yoy_5_year",
    "name": "New 5-Year High Quarterly Net Income (Year-Over-Year)",
    "shortName": "New 5-Year High Quarterly Net Income (YoY)",
    "category": "High/Low Net Income",
    "description": "Indicates if the company had its highest quarterly net income in 5 years, comparing quarters on a year-over-year basis, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_net_income_mry_yoy_5_year",
    "name": "New 5-Year Low Annual Net Income (Year-Over-Year)",
    "shortName": "New 5-Year Low Annual Net Income (YoY)",
    "category": "High/Low Net Income",
    "description": "Indicates if the company had its lowest annual net income in 5 years, in the most recent fiscal year. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_net_income_mrq_qoq_5_year",
    "name": "New 5-Year Low Quarterly Net Income (Quarter-Over-Quarter)",
    "shortName": "New 5-Year Low Quarterly Net Income (QoQ)",
    "category": "High/Low Net Income",
    "description": "Indicates if the company had its lowest quarterly net income in 5 years, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "new_low_net_income_mrq_yoy_5_year",
    "name": "New 5-Year Low Quarterly Net Income (Year-Over-Year)",
    "shortName": "New 5-Year Low Quarterly Net Income (YoY)",
    "category": "High/Low Net Income",
    "description": "Indicates if the company had its lowest quarterly net income in 5 years, comparing quarters on a year-over-year basis, in the most recent fiscal quarter. Value is 1 (True) or 0 (False)."
  },
  {
    "id": "percent_change_revenue_mry_yoy",
    "name": "Percent Change in Annual Revenue (Year-Over-Year)",
    "shortName": "% Change in Annual Revenue (YoY)",
    "category": "Percent Change in Revenue",
    "description": "Percent change in annual revenue between the most recent two fiscal years."
  },
  {
    "id": "percent_change_revenue_mrq_qoq",
    "name": "Percent Change in Quarterly Revenue (Quarter-Over-Quarter)",
    "shortName": "% Change in Quarterly Revenue (QoQ)",
    "category": "Percent Change in Revenue",
    "description": "Percent change in quarterly revenue between the most recent two fiscal quarters."
  },
  {
    "id": "percent_change_revenue_mrq_yoy",
    "name": "Percent Change in Quarterly Revenue (Year-Over-Year)",
    "shortName": "% Change in Quarterly Revenue (YoY)",
    "category": "Percent Change in Revenue",
    "description": "Percent change in quarterly revenue between the most recent fiscal quarter and the corresponding fiscal quarter one year prior."
  },
  {
    "id": "percent_change_net_income_mry_yoy",
    "name": "Percent Change in Annual Net Income (Year-Over-Year)",
    "shortName": "% Change in Annual Net Income (YoY)",
    "category": "Percent Change in Net Income",
    "description": "Percent change in annual net income between the most recent two fiscal years."
  },
  {
    "id": "percent_change_net_income_mrq_qoq",
    "name": "Percent Change in Quarterly Net Income (Quarter-Over-Quarter)",
    "shortName": "% Change in Quarterly Net Income (QoQ)",
    "category": "Percent Change in Net Income",
    "description": "Percent change in quarterly net income between the most recent two fiscal quarters."
  },
  {
    "id": "percent_change_net_income_mrq_yoy",
    "name": "Percent Change in Quarterly Net Income (Year-Over-Year)",
    "shortName": "% Change in Quarterly Net Income (YoY)",
    "category": "Percent Change in Net Income",
    "description": "Percent change in quarterly net income between the most recent fiscal quarter and the corresponding fiscal quarter one year prior."
  },
  {
    "id": "consecutive_up_revenue_mry_yoy",
    "name": "Consecutive Years of Increasing Revenue (Year-Over-Year)",
    "shortName": "Years of Increasing Revenue (YoY)",
    "category": "Increasing/Decreasing Revenue",
    "description": "Number of consecutive years the company had an increase in revenue."
  },
  {
    "id": "consecutive_up_revenue_mrq_qoq",
    "name": "Consecutive Quarters of Increasing Revenue (Quarter-Over-Quarter)",
    "shortName": "Quarters of Increasing Revenue (QoQ)",
    "category": "Increasing/Decreasing Revenue",
    "description": "Number of consecutive quarters the company had an increase in revenue."
  },
  {
    "id": "consecutive_up_revenue_mrq_yoy",
    "name": "Consecutive Quarters of Increasing Revenue (Year-Over-Year)",
    "shortName": "Quarters of Increasing Revenue (YoY)",
    "category": "Increasing/Decreasing Revenue",
    "description": "Number of consecutive quarters the company had an increase in revenue, comparing quarters on a year-over-year basis."
  },
  {
    "id": "consecutive_down_revenue_mry_yoy",
    "name": "Consecutive Years of Decreasing Revenue (Year-Over-Year)",
    "shortName": "Years of Decreasing Revenue (YoY)",
    "category": "Increasing/Decreasing Revenue",
    "description": "Number of consecutive years the company had a decrease in revenue."
  },
  {
    "id": "consecutive_down_revenue_mrq_qoq",
    "name": "Consecutive Quarters of Decreasing Revenue (Quarter-Over-Quarter)",
    "shortName": "Quarters of Decreasing Revenue (QoQ)",
    "category": "Increasing/Decreasing Revenue",
    "description": "Number of consecutive quarters the company had a decrease in revenue."
  },
  {
    "id": "consecutive_down_revenue_mrq_yoy",
    "name": "Consecutive Quarters of Decreasing Revenue (Year-Over-Year)",
    "shortName": "Quarters of Decreasing Revenue (YoY)",
    "category": "Increasing/Decreasing Revenue",
    "description": "Number of consecutive quarters the company had a decrease in revenue, comparing quarters on a year-over-year basis."
  },
  {
    "id": "consecutive_up_net_income_mry_yoy",
    "name": "Consecutive Years of Increasing Net Income (Year-Over-Year)",
    "shortName": "Years of Increasing Net Income (YoY)",
    "category": "Increasing/Decreasing Net Income",
    "description": "Number of consecutive years the company had an increase in net income."
  },
  {
    "id": "consecutive_up_net_income_mrq_qoq",
    "name": "Consecutive Quarters of Increasing Net Income (Quarter-Over-Quarter)",
    "shortName": "Quarters of Increasing Net Income (QoQ)",
    "category": "Increasing/Decreasing Net Income",
    "description": "Number of consecutive quarters the company had an increase in net income."
  },
  {
    "id": "consecutive_up_net_income_mrq_yoy",
    "name": "Consecutive Quarters of Increasing Net Income (Year-Over-Year)",
    "shortName": "Quarters of Increasing Net Income (YoY)",
    "category": "Increasing/Decreasing Net Income",
    "description": "Number of consecutive quarters the company had an increase in net income, comparing quarters on a year-over-year basis."
  },
  {
    "id": "consecutive_down_net_income_mry_yoy",
    "name": "Consecutive Years of Decreasing Net Income (Year-Over-Year)",
    "shortName": "Years of Decreasing Net Income (YoY)",
    "category": "Increasing/Decreasing Net Income",
    "description": "Number of consecutive years the company had a decrease in net income."
  },
  {
    "id": "consecutive_down_net_income_mrq_qoq",
    "name": "Consecutive Quarters of Decreasing Net Income (Quarter-Over-Quarter)",
    "shortName": "Quarters of Decreasing Net Income (QoQ)",
    "category": "Increasing/Decreasing Net Income",
    "description": "Number of consecutive quarters the company had a decrease in net income."
  },
  {
    "id": "consecutive_down_net_income_mrq_yoy",
    "name": "Consecutive Quarters of Decreasing Net Income (Year-Over-Year)",
    "shortName": "Quarters of Decreasing Net Income (YoY)",
    "category": "Increasing/Decreasing Net Income",
    "description": "Number of consecutive quarters the company had a decrease in net income, comparing quarters on a year-over-year basis."
  },
  {
    "id": "total_assets",
    "name": "Total Assets (Most Recent Fiscal Quarter)",
    "shortName": "Total Assets",
    "category": "Balance Statement Financials",
    "description": "Sum of all assets on the company's balance sheet (most recent fiscal quarter)."
  },
  {
    "id": "current_assets",
    "name": "Current Assets (Most Recent Fiscal Quarter)",
    "shortName": "Current Assets",
    "category": "Balance Statement Financials",
    "description": "Assets that can be used or converted to cash within a year (most recent fiscal quarter)."
  },
  {
    "id": "long_term_assets",
    "name": "Long Term Assets (Most Recent Fiscal Quarter)",
    "shortName": "Long Term Assets",
    "category": "Balance Statement Financials",
    "description": "Assets not expected to be used or converted to cash within a year (most recent fiscal quarter)."
  },
  {
    "id": "cash_equivalents",
    "name": "Cash and Cash Equivalents (Most Recent Fiscal Quarter)",
    "shortName": "Cash and Cash Equivalents",
    "category": "Balance Statement Financials",
    "description": "Cash and other current assets that can readily be converted to cash (most recent fiscal quarter)."
  },
  {
    "id": "investments",
    "name": "Investments (Most Recent Fiscal Quarter)",
    "shortName": "Investments",
    "category": "Balance Statement Financials",
    "description": "Assets such as stocks, bonds, real estate, and other investments held for the purpose of income generation or capital appreciation (most recent fiscal quarter)."
  },
  {
    "id": "property_plant_equipment",
    "name": "Property, Plant, and Equipment (PP\u0026E) (Most Recent Fiscal Quarter)",
    "shortName": "Property, Plant, and Equipment",
    "category": "Balance Statement Financials",
    "description": "Long-term tangible assets used by the company to conduct its business (most recent fiscal quarter)."
  },
  {
    "id": "inventory",
    "name": "Inventory (Most Recent Fiscal Quarter)",
    "shortName": "Inventory",
    "category": "Balance Statement Financials",
    "description": "Product on hand expected to be sold within a year (most recent fiscal quarter)."
  },
  {
    "id": "tax_assets",
    "name": "Tax Assets (Most Recent Fiscal Quarter)",
    "shortName": "Tax Assets",
    "category": "Balance Statement Financials",
    "description": "Assets that account for the potential reduction of future tax liabilities, such as tax credits or tax losses carried forward (most recent fiscal quarter)."
  },
  {
    "id": "receivables",
    "name": "Receivables (Most Recent Fiscal Quarter)",
    "shortName": "Receivables",
    "category": "Balance Statement Financials",
    "description": "Assets that account for balances owned to the company by customers (most recent fiscal quarter)."
  },
  {
    "id": "tangible_assets",
    "name": "Tangible Assets (Most Recent Fiscal Quarter)",
    "shortName": "Tangible Assets",
    "category": "Balance Statement Financials",
    "description": "Physical assets such as equipment and inventory. (most recent fiscal quarter)."
  },
  {
    "id": "intangible_assets",
    "name": "Intangible Assets and Goodwill (Most Recent Fiscal Quarter)",
    "shortName": "Intangible Assets and Goodwill",
    "category": "Balance Statement Financials",
    "description": "Long-term non-physical assets such as patents, trademarks, and copyrights, and other intangible assets of a company such as brand recognition (most recent fiscal quarter)."
  },
  {
    "id": "total_liabilities",
    "name": "Total Liabilities (Most Recent Fiscal Quarter)",
    "shortName": "Total Liabilities",
    "category": "Balance Statement Financials",
    "description": "Sum of all liabilities on the company's balance sheet (most recent fiscal quarter)."
  },
  {
    "id": "current_liabilities",
    "name": "Current Liabilities (Most Recent Fiscal Quarter)",
    "shortName": "Current Liabilities",
    "category": "Balance Statement Financials",
    "description": "Financial obligations owed by the company within a year (most recent fiscal quarter)."
  },
  {
    "id": "long_term_liabilities",
    "name": "Long Term Liabilities (Most Recent Fiscal Quarter)",
    "shortName": "Long Term Liabilities",
    "category": "Balance Statement Financials",
    "description": "Financial obligations not owed by the company within a year (most recent fiscal quarter)."
  },
  {
    "id": "deferred_revenue",
    "name": "Deferred Revenue (Most Recent Fiscal Quarter)",
    "shortName": "Deferred Revenue",
    "category": "Balance Statement Financials",
    "description": "Liabilities that account for revenue that has been received by the company but has not yet been earned, as the corresponding products or services have not yet been delivered (most recent fiscal quarter)."
  },
  {
    "id": "deposit_liabilities",
    "name": "Deposit Liabilities (Most Recent Fiscal Quarter)",
    "shortName": "Deposit Liabilities",
    "category": "Balance Statement Financials",
    "description": "Liabilities such as savings deposits and time deposits, the funds of which the company is obligated to return to customers on demand (most recent fiscal quarter)."
  },
  {
    "id": "debt",
    "name": "Debt (Most Recent Fiscal Quarter)",
    "shortName": "Debt",
    "category": "Balance Statement Financials",
    "description": "Total current and non-current debt owed by the company, including bonds issued, lines of credit, and lease obligations (most recent fiscal quarter)."
  },
  {
    "id": "tax_liabilities",
    "name": "Tax Liabilities (Most Recent Fiscal Quarter)",
    "shortName": "Tax Liabilities",
    "category": "Balance Statement Financials",
    "description": "Taxes owed by the company (most recent fiscal quarter)."
  },
  {
    "id": "payables",
    "name": "Payables (Most Recent Fiscal Quarter)",
    "shortName": "Payables",
    "category": "Balance Statement Financials",
    "description": "Liabilities that account for obligations owed to creditors and suppliers for goods and services that have been received but not yet paid for (most recent fiscal quarter)."
  },
  {
    "id": "working_capital",
    "name": "Working Capital (Most Recent Fiscal Quarter)",
    "shortName": "Working Capital",
    "category": "Balance Statement Financials",
    "description": "Difference between current assets and current liabilities (most recent fiscal quarter)."
  },
  {
    "id": "shareholder_equity",
    "name": "Shareholder Equity (Most Recent Fiscal Quarter)",
    "shortName": "Shareholder Equity",
    "category": "Balance Statement Financials",
    "description": "Book value of the company allotted to stockholders in the event of liquidation, equal to total assets minus total liabilities (most recent fiscal quarter)."
  },
  {
    "id": "shares_outstanding",
    "name": "Shares Outstanding (Most Recent Fiscal Quarter)",
    "shortName": "Shares Outstanding",
    "category": "Balance Statement Financials",
    "description": "Shares of outstanding common stock (most recent fiscal quarter)."
  },
  {
    "id": "cash_flow_operating_mry",
    "name": "Cash Flow from Operating Activities (Most Recent Fiscal Year)",
    "shortName": "Cash Flow (Operations) (MRY)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from normal business operations (most recent fiscal year)."
  },
  {
    "id": "cash_flow_operating_ttm",
    "name": "Cash Flow from Operating Activities (Trailing 12 Months)",
    "shortName": "Cash Flow (Operations) (TTM)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from normal business operations (trailing 12 months)."
  },
  {
    "id": "cash_flow_operating_mrq",
    "name": "Cash Flow from Operating Activities (Most Recent Fiscal Quarter)",
    "shortName": "Cash Flow (Operations) (MRQ)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from normal business operations (most recent fiscal quarter)."
  },
  {
    "id": "cash_flow_investing_mry",
    "name": "Cash Flow from Investing Activities (Most Recent Fiscal Year)",
    "shortName": "Cash Flow (Investing) (MRY)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from investing activities, such as the purchase or sale of securities (most recent fiscal year)."
  },
  {
    "id": "cash_flow_investing_ttm",
    "name": "Cash Flow from Investing Activities (Trailing 12 Months)",
    "shortName": "Cash Flow (Investing) (TTM)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from investing activities, such as the purchase or sale of securities (trailing 12 months)."
  },
  {
    "id": "cash_flow_investing_mrq",
    "name": "Cash Flow from Investing Activities (Most Recent Fiscal Quarter)",
    "shortName": "Cash Flow (Investing) (MRQ)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from investing activities, such as the purchase or sale of securities (most recent fiscal quarter)."
  },
  {
    "id": "cash_flow_financing_mry",
    "name": "Cash Flow from Financing Activities (Most Recent Fiscal Year)",
    "shortName": "Cash Flow (Financing) (MRY)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from financing activities, such as transactions involving debt, equity, and dividends (most recent fiscal year)."
  },
  {
    "id": "cash_flow_financing_ttm",
    "name": "Cash Flow from Financing Activities (Trailing 12 Months)",
    "shortName": "Cash Flow (Financing) (TTM)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from financing activities, such as transactions involving debt, equity, and dividends (trailing 12 months)."
  },
  {
    "id": "cash_flow_financing_mrq",
    "name": "Cash Flow from Financing Activities (Most Recent Fiscal Quarter)",
    "shortName": "Cash Flow (Financing) (MRQ)",
    "category": "Cash Flow Statement Financials",
    "description": "Cash inflow (or outflow) from financing activities, such as transactions involving debt, equity, and dividends (most recent fiscal quarter)."
  },
  {
    "id": "capital_expenditure_mry",
    "name": "Capital Expenditure (CAPEX) (Most Recent Fiscal Year)",
    "shortName": "CAPEX (MRY)",
    "category": "Cash Flow Statement Financials",
    "description": "Part of cash flow from operating activities accounting for funds used to acquire long-term assets for conducting the company's business, such as buildings or equipment (most recent fiscal year)."
  },
  {
    "id": "capital_expenditure_ttm",
    "name": "Capital Expenditure (CAPEX) (Trailing 12 Months)",
    "shortName": "CAPEX (TTM)",
    "category": "Cash Flow Statement Financials",
    "description": "Part of cash flow from operating activities accounting for funds used to acquire long-term assets for conducting the company's business, such as buildings or equipment (trailing 12 months)."
  },
  {
    "id": "capital_expenditure_mrq",
    "name": "Capital Expenditure (CAPEX) (Most Recent Fiscal Quarter)",
    "shortName": "CAPEX (MRQ)",
    "category": "Cash Flow Statement Financials",
    "description": "Part of cash flow from operating activities accounting for funds used to acquire long-term assets for conducting the company's business, such as buildings or equipment (most recent fiscal quarter)."
  },
  {
    "id": "net_cash_flow_mry",
    "name": "Net Cash Flow (Most Recent Fiscal Year)",
    "shortName": "Net Cash Flow (MRY)",
    "category": "Cash Flow Statement Financials",
    "description": "Company's net change in cash and cash equivalents (most recent fiscal year)."
  },
  {
    "id": "net_cash_flow_ttm",
    "name": "Net Cash Flow (Trailing 12 Months)",
    "shortName": "Net Cash Flow (TTM)",
    "category": "Cash Flow Statement Financials",
    "description": "Company's net change in cash and cash equivalents (trailing 12 months)."
  },
  {
    "id": "net_cash_flow_mrq",
    "name": "Net Cash Flow (Most Recent Fiscal Quarter)",
    "shortName": "Net Cash Flow (MRQ)",
    "category": "Cash Flow Statement Financials",
    "description": "Company's net change in cash and cash equivalents (most recent fiscal quarter)."
  },
  {
    "id": "free_cash_flow_mry",
    "name": "Free Cash Flow (FCF) (Most Recent Fiscal Year)",
    "shortName": "Free Cash Flow (MRY)",
    "category": "Cash Flow Statement Financials",
    "description": "Measure of the company's financial performance, calculated as cash flow from operating activities minus capital expenditure (most recent fiscal year)."
  },
  {
    "id": "free_cash_flow_ttm",
    "name": "Free Cash Flow (FCF) (Trailing 12 Months)",
    "shortName": "Free Cash Flow (TTM)",
    "category": "Cash Flow Statement Financials",
    "description": "Measure of the company's financial performance, calculated as cash flow from operating activities minus capital expenditure (trailing 12 months)."
  },
  {
    "id": "free_cash_flow_mrq",
    "name": "Free Cash Flow (FCF) (Most Recent Fiscal Quarter)",
    "shortName": "Free Cash Flow (MRQ)",
    "category": "Cash Flow Statement Financials",
    "description": "Measure of the company's financial performance, calculated as cash flow from operating activities minus capital expenditure (most recent fiscal quarter)."
  },
  {
    "id": "pe_ratio",
    "name": "Price to Earnings Ratio (P/E) (Trailing 12 Months Net Income)",
    "shortName": "P/E Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of price to earnings per share, calculated as current market capitalization divided by net income (trailing 12 months)."
  },
  {
    "id": "ps_ratio",
    "name": "Price to Sales Ratio (P/S) (Trailing 12 Months Revenue)",
    "shortName": "P/S Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of price to revenue per share, calculated as current market capitalization divided by revenue (trailing 12 months)."
  },
  {
    "id": "pb_ratio",
    "name": "Price to Book Ratio (P/B) (Most Recent Fiscal Quarter Book Value)",
    "shortName": "P/B Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of price to book value per share, calculated as current market capitalization divided by shareholder equity (most recent fiscal quarter)."
  },
  {
    "id": "pcf_ratio",
    "name": "Price to Cash Flow Ratio (P/CF) (Trailing 12 Months Operating Cash Flow)",
    "shortName": "P/CF Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of price to operating cash flow per share, calculated as current market capitalization divided by cash flow from operating activities (trailing 12 months)."
  },
  {
    "id": "pfcf_ratio",
    "name": "Price to Free Cash Flow Ratio (P/FCF) (Trailing 12 Months Free Cash Flow)",
    "shortName": "P/FCF Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of price to free cash flow per share, calculated as current market capitalization divided by free cash flow (trailing 12 months)."
  },
  {
    "id": "payout_ratio",
    "name": "Payout Ratio (Trailing 12 Months)",
    "shortName": "Payout Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of dividends paid to net income for common shareholders, as a percentage (trailing 12 months)."
  },
  {
    "id": "dividend_yield",
    "name": "Dividend Yield (Trailing 12 Months Dividend Payments)",
    "shortName": "Dividend Yield",
    "category": "Fundamental Ratios",
    "description": "Ratio of yearly dividend payments per basic common share to current price, as a percentage (trailing 12 months)."
  },
  {
    "id": "gross_margin",
    "name": "Gross Margin (Trailing 12 Months)",
    "shortName": "Gross Margin",
    "category": "Fundamental Ratios",
    "description": "Ratio of gross profit to revenue, as a percentage (trailing 12 months)."
  },
  {
    "id": "operating_margin",
    "name": "Operating Margin (Trailing 12 Months)",
    "shortName": "Operating Margin",
    "category": "Fundamental Ratios",
    "description": "Ratio of operating income to revenue, as a percentage (trailing 12 months)."
  },
  {
    "id": "ebitda_margin",
    "name": "EBITDA Margin (Trailing 12 Months)",
    "shortName": "EBITDA Margin",
    "category": "Fundamental Ratios",
    "description": "Ratio of EBITDA to revenue, as a percentage (trailing 12 months)."
  },
  {
    "id": "ebit_margin",
    "name": "EBIT Margin (Trailing 12 Months)",
    "shortName": "EBIT Margin",
    "category": "Fundamental Ratios",
    "description": "Ratio of EBIT to revenue, as a percentage (trailing 12 months)."
  },
  {
    "id": "pretax_margin",
    "name": "Pretax Margin (Trailing 12 Months)",
    "shortName": "Pretax Margin",
    "category": "Fundamental Ratios",
    "description": "Ratio of pretax income (EBT) to revenue, as a percentage (trailing 12 months)."
  },
  {
    "id": "net_margin",
    "name": "Net Margin (Trailing 12 Months)",
    "shortName": "Net Margin",
    "category": "Fundamental Ratios",
    "description": "Ratio of net income to revenue, as a percentage (trailing 12 months)."
  },
  {
    "id": "return_on_equity",
    "name": "Return on Equity (ROE) (Trailing 12 Months)",
    "shortName": "Return on Equity",
    "category": "Fundamental Ratios",
    "description": "Ratio of net income to average shareholder equity, as a percentage (trailing 12 months)."
  },
  {
    "id": "return_on_assets",
    "name": "Return on Assets (ROA) (Trailing 12 Months)",
    "shortName": "Return on Assets",
    "category": "Fundamental Ratios",
    "description": "Ratio of net income to average total assets, as a percentage (trailing 12 months)."
  },
  {
    "id": "asset_turnover",
    "name": "Asset Turnover (Trailing 12 Months)",
    "shortName": "Asset Turnover",
    "category": "Fundamental Ratios",
    "description": "Ratio of revenue to average total assets (trailing 12 months)."
  },
  {
    "id": "de_ratio",
    "name": "Debt to Equity Ratio (D/E) (Most Recent Fiscal Quarter)",
    "shortName": "Debt to Equity Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of total liabilities to shareholder equity (most recent fiscal quarter)."
  },
  {
    "id": "current_ratio",
    "name": "Current Ratio (Most Recent Fiscal Quarter)",
    "shortName": "Current Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of current assets to current liabilities (most recent fiscal quarter)."
  },
  {
    "id": "quick_ratio",
    "name": "Quick Ratio (Most Recent Fiscal Quarter)",
    "shortName": "Quick Ratio",
    "category": "Fundamental Ratios",
    "description": "Ratio of quick assets to current liabilities, where quick assets are defined as current assets minus inventory (most recent fiscal quarter)."
  }
];

export default FieldsList;
