import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CreateIcon from '@mui/icons-material/Create';
import {
    DataGrid,
    gridClasses,
    GridFooterContainer,
    gridPageCountSelector,
    gridPageSelector,
    GridToolbarQuickFilter,
    useGridApiContext,
    useGridSelector,
    GridPagination
} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import { addCommas } from './ValueFormatter';
import { getLocalStorageJSON, setLocalStorageJSON, getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'rgba(0, 0, 0, .05)',
    },
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#f3fff9 !important',
    },
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader:first-of-type, .MuiDataGrid-cell:first-of-type': {
        borderLeft: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
    },
    '& .MuiDataGrid-cell': {
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiDataGrid-cell:not(:first-of-type)': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: '#fcf8e3',
    },
    // '& .MuiPaginationItem-root': {
    //     borderRadius: 0,
    // },
    '& .MuiTablePagination-displayedRows': {
        minWidth: '131px',
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-cell--withRenderer': {
        outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeaders': {
        borderTop: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
        backgroundColor: '#f3f9ff',
    },
    '& .MuiDataGrid-footerContainer': {
        borderTop: 0,
        justifyContent: 'space-between',
    },
    '& .MuiDataGrid-main': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#c0c0c0' : '#303030'}`,
    },
    // '& .MuiDataGrid-cell--withRenderer': {
    //     position: 'sticky',
    //     overflow: 'hidden',
    //     zIndex: 1,
    //     backgroundColor: '#fff',
    //     left: '0',
    //     float: 'left',
    //     boxShadow: '2px 0px 4px -2px rgba(0, 0, 0, 0.21)',
    // },
    display: 'flex',
    flexDirection: 'column-reverse',

    /* if sticky column headers are needed: */
    // '& .MuiDataGrid-columnHeaders': {
    //     position: "sticky",
    //     backgroundColor: theme.palette.background.paper,
    //     zIndex: theme.zIndex.mobileStepper - 1,
    // },
    // '& .MuiDataGrid-virtualScroller': {
    //     marginTop: "0 !important"
    // },
    // '& .MuiDataGrid-main': {
    //     overflow: "visible"
    // },
}));

let globalApiRef;

function TablePaginationActions(props) {
    const apiRef = useGridApiContext();
    globalApiRef = apiRef;
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handleFirstPageButtonClick = (event) => {
        apiRef.current.setPage(0);
    };

    const handlePrevButtonClick = (event) => {
        apiRef.current.setPage(page - 1);
    };

    const handleNextButtonClick = (event) => {
        apiRef.current.setPage(page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        apiRef.current.setPage(pageCount - 1);
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handlePrevButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= pageCount - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= pageCount - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </Box>
    );
}

const CustomFooter = (props) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);

    const navigate = useNavigate();

    // support arrow key paging
    document.onkeydown = (e) => {
        switch (e.key) {
            case 'Left':
            case 'ArrowLeft':
                apiRef.current.setPage(page - 1);
                break;
            case 'Up':
            case 'ArrowUp':
                apiRef.current.setPage(page - 1);
                break;
            case 'Right':
            case 'ArrowRight':
                apiRef.current.setPage(page + 1);
                break;
            case 'Down':
            case 'ArrowDown':
                apiRef.current.setPage(page + 1);
                break;
            default: return;
        }
        e.preventDefault();
    };

    let buttonContent = (
        <Stack direction="row" spacing={5} sx={{ mb: 1 }}>
            <Button variant="contained" style={{ maxWidth: '200px', minWidth: '200px' }} startIcon={<CreateIcon />}
                onClick={() => {
                    navigate('/saved-queries', { replace: true, state: { savedQueriesDataGridState: apiRef.current.exportState() } });
                    navigate('/query');
                }}>
                New Query
            </Button>
        </Stack>
    );

    return (
        <GridFooterContainer>
            {buttonContent}
            <GridPagination />
            <GridToolbarQuickFilter debounceMs={500} />
        </GridFooterContainer>
    );
}

const SavedQueries = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [loading, setLoading] = useState(() => true);
    const [runningQuery, setRunningQuery] = useState(() => false);
    const [exportingResults, setExportingResults] = useState(() => false);

    const [renamingQueryDialog, setRenamingQueryDialog] = useState(() => false);
    const [deletingQueryDialog, setDeletingQueryDialog] = useState(() => false);

    // lastQid holds a qid returned by run-query as a result of an Export Results click
    const [lastQid, setLastQid] = useState(() => '');

    const [exportResultsDialogOpen, setExportResultsDialogOpen] = useState(() => false);
    const [exportFormat, setExportFormat] = useState(() => getLocalStorageJSON('exportFormat') || '');
    const [exportZip, setExportZip] = useState(() => getLocalStorageJSON('exportZip') || false);
    const [exportPretty, setExportPretty] = useState(() => getLocalStorageJSON('exportPretty') || false);
    const [exportDialogError, setExportDialogError] = useState(() => false);

    const [renameQueryDialogOpen, setRenameQueryDialogOpen] = useState(() => false);
    const [renameQueryName, setRenameQueryName] = useState(() => ''); // input field
    const [renameQueryDialogError, setRenameQueryDialogError] = useState(() => false);

    const [deleteQueryDialogOpen, setDeleteQueryDialogOpen] = useState(() => false);
    const [deleteQueryName, setDeleteQueryName] = useState(() => '');

    const [error, setError] = useState(() => null);
    const [columns] = useState(() => [
        {
            field: 'sqid',
            headerName: 'ID',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            getApplyQuickFilterFn: undefined,
            sortable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            headerAlign: 'left',
            align: 'left',
            width: 750,
        },
        {
            field: 'type',
            headerName: 'Type',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            valueFormatter: (params) => {
                if (params.value === 1) {
                    return 'Basic';
                } else if (params.value === 2) {
                    return 'SQL';
                }
                return 'Invalid type';
            },
            getApplyQuickFilterFn: undefined,
        },
        {
            field: 'timeUpdated',
            headerName: 'Updated',
            headerAlign: 'left',
            align: 'left',
            width: 200,
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleString();
            },
            getApplyQuickFilterFn: undefined,
        },
        {
            field: 'actions',
            headerName: '',
            // headerAlign: 'center',
            align: 'center',
            width: 50,
            getApplyQuickFilterFn: undefined,
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    aria-label="actions"
                    onClick={(event) => {
                        setSelectedRowId(params.row.id);
                        handleContextMenu(event);
                    }}
                >
                    <MoreVertIcon key={params.row.id} />
                </IconButton>
            ),
        }]);

    const [rows, setRows] = useState(() => []);
    const [rowMap, setRowMap] = useState(() => { }); // maps ids to other data for context menu links

    const [contextMenu, setContextMenu] = useState(() => null);
    const [selectedRowId, setSelectedRowId] = useState(() => 0);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
                : null,
        );
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const [successMessage, setSuccessMessage] = useState(() => location?.state?.successMessage || null);

    useEffect(() => {
        if (!user.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (user.status < 2) {
            navigate('/subscribe');
            return;
        }

        document.title = 'Saved Queries - EODmetrics'
        fetchService('GET', '/service/saved-queries', {})
            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setLoading(false);
                    setError('Error loading saved queries: ' + data['error']);
                } else {
                    setRows(data['savedQueryList']);
                    setLoading(false);
                    setError(null);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError('Error loading saved queries: ' + err.toString());
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newRowMap = {};
        for (let row of rows) {
            newRowMap[row['id']] = row;
        }
        setRowMap(newRowMap);
    }, [rows]);

    useEffect(() => {
        if (error) {
            setSuccessMessage(null);
            window.scrollTo(0, 0);
        }
    }, [error]);

    useEffect(() => {
        if (successMessage) {
            window.scrollTo(0, 0);
        }
    }, [successMessage]);

    const handleEditQueryClick = () => {
        handleContextMenuClose();
        window.open('/query?sqid=' + String(rowMap[selectedRowId]?.sqid), '_blank');
    }

    const handleRunQueryClick = () => {
        let userLocalStorage = getUserLocalStorage();
        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        setRunningQuery(true);

        fetchService('POST', '/service/run-query', {
            sqid: String(rowMap[selectedRowId]?.sqid),
        })
            .then((response) => response.json())
            .then((data) => {
                setRunningQuery(false);
                handleContextMenuClose();
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError('Error running query: ' + data['error']);
                } else {
                    setError(null);
                    window.open('/results/' + data['qid'] + '?sqid=' + String(rowMap[selectedRowId]?.sqid), '_blank');
                }
            })
            .catch((err) => {
                setRunningQuery(false);
                handleContextMenuClose();
                setError('Error running query: ' + err.toString());
            });
    }

    const handleExportResultsDialogClose = () => {
        setExportResultsDialogOpen(false);
        setExportDialogError(false);
    }

    const handleExportResultsClick = () => {
        let userLocalStorage = getUserLocalStorage();
        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        setExportingResults(true);

        fetchService('POST', '/service/run-query', {
            sqid: String(rowMap[selectedRowId]?.sqid),
        })
            .then((response) => response.json())
            .then((data) => {
                setExportingResults(false);
                handleContextMenuClose();
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError('Error exporting results: ' + data['error']);
                } else {
                    setError(null);
                    setLastQid(data['qid']);
                    setExportResultsDialogOpen(true);
                }
            })
            .catch((err) => {
                setExportingResults(false);
                handleContextMenuClose();
                setError('Error exporting results: ' + err.toString());
            });
    }

    const handleExport = (event) => {
        event.preventDefault();
        let userLocalStorage = getUserLocalStorage();

        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        if (!exportFormat) {
            setExportDialogError(true);
            return
        }

        setExportDialogError(false);
        handleExportResultsDialogClose();
        setExportLocalStorage();
        window.location.href = '/service/export-results?qid=' + lastQid +
            '&format=' + exportFormat +
            '&zip=' + (exportZip ? '1' : '0') +
            '&pretty=' + (exportPretty ? '1' : '0');
    }

    const handleRenameQueryDialogClose = () => {
        setRenameQueryDialogOpen(false);
        setRenameQueryDialogError(false);
    }

    const handleRenameQueryClick = () => {
        let userLocalStorage = getUserLocalStorage();

        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        handleContextMenuClose();
        setRenameQueryName(String(rowMap[selectedRowId]?.name));
        setRenameQueryDialogOpen(true);
    }

    const handleRenameQuery = (event) => {
        event.preventDefault();
        let userLocalStorage = getUserLocalStorage();

        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        if (!renameQueryName) {
            setRenameQueryDialogError(true);
            return
        }

        setRenamingQueryDialog(true);

        fetchService('POST', '/service/rename-query', {
            sqid: String(rowMap[selectedRowId]?.sqid),
            name: renameQueryName,
        })
            .then((response) => response.json())
            .then((data) => {
                handleRenameQueryDialogClose();
                setRenamingQueryDialog(false);
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError('Error renaming query: ' + data['error']);
                } else {
                    setRows(rows => rows.map((row) => {
                        if (row.id === selectedRowId) {
                            return { ...row, name: renameQueryName };
                        } else {
                            return row;
                        }
                    }));
                    setError(null);
                    setSuccessMessage('Query renamed.');
                }
            })
            .catch((err) => {
                handleRenameQueryDialogClose();
                setRenamingQueryDialog(false);
                setError('Error renaming query: ' + err.toString());
            });
    }

    const handleDeleteQueryDialogClose = () => {
        setDeleteQueryDialogOpen(false);
    }

    const handleDeleteQueryClick = () => {
        let userLocalStorage = getUserLocalStorage();

        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        handleContextMenuClose();
        setDeleteQueryName(String(rowMap[selectedRowId]?.name));
        setDeleteQueryDialogOpen(true);
    }

    const handleDeleteQuery = (event) => {
        event.preventDefault();
        let userLocalStorage = getUserLocalStorage();

        if (!userLocalStorage.status) {
            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
            return;
        }

        if (userLocalStorage.status < 2) {
            navigate('/subscribe');
            return;
        }

        setDeletingQueryDialog(true);

        fetchService('POST', '/service/delete-query', {
            sqid: String(rowMap[selectedRowId]?.sqid),
        })
            .then((response) => response.json())
            .then((data) => {
                handleDeleteQueryDialogClose();
                setDeletingQueryDialog(false);
                if (data['error']) {
                    if (data['errorDetails']) {
                        if (data['errorDetails']['login']) {
                            navigate('/login', { state: { errorMessage: 'You have have been logged out. Please log in again.' } });
                            return;
                        }
                    }
                    setError('Error deleting query: ' + data['error']);
                } else {
                    setRows(rows => rows.filter(row => row.id !== selectedRowId));
                    setError(null);
                    setSuccessMessage('Query deleted.');
                }
            })
            .catch((err) => {
                handleDeleteQueryDialogClose();
                setDeletingQueryDialog(false);
                setError('Error deleting query: ' + err.toString());
            });
    }

    let dataGridContainerStyle = { display: 'flex', height: '100%', margin: '0 auto', width: '1200px' };

    let initialState;
    if (location.state && location.state.savedQueriesDataGridState) {
        initialState = location.state.savedQueriesDataGridState;
    } else {
        initialState = {
            pagination: {
                pageSize: 15,
            },
        };
    }

    const setExportLocalStorage = () => {
        setLocalStorageJSON('exportFormat', exportFormat);
        setLocalStorageJSON('exportZip', exportZip);
        setLocalStorageJSON('exportPretty', exportPretty);
    }

    let exportResultsDialogContent = (
        <Dialog open={exportResultsDialogOpen} onClose={handleExportResultsDialogClose}>
            <Box component="form" onSubmit={handleExport}>
                <DialogTitle>Export Results</DialogTitle>
                <DialogContent style={{ width: '350px'/*, height: '150px'*/ }}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 120 }}
                            size="small"
                            error={exportDialogError}
                        >
                            <InputLabel>Format</InputLabel>
                            <Select
                                value={exportFormat}
                                label="Format"
                                onChange={(event) => setExportFormat(event.target.value)}
                            >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="csv">CSV</MenuItem>
                                <MenuItem value="excel">Excel</MenuItem>
                                <MenuItem value="json">JSON</MenuItem>
                                <MenuItem value="xml">XML</MenuItem>
                            </Select>
                            {exportDialogError && (
                                <FormHelperText>Select export format</FormHelperText>
                            )}
                        </FormControl>
                        <FormControlLabel control={<Checkbox />}
                            label="Zip File"
                            checked={exportZip}
                            onChange={(event) => setExportZip(event.target.checked)} />
                        <FormControlLabel control={<Checkbox />}
                            label="Pretty Print (line breaks and indentation)"
                            checked={exportPretty}
                            onChange={(event) => setExportPretty(event.target.checked)}
                            style={{ display: exportFormat === 'json' || exportFormat === 'xml' ? 'inline-flex' : 'none' }}
                        />
                    </FormGroup>
                    {/* <div style={{ width: '338px' }}></div> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleExportResultsDialogClose}>Cancel</Button>
                    <Button type="submit" autoFocus={exportResultsDialogOpen}>Export</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

    let renameQueryDialogContent = (
        <Dialog open={renameQueryDialogOpen} onClose={handleRenameQueryDialogClose}>
            <Box component="form" onSubmit={handleRenameQuery}>
                <DialogTitle>Rename Query</DialogTitle>
                <DialogContent style={{ width: '350px' }}>
                    <FormGroup>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                autoFocus={renameQueryDialogOpen}
                                size="small"
                                error={renameQueryDialogError}
                                helperText={renameQueryDialogError ? 'Enter query name' : ''}
                                label="Query Name"
                                value={renameQueryName}
                                onChange={(event) => setRenameQueryName(event.target.value)}
                            />
                        </FormControl>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    {renamingQueryDialog ? (
                        <CircularProgress size={30} sx={{ mr: 4, mb: 1 }} />
                    ) : (
                        <>
                            <Button onClick={handleRenameQueryDialogClose}>Cancel</Button>
                            <Button type="submit">Save</Button>
                        </>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    );

    let deleteQueryDialogContent = (
        <Dialog open={deleteQueryDialogOpen} onClose={handleDeleteQueryDialogClose}>
            <Box component="form" onSubmit={handleDeleteQuery}>
                <DialogTitle>Delete Query</DialogTitle>
                <DialogContent style={{ width: '350px' }}>
                    Are you sure you want to delete saved query <strong>{deleteQueryName?.length > 100 ? deleteQueryName.substring(0, 100) + '...' : deleteQueryName}</strong>?
                </DialogContent>
                <DialogActions>
                    {deletingQueryDialog ? (
                        <CircularProgress size={30} sx={{ mr: 4, mb: 1 }} />
                    ) : (
                        <>
                            <Button onClick={handleDeleteQueryDialogClose}>Cancel</Button>
                            <Button type="submit" autoFocus={deleteQueryDialogOpen}>Delete</Button>
                        </>
                    )}
                </DialogActions>
            </Box>
        </Dialog >
    );

    let mainContent = (
        <div style={dataGridContainerStyle}>
            <div style={{ flexGrow: 1 }}>
                {error && (
                    <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ mb: 1 }}>{error}</Alert>
                )}
                {successMessage && (
                    <Alert id="success-container" onClose={() => { setSuccessMessage(null) }} severity="success" sx={{ mb: 1 }}>{successMessage}</Alert>
                )}
                <StyledDataGrid
                    density="compact"
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableSelectionOnClick
                    autoHeight
                    rows={rows}
                    columns={columns}
                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                    rowsPerPageOptions={[]}
                    initialState={initialState}
                    components={{
                        Footer: CustomFooter,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                You have no saved queries.
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No queries found.
                            </Stack>
                        )
                    }}
                    componentsProps={{
                        footer: { navigate },
                        row: {
                            onContextMenu: (event) => {
                                setSelectedRowId(Number(event.currentTarget.getAttribute('data-id')));
                                handleContextMenu(event);
                            },
                        },
                    }}
                    localeText={{
                        MuiTablePagination: {
                            ActionsComponent: TablePaginationActions,
                            labelDisplayedRows: ({ from, to, count }) => {
                                return `${from} - ${to} of ${addCommas(String(count))}`;
                            }
                        },
                    }}
                    // onRowClick={(params) => navigate('/query?sqid=' + params.row.sqid)}
                    onCellClick={(params, event) => {
                        if (params.field !== 'sqid' && params.field !== 'actions') {
                            if (event.ctrlKey) {
                                window.open('/query?sqid=' + params.row.sqid, '_blank');
                            } else {
                                navigate('/saved-queries', { replace: true, state: { savedQueriesDataGridState: globalApiRef.current.exportState() } });
                                navigate('/query?sqid=' + params.row.sqid);
                            }
                        }
                    }}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleContextMenuClose();
                            },
                        },
                    }}
                >
                    <MenuItem onClick={handleEditQueryClick}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Edit in new tab
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleRunQueryClick}>
                        <ListItemIcon>
                            <PlayArrowIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {runningQuery ? (
                                <CircularProgress size={15} />
                            ) : (
                                <>
                                    Run in new tab
                                </>
                            )}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleExportResultsClick}>
                        <ListItemIcon>
                            <DownloadIcon />
                        </ListItemIcon>
                        {exportingResults ? (
                            <CircularProgress size={15} />
                        ) : (
                            <>
                                Export Results
                            </>
                        )}
                    </MenuItem>
                    <MenuItem onClick={handleRenameQueryClick}>
                        <ListItemIcon>
                            <DriveFileRenameOutlineIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Rename
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDeleteQueryClick}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Delete
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        </div >
    );

    let loadingContent = (
        <Box style={{ display: 'flex', minHeight: '500px', marginBottom: '500px' }}
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={100} />
        </Box>
    );

    let content = (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Saved Queries" />
            </Box>
            {loading ? loadingContent : (
                <>
                    {mainContent}
                    {exportResultsDialogContent}
                    {renameQueryDialogContent}
                    {deleteQueryDialogContent}
                </>
            )
            }
        </Container >
    );

    return content;
}

export default SavedQueries;