import React, { useEffect } from 'react';
import { Link as RouterLink } from "react-router-dom";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PageHeader from './PageHeader';

const NotFound = (props) => {
    useEffect(() => {
        document.title = 'Page Not Found - EODmetrics';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Box sx={{ mb: 1 }}>
                <PageHeader title="Page Not Found" />
                <Typography variant="body1" mb={2}>
                    The page you requested could not be found. Please <Link component={RouterLink} to="/contact">contact us</Link> if you believe you have received this message in error.
                </Typography>
            </Box>
        </Container>
    );
}

export default NotFound;